import { Injectable, OnDestroy } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { NotFoundError } from '@app/models/errors';
import { environment } from '@environments/environment';
import jwtDecode from 'jwt-decode';
import { AbstractUserService } from 'lingo2-forms';
import { AccountDetailsType, User, UserStatusEnum } from 'lingo2-models';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';

export interface AuthModalOptions {
  /** Откуда вызвали */
  caller: string;

  /** Причина вызова */
  reason: string;

  /** Параметры для возвращения к незавершённой операции после авторизации */
  params: {
    /** код действия */
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    return: 'meeting' | string;

    /** ID сессии митинга */
    session_id: string;

    [key: string]: string | number;
  };

  // /** Режим отображения */
  // mode?: AuthModalModeType;
}

type AuthAuthSuccessFnType = (user?: User) => void;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractService implements OnDestroy, AbstractUserService {
  public modalVisible$: Observable<boolean>;
  public modalOptions$: Observable<AuthModalOptions>;
  public me$: Observable<User>;

  private modalOptionsSubject = this.register(new BehaviorSubject<AuthModalOptions>(null));
  private _meSubject = new ReplaySubject<User>(1);
  private _me: User;
  private getUserById$$: Subscription;
  private subscription = new Subscription();
  private onAuthSuccessFn: AuthAuthSuccessFnType;

  public constructor(private cookie: CookieService, private account: AccountService) {
    super();
    // this.setLogNamespace('onclass:service:Auth');
    this.me$ = this._meSubject.asObservable();
    this.modalOptions$ = this.modalOptionsSubject.asObservable();
    this.modalVisible$ = this.modalOptionsSubject.pipe(map((options) => !!options));
  }

  public get me(): User {
    return this._me;
  }

  public get isAuthenticated(): boolean {
    return this._me && this._me.status !== UserStatusEnum.guest;
  }

  public get accessToken(): string {
    if (this.mockAccessToken) {
      return this.mockAccessToken;
    }

    const accessToken = this.cookie.get(environment.access_token_key) || '';
    return accessToken.length ? accessToken : null;
  }

  /** Для тестовых целей */
  public get mockAccessToken(): string {
    if (environment.useMocks.auth) {
      return localStorage.getItem('APP_MEET_AUTH');
    }
    return null;
  }

  /** Для тестовых целей */
  public set mockAccessToken(token: string) {
    if (environment.useMocks.auth) {
      if (token) {
        localStorage.setItem('APP_MEET_AUTH', token);
      } else {
        localStorage.removeItem('APP_MEET_AUTH');
      }
    }
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public showAuthModal(onAuthSuccessFn?: AuthAuthSuccessFnType, options?: AuthModalOptions) {
    if (onAuthSuccessFn) {
      this.onAuthSuccessFn = onAuthSuccessFn;
    } else {
      this.onAuthSuccessFn = null;
    }
    if (this.isAuthenticated) {
      this.onAuthSuccess(this._me);
      return;
    }

    /** @see AuthModalComponent */
    this.modalOptionsSubject.next(options);
  }

  public closeAuthModal() {
    this.onAuthSuccessFn = null;
    this.modalOptionsSubject.next(null);
  }

  /** Авторизация в системе */
  public init() {
    if (!this.accessToken) {
      this._me = null;
      this._meSubject.next(this._me);
      return;
    }
    const user = this.parseUserFromAccessToken(this.accessToken);
    if (user) {
      this.refreshUser(user);
    }
  }

  refreshUser(user: User, onSuccess?: (account: User) => void) {
    if (this.getUserById$$) {
      this.subscription.remove(this.getUserById$$);
      this.getUserById$$.unsubscribe();
    }

    const details: AccountDetailsType[] = [
      'id',
      'slug',
      'status',
      'first_name',
      'last_name',
      'userpic',
      'ui_language',
      'currency_id',
      'segments',
      'roles',
      'teacher_status',
      'spoken_languages',
      'country',
      'features',
    ];

    this.getUserById$$ = this.account.getUserById(user.id, details).subscribe(
      (account) => {
        this.subscription.remove(this.getUserById$$);
        this._me = account;
        this._meSubject.next(this._me);
        if (onSuccess) {
          onSuccess(account);
        }
      },
      (err) => {
        this.subscription.remove(this.getUserById$$);
        if (err instanceof NotFoundError) {
          // не найден пользователь с таким user.id
          // this.viewlog.error('AuthService::refreshUser(): User not found by id#' + user.id);
          this._me = null;
          this._meSubject.next(this._me);
        }
      },
    );
    this.subscription.add(this.getUserById$$);
  }

  private parseUserFromAccessToken(token: string): User {
    try {
      const token_decoded = jwtDecode<any>(token);
      return new User(token_decoded.subject);
    } catch (e) {
      this.errorService.err(e);
      // токен некорректный или устарел
      // this.viewlog.error('AuthService:parseUserFromAccessToken(): Access token not correct or expired', e);
      this._me = null;
      this._meSubject.next(this._me);
    }
  }

  private onAuthSuccess(user: User) {
    if (!!user && this.onAuthSuccessFn) {
      const fn = this.onAuthSuccessFn;
      this.onAuthSuccessFn = null;
      fn(user);
    }
  }
}
