export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-conference-2',
    versionDate: '2024-09-11T14:02:05.655Z',
    gitCommitHash: 'ge4430b4',
    gitCommitDate: '2024-09-11T13:59:56.000Z',
    versionLong: '35.0.0-ge4430b4',
    gitTag: 'v35.0.0',
};
export default versions;
