import { Injectable } from '@angular/core';
import { FeatureEnum, FeatureStatusEnum, IUserFeature, TenantEnum, User } from 'lingo2-models';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private _tenant: TenantEnum;

  public get tenant(): TenantEnum {
    if (!this._tenant) {
      this._tenant = this.resolveTenant();
    }

    return this._tenant;
  }

  public set tenant(value: TenantEnum) {
    this._tenant = value;
  }

  /**
   * Фича доступна пользователю
   *
   * Интерфейс IFeature сохранён для совместимости с lingo2_forms
   * */
  public isAvailable(feature: FeatureEnum, user: User): boolean {
    const userFeature = this.getUserFeature(user, feature);
    return userFeature?.status === FeatureStatusEnum.enabled;
  }

  public resolveTenant(): TenantEnum {
    const hostname = window.location.hostname;

    if (/^(.+\.)?onclass\.me$/.test(hostname)) {
      return TenantEnum.onclass_me;
    }

    return TenantEnum.onclass_com;
  }

  protected getUserFeature(user: User, feature: FeatureEnum): IUserFeature {
    return (user?.features || []).find((f) => f.feature === feature);
  }
}
