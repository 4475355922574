import pick from 'lodash-es/pick';
import { Subject } from 'rxjs';

export type MediaPermission = 'api_error' | 'no_devices' | 'busy' | 'granted' | 'denied' | 'prompt' | 'partial';

const properties: Array<keyof ClientMediaSettings> = [
  'audio_input_device_id',
  'video_input_device_id',
  'audio_output_device_id',
];

export class ClientMediaSettings {
  public audio_input_device_id: string;
  public video_input_device_id: string;
  public audio_output_device_id: string;

  public changed$ = new Subject<boolean>();

  public constructor(values: Partial<ClientMediaSettings>) {
    const instance: any = this;
    properties.forEach((p: keyof Partial<ClientMediaSettings>) => {
      if (p in values) {
        instance[p] = values[p];
      }
    });
  }

  public destroy() {
    this.changed$.complete();
  }

  public apply(values: Partial<ClientMediaSettings>): boolean {
    let changed = false;
    const instance: any = this;
    properties.forEach((p: keyof Partial<ClientMediaSettings>) => {
      if (p in values && instance[p] !== values[p]) {
        instance[p] = values[p];
        changed = true;
      }
    });
    if (changed) {
      this.changed$.next(true);
    }
    return changed;
  }

  public save() {
    const values = pick(this, properties);
    localStorage.setItem('ConferenceSettings', JSON.stringify(values));
  }

  // Корректирует настройки исходя из доступных устройств браузера
  public applyMediaDevices(devices: MediaDeviceRegistry): boolean {
    const values: Partial<ClientMediaSettings> = pick(this, properties);
    const audioInputDevices = (devices.audioInput || []).filter((d) => !!d.deviceId);
    const videoInputDevices = (devices.videoInput || []).filter((d) => !!d.deviceId);

    // TODO умный алгоритм, который определяет, что ПОДКЛЮЧИЛИСЬ AirPods/Bluetooth и переключает микрофон
    // TODO умный алгоритм, который определяет, что ОТКЛЮЧИЛИСЬ AirPods/Bluetooth и переключает НА РАНЕЕ ИСПОЛЬЗОВАННЫЙ микрофон

    if (!values.audio_input_device_id || !audioInputDevices.find((d) => d.deviceId === values.audio_input_device_id)) {
      values.audio_input_device_id = null;
    }

    if (!values.video_input_device_id || !videoInputDevices.find((d) => d.deviceId === values.video_input_device_id)) {
      values.video_input_device_id = null;
    }

    // if (!values.audio_output_device_id || !audioOutputDevices.find((d) => d.deviceId === values.audio_output_device_id)) {
    //   values.audio_output_device_id = null;
    // }

    /**
     * Типа сложные вычисления, так как ID = default (дефолтное устройство системы)
     * устройства с ID default могут меняться как аппаратная часть
     * но для браузера ничего не изменилось (ID тот же), поэтому он не применяет новые устройства
     *
     * @WARNING: label пока использует как уникальный идентификатор, лучше придумать что-то получше
     * @WARNING: Упрощенная схема код не работает(((
     * @TODO: надо придумать вариант попроще, поиск в поиске это тяжеловато)
     */
    /* if (audioInputDevices.length > 0) {
      audioInputDevices.find((device) => {
        if (device.deviceId === 'default' && device.deviceId === values.audio_input_device_id) {
          audioInputDevices.find((dev) => {
            if (dev.label === device.label || device.label.includes(dev.label)) {
              values.audio_input_device_id = dev.deviceId;
            }
          });
        } else {
          audioInputDevices.find((dev) => {
            if (dev.label === device.label || device.label.includes(dev.label)) {
              values.audio_input_device_id = dev.deviceId;
            }
          });
        }
      });
      if (!values.audio_input_device_id) {
        values.audio_input_device_id = audioInputDevices[0].deviceId;
      }
    }*/

    if (!values.audio_input_device_id && audioInputDevices.length > 0) {
      values.audio_input_device_id = audioInputDevices[0].deviceId;
    }

    if (!values.video_input_device_id && videoInputDevices.length > 0) {
      values.video_input_device_id = videoInputDevices[0].deviceId;
    }

    return this.apply(values);
  }

  public static load(): ClientMediaSettings | null {
    const values = localStorage.getItem('ConferenceSettings');
    if (values) {
      try {
        return new ClientMediaSettings(JSON.parse(values));
      } catch (err) {
        return;
      }
    }

    return null;
  }
}

export interface MediaDevice {
  kind: 'audioInput' | 'audioOutput' | 'videoInput';
  deviceId: string;
  label: string;
}

export interface MediaDeviceRegistry {
  audioInput: MediaDevice[];
  audioOutput?: MediaDevice[];
  videoInput: MediaDevice[];
}
