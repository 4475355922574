import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AbstractComponent } from '@app/models';
import { ScreenService } from '@app/services';

@Component({
  selector: 'app-web-view-alert',
  templateUrl: './web-view-alert.component.html',
  styleUrls: ['./web-view-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebViewAlertComponent extends AbstractComponent {
  public screenService: ScreenService;
  constructor(protected inject?: Injector) {
    super();
    this.screenService = this.inject.get(ScreenService);
  }
}
