import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ORIGIN_PARAM, VERSION_HASH_PARAM, VERSION_NUMBER_PARAM } from '@app/models';
import { RequestService } from '@app/services/request.service';
import { environment } from '@environments/environment';
import { filter, take, tap } from 'rxjs/operators';

/** Сервис загрузки svg спрайтов */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private http: HttpClient;

  public constructor(private request: RequestService, private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  public static svgIconUrl(icon: string): string {
    return `#${icon}`;
  }

  public inject() {
    this.injectFile('svgset.svg');
    this.injectFile('svgset_meet.svg');
  }

  /** Код домена, с которого отправляется запрос, слегка упрощённый, чтобы было проще преобразовывать в URL */
  protected get host(): string {
    return this.request.host.replace('http://', '').replace('https://', 'https-').replace(':', '-');
  }

  /** Внедрение файла SVG в страницу */
  protected injectFile(file: string) {
    const params = new URLSearchParams({
      [ORIGIN_PARAM]: this.host,
      [VERSION_NUMBER_PARAM]: environment.versions.version,
      [VERSION_HASH_PARAM]: environment.versions.gitCommitHash,
    });
    this.http
      .get((window['deployUrl'] || '') + `/assets/icons/${file}?${params}`, {
        responseType: 'text',
        withCredentials: false,
        observe: 'response',
      })
      .pipe(
        take(1),
        filter((response) => !!response.body),
        tap((response) => {
          const div = document.createElement('div');
          div.setAttribute('style', 'display: none;');
          div.innerHTML = response.body;
          document.getElementsByTagName('body')[0].appendChild(div);
        }),
      )
      .subscribe();
  }
}
