import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models';
import { Observable } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { ApplicationState } from '../services';

// критичное время исполнения запроса
const criticalTimeMs = 1000; // = 1 секунда

/**
 * Замер времени запроса
 */
@Injectable()
export class ProfilerInterceptor extends AbstractService implements HttpInterceptor {
  private debug = false;

  public constructor(private applicationState: ApplicationState) {
    super();
    this.applicationState.debug$.pipe(takeUntil(this.destroyed$)).subscribe((debug) => (this.debug = debug));
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => (ok = event instanceof HttpResponse ? 'succeed' : ''),
        (error: HttpErrorResponse) => (ok = 'failed'),
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${request.method} '${request.urlWithParams}' ${ok} in ${elapsed} ms.`;
        if (elapsed > criticalTimeMs && this.debug) {
          this.logger.warn('[slow query] ' + msg);
        } else {
          // logger.debug(msg);
        }
      }),
    );
  }
}
