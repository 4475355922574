import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeaturesService } from '../services';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  public constructor(private featuresService: FeaturesService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({ setHeaders: { Tenant: this.featuresService.tenant } });
    return next.handle(request);
  }
}
