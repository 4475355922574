/* eslint-disable @typescript-eslint/member-ordering */
import { Directive, OnDestroy } from '@angular/core';
import { format } from 'date-fns';
import { Participant, onlineStatuses } from 'lingo2-conference-models';
import { IHash, IImageFile } from 'lingo2-models';
import { BehaviorSubject, Subject, tap, takeUntil } from 'rxjs';

@Directive()
export class ClientParticipant extends Participant implements OnDestroy {
  protected readonly completable: Array<Subject<any>> = [];
  protected readonly destroyed$ = new Subject<boolean>();

  /** Признак выбора для групповой смены ролей или прав доступа */
  public changed$ = this.register(new BehaviorSubject<boolean>(null));
  public checked = false;
  /** Имя */
  public first_name: string;
  public handRaised$ = this.register(new BehaviorSubject<boolean>(null));
  public isOnline$ = this.register(new BehaviorSubject<boolean>(false));
  public lastChangedString: string;
  /** Фамилия */
  public last_name: string;
  /** Уникальный код для формирования ссылки */
  public slug: string;
  /** ID */
  public user_id: string;
  /** Аватара */
  public userpic?: IHash<IImageFile>;

  private lastChanged = new Date();

  public constructor(values?: Partial<ClientParticipant>) {
    super(values);

    if (values) {
      ['role', 'status', 'access', 'options', 'slug', 'first_name', 'last_name', 'userpic'].map((p) => {
        if (p in values) {
          this.setValue(p as any, values[p]);
        }
      });
    }

    this.changed$
      .pipe(
        tap(() => {
          this.lastChanged = new Date();
          this.lastChangedString = format(this.lastChanged, 'HH:mm:ss');
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.completable.map((s) => s.complete());
    this.completable.splice(0);
  }

  /* Признак - была ли загружена информация о пользователе */
  public get userApplied(): boolean {
    return (
      (this.slug || '').length > 0 || // указан SLUG пользователя на портале
      Object.keys(this.userpic || {}).length > 0
    ); // или известна его аватара
  }

  public get isOnline(): boolean {
    return onlineStatuses.includes(this.status);
  }

  /**
   * имя пользователя (только для отладочного использования)
   *
   * @private
   */
  public get debugName(): string {
    return this.last_name || this.first_name || this.user_id;
  }

  public get full_name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  public setValue(key: keyof ClientParticipant, value: any) {
    const instance: any = this;
    instance[key] = value;

    // трансляция статических значений в динамические свойства
    switch (key) {
      case 'status':
        this.isOnline$.next(this.isOnline);
        break;

      case 'options':
        this.handRaised$.next(value.hand_raised);
        // ??? if (value.role_override) {
        //   this.role$.next(value.role_override);
        // }
        break;
    }
  }
  protected register<T extends Subject<any>>(instance: T): T {
    this.completable.push(instance);
    return instance;
  }
}
