import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import { AbstractProfileService } from 'lingo2-forms';
import { IUserAccess, UserProfile } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends AbstractService implements AbstractProfileService {
  private version = 1;

  constructor(private http: HttpClient) {
    super();
  }

  public getProfile(user_id: string): Observable<UserProfile> {
    const url = `${environment.account_url}/profile/${this.version}/profile/${user_id}`;
    return this.http
      .get<UserProfile>(url, { observe: 'response' })
      .pipe(map((response) => new UserProfile(response.body)));
  }

  public getSettings(): Observable<IUserAccess> {
    const url = `${environment.account_url}/profile/${this.version}/settings`;
    return this.http.get<IUserAccess>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }
}
