import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import stringify from 'fast-json-stable-stringify';
import { Classroom, ClassroomDetailsType, IFindClassroomsFilter, IPagedResults, IPagination } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createPagedResults } from '../helpers/create-paged-results';

@Injectable({
  providedIn: 'root',
})
export class ClassroomsService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  /** Учебные классы */
  public getClassrooms(
    filter: Partial<IFindClassroomsFilter>,
    pagination: IPagination,
    details: ClassroomDetailsType[],
  ): Observable<IPagedResults<Classroom[]>> {
    const url = `${environment.content_url}/classrooms/`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter))
      .set('details', stringify(details));
    return this.http.get<Classroom[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleClassroomsResponse),
      // catchError(this.handleError),
    );
  }

  private handleClassroomsResponse(response: HttpResponse<Classroom[]>): IPagedResults<Classroom[]> {
    return createPagedResults(response, (item) => new Classroom(item));
  }
}
