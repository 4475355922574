import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import {
  IAccountUpdate,
  IBalanceUpdate,
  IBillingOperationUpdate,
  IChatMessageUpdate,
  IChatThreadUpdate,
  IEntityUpdate,
  INoticeEvent,
  INoticeOptions,
  INoticesPagedResults,
  IProfileVerificationNotice,
  IReferenceUpdate,
  IScheduleUpdate,
  User,
} from 'lingo2-models';
import { interval, Observable, Observer, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { distinctUntilChanged, share, takeWhile } from 'rxjs/operators';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { AuthService } from '../lingo2-account/auth.service';
import { IWebsocketService, IWsMessage, WebSocketConfig } from './websocket.interfaces';

export interface INoticeRead {
  unread_count: number;
}

export interface INoticeOptionsUpdate {
  result: 'done';
}

export type EntityWatchType = 'meeting' | 'content' | 'thread';

@Injectable({
  providedIn: 'root',
})
export class WebsocketChatService extends AbstractService implements IWebsocketService, OnDestroy {
  public status$: Observable<boolean>;
  private config: WebSocketSubjectConfig<IWsMessage<any>>;

  private connection$: Observer<boolean>;
  private reconnection$: Observable<number>;
  private sendingMessages$: WebSocketSubject<IWsMessage<any>>;
  private receiveMessages$: Subject<IWsMessage<any>> = new Subject<IWsMessage<any>>();

  private status$$: Subscription;
  private receiveMessages$$: Subscription;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  private isConnected: boolean;

  private _authUserId: string;
  private watches: any = {};
  private pinger: any;

  constructor(private authService: AuthService, protected inject?: Injector) {
    super(inject);

    this.load();
  }

  public load() {
    // connection status
    this.status$ = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    this.authService.me$
      .pipe(
        filter((user) => (user ? user.id : null) !== this._authUserId),
        debounceTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe((user) => {
        this._authUserId = user ? user.id : null;
        this.init();
        this.connect();
        this.auth();
      });
  }

  private init() {
    // tslint:disable-next-line: no-console
    this.logger.debug('init');
    const wsConfig: WebSocketConfig = {
      reconnectInterval: 5000,
      reconnectAttempts: 1000,
      url: environment.webSocketUrl, // TODO из настроек contextService.ws
    };

    this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
    this.reconnectAttempts = wsConfig.reconnectAttempts || 100; // number of connection attempts

    this.config = {
      url: wsConfig.url,
      closeObserver: {
        next: () => {
          if (this.pinger) {
            clearInterval(this.pinger);
            this.pinger = null;
          }

          // tslint:disable-next-line: no-console
          this.logger.debug('disconnected');
          this.sendingMessages$ = null;
          this.connection$.next(false);
        },
      },
      openObserver: {
        next: () => {
          // tslint:disable-next-line: no-console
          this.logger.debug('connected');
          this.connection$.next(true);
          this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
          this.reconnectAttempts = wsConfig.reconnectAttempts || 100; // number of connection attempts
          this.auth();

          // this.pinger = setInterval(() => {
          //   this.send('ping', null);
          // }, 2500);
        },
      },
    };

    // run reconnect if not connection
    if (this.status$$) {
      this.status$$.unsubscribe();
    }
    this.status$$ = this.status$.subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (!this.reconnection$ && typeof isConnected === 'boolean' && !isConnected) {
        this.reconnect();
      }
    });

    if (this.receiveMessages$$) {
      this.receiveMessages$$.unsubscribe();
    }
    this.receiveMessages$$ = this.receiveMessages$.subscribe(
      (msg) => {
        if (msg.event !== 'pong') {
          // tslint:disable-next-line: no-console
          this.logger.debug('received', msg);
        }
      },
      (error: ErrorEvent) => {
        // tslint:disable-next-line: no-console
        this.logger.error('received:error', error);
      },
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stopWatchingAll();
    if (this.receiveMessages$$) {
      this.receiveMessages$$.unsubscribe();
    }
    if (this.status$$) {
      this.status$$.unsubscribe();
    }
  }

  /** Авторизация на сокет-сервере */
  private auth() {
    // tslint:disable-next-line: no-console
    this.logger.debug('auth');
    this.send('auth', { token: this.authService.accessToken });
    /** @see onAuthComplete */

    Object.values(this.watches).map((data) => {
      const { entity, id } = data as any;
      this.startWatching(entity, id);
    });
  }

  /**
   * connect to WebSocked
   */
  private connect(): void {
    if (this.isConnected) {
      return;
    }
    this.logger.debug('connecting');

    if (this.sendingMessages$) {
      this.sendingMessages$.unsubscribe();
      this.sendingMessages$.complete();
    }

    this.sendingMessages$ = new WebSocketSubject(this.config);

    const sendMessages$$ = this.sendingMessages$.subscribe(
      (message) => this.receiveMessages$.next(message),
      () => {
        sendMessages$$.unsubscribe();

        if (!this.sendingMessages$) {
          // run reconnect if errors
          this.reconnect();
        }
      },
      () => {
        sendMessages$$.unsubscribe();
      },
    );
  }

  /**
   * reconnect if not connecting or errors
   */
  private reconnect(): void {
    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile((v, index) => index < this.reconnectAttempts && !this.sendingMessages$),
    );

    const reconnection$$ = this.reconnection$.subscribe(
      () => {
        this.connect();
      },
      (err) => {
        this.logger.error('reconnect()', 'error', err);
      },
      () => {
        reconnection$$.unsubscribe();
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (this.sendingMessages$) {
          // tslint:disable-next-line: no-console
          this.logger.debug('reconnect()', 'success');
        } else {
          this.logger.error('reconnect()', 'failed');
          this.receiveMessages$.complete();
          this.connection$.complete();
          if (this.sendingMessages$) {
            this.sendingMessages$.unsubscribe();
            this.sendingMessages$.complete();
            this.sendingMessages$ = null;
          }
        }
      },
    );
  }

  /**
   * on received message
   * рекомендуется написать более строгий метод с указанием возвращаемого типа данных
   * см. onAuthComplete onNoticeRead и другие
   */
  public on<T>(event: string): Observable<T> {
    if (event) {
      return this.receiveMessages$.pipe(
        filter(
          (message: IWsMessage<T>) => typeof message === 'object' && 'event' in message && message.event === event,
        ),
        map((message: IWsMessage<T>) => message.data),
      );
    }
  }

  /** Сообщение об авторизации на сокет-сервере */
  public get onAuthComplete(): Observable<Partial<User>> {
    return this.on<Partial<User>>('auth-complete');
  }

  /** Сообщение о количестве непрочитанных уведомлений */
  public get onNoticeRead(): Observable<INoticeRead> {
    return this.on('notice-read');
  }

  /** Новое уведомление */
  public get onNotice(): Observable<INoticeEvent> {
    return this.on('notice');
  }

  /** Страница уведомлений */
  public get onNotices(): Observable<INoticesPagedResults<any>> {
    return this.on('notices');
  }

  /** Настройки уведомлений */
  public get onNoticeOptions(): Observable<INoticeOptions> {
    return this.on('notice-options');
  }

  /** Подтверждение обновления настроек уведомлений */
  public get onNoticeOptionsUpdate(): Observable<INoticeOptionsUpdate> {
    return this.on('notice-options-update');
  }

  /** Изменение сообщения в нити чата */
  public get onChatMessage(): Observable<IChatMessageUpdate> {
    return this.on('chat-message');
  }

  /** Изменение нити чата */
  public get onChatThread(): Observable<IChatThreadUpdate> {
    return this.on('chat-thread');
  }

  /** Изменение учётной записи */
  public get onAccountUpdate(): Observable<IAccountUpdate> {
    return this.on('account');
  }

  /** Изменение финансового профиля (баланс, подписки, ...) */
  public get onBalanceUpdate(): Observable<IBalanceUpdate> {
    return this.on('balance');
  }

  /** Изменение операции */
  public onBillingOperation(id: string): Observable<IBillingOperationUpdate> {
    return this.on<IBillingOperationUpdate>('operation').pipe(filter((update) => update.operation_id === id));
  }

  /** Изменение расписания */
  public get onScheduleUpdate(): Observable<IScheduleUpdate> {
    return this.on('schedule');
  }

  /** Изменение справочников */
  public get onReferenceUpdate(): Observable<IReferenceUpdate> {
    return this.on('reference');
  }

  /** Верификация профиля пользователя */
  public get onVerification(): Observable<IProfileVerificationNotice> {
    return this.on('verification');
  }

  /** Изменение сущности */
  public onEntityUpdate(entity: EntityWatchType, id: string): Observable<IEntityUpdate> {
    return this.on<IEntityUpdate>('update').pipe(filter((update) => update.entity === entity && update.id === id));
  }

  /** Подключить наблюдение за изменениями сущности */
  public startWatching(entity: EntityWatchType, id: string) {
    if (!id) {
      return;
    }
    this.send('watch-start', { entity, id });
    this.watches[`${entity}-${id}`] = { entity, id };
  }

  /** Отключить наблюдение за изменениями сущности */
  public stopWatching(entity: EntityWatchType, id: string) {
    if (!id) {
      return;
    }
    this.send('watch-stop', { entity, id });
    delete this.watches[`${entity}-${id}`];
  }

  /** Отключить наблюдение за любыми изменениями сущностей */
  public stopWatchingAll() {
    this.send('watch-stop-all');
    this.watches = {};
  }

  /** send message to server */
  public send(event: string, data: any = {}): void {
    if (event) {
      if (this.isConnected && this.sendingMessages$) {
        // this.websocket$.next(<any>JSON.stringify({event, data}));
        this.sendingMessages$.next({ event, data });
        if (event !== 'ping') {
          this.logger.debug('send', { event, data });
        }
      } else {
        // this.logger.debug('send:error', { event, data }, 'Socket not connected or disconnected');
        // this.logger.debug('send retry', { event, data });
        // setTimeout(() => {
        //   this.send(event, data);
        // }, 250 + Math.round(1000 * Math.random()));
      }
    }
  }
}
