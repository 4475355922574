import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ILogger, createLogger } from '@app/models/logger';
import { ErrorService } from '@app/services/error.service';
import { environment } from '@environments/environment';
import { FeaturesType } from '@environments/IEnvironment';
import { Subject } from 'rxjs';

/** Упрощённый абстрактный класс для сервисов */
@Injectable()
export abstract class AbstractService implements OnDestroy {
  protected readonly completable: Array<Subject<any>> = [];
  protected readonly destroyed$ = new Subject<boolean>();
  protected errorService: ErrorService;
  protected logger: ILogger;

  public constructor(protected inject?: Injector) {
    this.logger = createLogger('onclass:' + this.constructor.name);

    if (!inject) {
      /* const message = `Injector is not defined, ${this.constructor.name}`;
      this.logger.error(message);*/
      return this;
    }

    this.errorService = inject.get(ErrorService);
    return this;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.completable.map((s) => s.complete());
    this.completable.splice(0);
  }

  /** Включена ли отдельная функциональная возможность, логический блок или компонента */
  public isFeatureAvailable(feature: FeaturesType): boolean {
    return environment.features[feature];
  }

  protected register<T extends Subject<any>>(instance: T): T {
    this.completable.push(instance);
    return instance;
  }

  protected try(fn: () => void, title?: string) {
    try {
      fn();
    } catch (err) {
      this.errorService.err(err);
      if (title) {
        this.logger.error(title, err);
      } else {
        this.logger.error(err);
      }
    }
  }
}
