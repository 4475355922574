/**
 * Для уменьшения размера main.js в AppModule вынесены только 100% нужные и важные сервисы, модули, компоненты
 * Остальное загружается через механизм загрузки модулей
 *
 *     @see loadChildren: () => import('@app/app.module').then(m => m.AppModule),
 */
import { NgModule } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { Routes } from '@angular/router';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';

import { ToParentSiteRedirectPath } from '@app/models';

import { AppComponent } from './app.component';

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export const routes: Routes = [
  {
    path: ToParentSiteRedirectPath,
    resolve: {
      url: externalUrlProvider,
    },
    component: AppComponent,
  },
  {
    path: '**',
    loadChildren: () => import('../main/main.module').then((m) => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        /**
         * TODO @sample Как этим пользоваться?
         */
        const externalUrl = route.paramMap.get('externalUrl');
        (window as any).open(externalUrl, '_self');
      },
    },
  ],
})
export class AppRoutingModule {}
