export interface IOnlineSearchFileUrls {
  full: string;
  raw: string;
  regular: string;
  small: string;
  small_s3: string;
  thumb: string;
}

export interface IOnlineSearchFile {
  id: number;
  width: number;
  height: number;
  urls: IOnlineSearchFileUrls;
  user: any;
}
