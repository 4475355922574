import { Injectable, Injector } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { UUIDv4 } from '@app/models/helpers';
import {
  EventEnum,
  MeetingUpdatedEvent,
  MeetingStartedEvent,
  MeetingFinishedEvent,
  StartMeetingMessage,
  StopMeetingMessage,
  MeetingAccessOptions,
  ChangeMeetingAccessMessage,
  MeetingAccessChangedEvent,
  MeetingOverrideEvent,
  InitializeMeetingMessage,
  MeetingOverrideCheckEvent,
  CheckMeetingForOverride,
} from 'lingo2-conference-models';
import { Observable, of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';
import { ApplicationState } from './application.state';
import { MeetingState } from './meeting.state';
import { WebsocketService } from './websocket';

/**
 * Сервис отвечает за синхронизацию состояний митинга
 *
 * @see WsBoardsSyncService
 * @see WsMeetingSyncService
 * @see WsParticipantsSyncService
 */
@Injectable({
  providedIn: 'root',
})
export class WsMeetingSyncService extends AbstractService {
  constructor(
    protected applicationState: ApplicationState,
    protected meetingState: MeetingState,
    protected ws: WebsocketService,
    protected injector: Injector,
  ) {
    super();
    this.init();
  }

  protected init() {
    of(this.watchWsMeetingEvents$).pipe(mergeAll(), takeUntil(this.destroyed$)).subscribe();
  }

  // ---------- управление митингом

  public initializeMeeting$(): Observable<MeetingUpdatedEvent> {
    const message = InitializeMeetingMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Запустить митинг */
  public startMeeting() {
    this.ws.send(StartMeetingMessage.createInstance({}));
  }

  /** Остановить митинг */
  public stopMeeting() {
    this.ws.send(StopMeetingMessage.createInstance({}));
  }

  /** Изменить глобальные настройки доступа митинга */
  public changeAccess(access: Partial<MeetingAccessOptions>) {
    this.ws.send(ChangeMeetingAccessMessage.createInstance({ access }));
  }

  /** Проверить митинги на возможность замены */
  public checkMeetingForOverride$(meeting_id: string[]): Observable<MeetingOverrideCheckEvent> {
    const message = CheckMeetingForOverride.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      meeting_id,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  // ---------- наблюдение за митингом

  protected get watchWsMeetingEvents$(): Observable<any> {
    return of(
      this.onMeetingUpdatedEvent$,
      this.onMeetingStartedEvent$,
      this.onMeetingFinishedEvent$,
      // TODO this.onCalledToFollowEvent$,
      // TODO this.onCallToMeetingEvent$,
      this.onMeetingAccessChangedEvent$,
      this.onMeetingOverrideEvent$,
    ).pipe(mergeAll());
  }

  /** Событие - митинг изменён */
  protected get onMeetingUpdatedEvent$(): Observable<any> {
    return this.ws.on<MeetingUpdatedEvent>(EventEnum.MeetingUpdatedEvent).pipe(
      tap((event: MeetingUpdatedEvent) => {
        this.meetingState.updateMeeting(event.meeting as any);
        // if (event.meeting.meeting_id_override) {
        //   this.ensureOverrideMeeting()
        // }
      }),
    );
  }
  /** Событие - митинг начат */
  protected get onMeetingStartedEvent$(): Observable<any> {
    return this.ws.on<MeetingStartedEvent>(EventEnum.MeetingStartedEvent).pipe(
      tap((event: MeetingStartedEvent) => {
        this.meetingState.updateRuntimeStatus(event.meeting);
      }),
    );
  }

  /** Событие - митинг завершён */
  protected get onMeetingFinishedEvent$(): Observable<any> {
    return this.ws.on<MeetingFinishedEvent>(EventEnum.MeetingFinishedEvent).pipe(
      tap((event: MeetingFinishedEvent) => {
        this.meetingState.updateRuntimeStatus(event.meeting);
      }),
    );
  }

  // /** Событие - призыв следовать за пользователем */
  // protected get onCalledToFollowEvent$(): Observable<any> {
  //   return this.websocket2Service.on<CalledToFollowEvent>(EventEnum.CalledToFollowEvent)
  //     .pipe(
  //       tap((event) => {
  //         //
  //       }),
  //     );
  // }

  // /** Событие - призыв перейти к другому митингу */
  // protected get onCallToMeetingEvent$(): Observable<any> {
  //   return this.websocket2Service.on<CallToMeetingEvent>(EventEnum.CallToMeetingEvent)
  //     .pipe(
  //       tap((event) => {
  //         //
  //       }),
  //     );
  // }

  /** Событие - изменены права доступа митинга */
  protected get onMeetingAccessChangedEvent$(): Observable<any> {
    return this.ws.on<MeetingAccessChangedEvent>(EventEnum.MeetingAccessChangedEvent).pipe(
      tap((event: MeetingAccessChangedEvent) => {
        this.meetingState.updateAccess(event.access);
      }),
    );
  }

  /** Событие - замена текущего митинга */
  protected get onMeetingOverrideEvent$(): Observable<any> {
    return this.ws.on<MeetingOverrideEvent>(EventEnum.MeetingOverrideEvent).pipe(
      tap((event: MeetingOverrideEvent) => {
        this.meetingState.setOverrideMeeting(event.meeting);
      }),
    );
  }

  protected calcMeetingState() {
    // TODO
    /*
    protected onRuntimeStatusUpdated() {
      switch (this._meeting.runtime_status) {
      case MeetingRuntimeStatusEnum.started:
        this.state.next(MeetingStateEnum.MeetingIsGoing);
        break;

      case MeetingRuntimeStatusEnum.finished:
        this.state.next(MeetingStateEnum.MeetingIsOver);
        break;
      }
    }
    */
  }
}
