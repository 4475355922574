import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import {
  Country,
  Language,
  LanguageLevel,
  Subject,
  SubjectLevel,
  SubjectLevelsRegistry,
  SubjectsRegistry,
  UserServiceCategory,
} from 'lingo2-models';
import { ReplaySubject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ReferenceRequireType =
  | 'subjects$'
  | 'languages$'
  | 'languageLevels$'
  | 'subjectsV2$'
  | 'subjectLevels2$'
  | 'countries$'
  | 'timezones$'
  | 'userServiceCategories$'
  | 'collectionCategories$';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends AbstractService {
  protected _subjects = new ReplaySubject<Subject[]>(1);
  public subjects$ = this._subjects.asObservable();

  protected _subjectsV2 = new ReplaySubject<SubjectsRegistry>(1);
  public subjectsV2$ = this._subjectsV2.asObservable();

  protected _subjectLevels = new ReplaySubject<SubjectLevel[]>(1);
  public subjectLevels$ = this._subjectLevels.asObservable();

  protected _subjectLevels2 = new ReplaySubject<SubjectLevelsRegistry>(1);
  public subjectLevels2$ = this._subjectLevels2.asObservable();

  protected _languages = new ReplaySubject<Language[]>(1);
  public languages$ = this._languages.asObservable();

  protected _languageLevels = new ReplaySubject<LanguageLevel[]>(1);
  public languageLevels$ = this._languageLevels.asObservable();

  protected _countries = new ReplaySubject<Country[]>(1);
  public countries$ = this._countries.asObservable();

  protected _userServiceCategories = new ReplaySubject<UserServiceCategory[]>(1);
  public userServiceCategories$ = this._userServiceCategories.asObservable();

  private loaded = new Map<ReferenceRequireType, boolean>();
  private locale: string;

  constructor(private http: HttpClient) {
    super();
  }

  public onLocaleChanged(locale: string) {
    this.locale = locale;
    const alreadyLoaded = [...this.loaded.keys()];
    this.loaded.clear();
    this.load(alreadyLoaded);
  }

  /**
   * Call configService.load([...]) to initialize references Observables
   */
  public load(references: ReferenceRequireType[]) {
    references.map((reference) => {
      if (this.loaded.has(reference)) {
        return;
      }
      this.loaded.set(reference, true);

      switch (reference) {
        case 'subjects$':
          return this.loadSubjects(this.locale);

        case 'languages$':
          return this.loadLanguages(this.locale);

        case 'languageLevels$':
          return this.loadLanguageLevels();

        case 'subjectsV2$':
          return this.loadSubjectsV2(this.locale);

        case 'subjectLevels2$':
          return this.loadSubjectLevels2(this.locale);

        case 'userServiceCategories$':
          return this.loadUserServiceCategories(this.locale);

        case 'countries$':
          return this.loadCountries(this.locale);

        // case 'timezones$':
        //   retutn this.loadTimezones(this.locale);

        // case 'collectionCategories$':
        //   return this.loadCollectionCategories(this.locale);
        default:
          alert('Reference ' + reference + ' not defined');
      }
    });
  }

  // public onVersionChanged(locale: string,
  //                         entity: 'languages' | 'subjects' | 'countries',
  //                         version: string) {
  //   switch (entity) {
  //     case 'subjects':
  //         this.loadSubjects(locale);
  //         return;
  //
  //     case 'languages':
  //       this.loadLanguages(locale);
  //       return;
  //
  //     case 'countries':
  //       this.loadCountries(locale);
  //       return;
  //   }
  // }

  protected loadSubjects(locale: string) {
    this.getSubjects(locale)
      .pipe(
        tap((values) => this._subjects.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadSubjectsV2(locale: string) {
    this.getSubjectsV2$(locale)
      .pipe(
        tap((values) => this._subjectsV2.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadLanguages(locale: string) {
    this.getLanguages$(locale, true)
      .pipe(
        tap((values) => this._languages.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadSubjectLevels2(locale: string) {
    this.getSubjectLevels2$(locale, true)
      .pipe(
        tap((values) => this._subjectLevels2.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadSubjectLevels() {
    this.getSubjectLevels$(true)
      .pipe(
        tap((values) => this._subjectLevels.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadLanguageLevels() {
    this.getLanguageLevels$(true)
      .pipe(
        tap((values) => this._languageLevels.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadUserServiceCategories(locale: string) {
    this.getUserServiceCategories$(locale)
      .pipe(
        tap((values) => this._userServiceCategories.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected loadCountries(locale: string) {
    this.getCountries$(locale, true)
      .pipe(
        tap((values) => this._countries.next(values)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public getSubjects(locale: string, all = false): Observable<Subject[]> {
    const url = `${environment.content_url}/reference/subjects`;
    const params = new HttpParams().set('locale', locale).set('all', all ? '1' : '0');
    return this.http.get<Subject[]>(url, { params, observe: 'body' });
  }

  public getSubjectsV2$(locale: string): Observable<SubjectsRegistry> {
    const url = `${environment.content_url}/reference/subjects/2`;
    const params = new HttpParams().set('locale', locale).set('all', '1');
    return this.http.get<SubjectsRegistry>(url, { params, observe: 'body' });
  }

  public getLanguages$(locale: string, all = false): Observable<Language[]> {
    const url = `${environment.content_url}/reference/languages`;
    const params = new HttpParams().set('locale', locale).set('all', all ? '1' : '0');
    return this.http.get<Language[]>(url, { params, observe: 'body' });
  }

  public getSubjectLevels2$(locale: string, all = false): Observable<SubjectLevelsRegistry> {
    const url = `${environment.content_url}/reference/difficulty-levels/2`;
    const params = new HttpParams().set('locale', locale).set('all', all ? '1' : '0');
    return this.http.get<SubjectLevelsRegistry>(url, { params, observe: 'body' });
  }

  public getSubjectLevels$(all = false): Observable<SubjectLevel[]> {
    const url = `${environment.content_url}/reference/difficulty-levels`;
    // TODO const url = `${environment.content_url}/reference/subject-Levels`;
    const params = new HttpParams().set('all', all ? '1' : '0');
    return this.http.get<SubjectLevel[]>(url, { params, observe: 'body' });
  }

  public getLanguageLevels$(all = false): Observable<LanguageLevel[]> {
    const url = `${environment.content_url}/reference/language-levels`;
    const params = new HttpParams().set('all', all ? '1' : '0');
    return this.http.get<LanguageLevel[]>(url, { params, observe: 'body' });
  }

  public getUserServiceCategories$(locale: string): Observable<UserServiceCategory[]> {
    const url = `${environment.content_url}/reference/user-service-categories`;
    const params = new HttpParams().set('locale', locale);
    return this.http.get<UserServiceCategory[]>(url, { params, observe: 'body' });
  }

  public getCountries$(locale: string, all = false): Observable<Country[]> {
    const url = `${environment.account_url}/reference/countries`;
    const params = new HttpParams().set('locale', locale).set('all', all ? '1' : '0');
    return this.http.get<Country[]>(url, { params, observe: 'body' });
  }

  public getEnvBillingFrameHost() {
    return environment.stripe_iframe_host;
  }

  public getEnvAccountUrl() {
    return environment.account_url;
  }

  public getEnvBillingPublicUrl() {
    return environment.billing_public_url;
  }
}
