import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/** Сервис загрузки svg спрайтов */
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public unreadMessagesCount$: Observable<number>;
  protected unreadMessagesCount = new BehaviorSubject<number>(0);

  constructor() {
    this.unreadMessagesCount$ = this.unreadMessagesCount.asObservable();
  }

  public set unreadMessageCount(count: number) {
    this.unreadMessagesCount.next(count);
  }
}
