/* eslint-disable no-console */
import { differenceInMilliseconds } from 'date-fns';
import { difference, every, filter } from 'lodash-es';

const toHexColor = (namespace) => {
  const colors = [
    '#e51c23',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#5677fc',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#259b24',
    '#8bc34a',
    '#afb42b',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  let hash = 0;

  if (namespace.length === 0) {
    return hash;
  }

  for (let i = 0; i < namespace.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = namespace.charCodeAt(i) + ((hash << 5) - hash);
    // eslint-disable-next-line no-bitwise
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

const options = {
  enabled: false,
  filter: '',
  level: 'error',
};

export function enableLogs(_filter: string, _level: string) {
  options.enabled = true;
  options.filter = _filter;
  options.level = _level;
}

export function disableLogs() {
  options.enabled = false;
}

const logger = (level, namespace, input) => {
  if (input.length === 0) {
    return;
  }
  if (typeof console === 'undefined') {
    return;
  }

  // const root: any = (typeof self === 'object' && self.self === self && self)
  // || (typeof global === 'object' && global.global === global && global)
  // || this;

  const _enabled = !!options.enabled && (options.filter || '').length > 0;

  if (!_enabled) {
    if (level === 'error') {
      console.error(...input);
    }
    if (level === 'warn') {
      console.warn(...input);
    }
    return;
  }

  // if (!filterLevel(level)) {
  //   return;
  // }

  const ns = namespace.split(':');

  // Для выбора нескольких параметров отображения логов
  // Параметры указываются через |
  if (
    every(options.filter.split('|'), (element) => {
      const rt = element.split(':');
      const nsDiffrent = difference(rt, ns);
      return filter(nsDiffrent, (el) => el !== '*').length !== 0;
    })
  ) {
    return;
  }

  const datetime =
    new Date().toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }) +
    '.' +
    new Date().getMilliseconds();

  if (input[0].toString().indexOf('Table:') > -1) {
    console.log(`${datetime} %c ${namespace} `, `background: ${toHexColor(namespace)}; color: white`, input[0]);
    console.table(input[1]);
    return;
  }

  console.groupCollapsed(
    `${datetime} %c ${namespace} `,
    `background: ${toHexColor(namespace)}; color: white`,
    input[0],
  );

  switch (level) {
    case 'error':
      console.error(...input);
      break;

    case 'warn':
      console.warn(...input);
      break;

    case 'debug':
      // tslint:disable-next-line:no-console
      console.debug(...input);
      break;

    default:
      console.log(...input);
      break;
  }

  console.groupCollapsed('Call stack');

  // пропустить два службных уровня стека, которые указывают на этот самый файл logger.ts
  const [t, , , ...stack] = new Error().stack.split(/\n/);
  console.log([t, ...stack].join('\n'));

  console.groupEnd();

  console.groupEnd();
};

export interface ILogger {
  verbose: (...args) => void;
  info: (...args) => void;
  log: (...args) => void;
  warn: (...args) => void;
  debug: (...args) => void;
  error: (...args) => void;
  exception: (...args) => void;
  trace: (...args) => void;
  profilingStart(...args): void;
  profilingStop(...args): void;
}

const profilingTimers = new Map<string, [Date, any[]]>();

export function createLogger(namespace: string): ILogger {
  return {
    verbose: (...input) => logger('verbose', namespace, input),
    info: (...input) => logger('info', namespace, input),
    log: (...input) => logger('log', namespace, input),
    warn: (...input) => logger('warn', namespace, input),
    debug: (...input) => logger('debug', namespace, input),
    error: (...input) => logger('error', namespace, input),

    exception: (...input) => logger('error', namespace, input),
    trace: (...input) => logger('debug', namespace, input),

    profilingStart: (key: string, ...optionalParams: any[]) => {
      const _enabled = !!options.enabled;
      if (!_enabled) {
        return;
      }

      if (!profilingTimers.has(key)) {
        profilingTimers.set(key, [new Date(), optionalParams]);
      }
    },
    profilingStop: (key: string, ...optionalParams: any[]) => {
      const _enabled = !!options.enabled;
      if (!_enabled) {
        return;
      }

      if (profilingTimers.has(key)) {
        const [timer, optionalParams1] = profilingTimers.get(key);
        logger('trace', namespace, [
          'profiling ' + key + ' in ' + differenceInMilliseconds(new Date(), timer) / 1000 + 's',
          ...optionalParams1,
          ...optionalParams,
        ]);
        profilingTimers.delete(key);
      }
    },
  };
}

const dummyLogger = () => {};

export function createDummyLogger(): ILogger {
  return {
    verbose: () => dummyLogger(),
    info: () => dummyLogger(),
    log: () => dummyLogger(),
    warn: () => dummyLogger(),
    debug: () => dummyLogger(),
    error: () => dummyLogger(),
    exception: () => dummyLogger(),
    trace: () => dummyLogger(),

    profilingStart: () => dummyLogger(),
    profilingStop: () => dummyLogger(),
  };
}
