import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { ClientMeeting, ClientMeetingSession, MeetingStateEnum } from '@app/models/meeting';
import { environment } from '@environments/environment';
import { MeetingAccessOptions, MeetingRuntimeStatusEnum } from 'lingo2-conference-models';
import { Meeting as ContentMeeting } from 'lingo2-models';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, pluck } from 'rxjs/operators';

/** Хранилище информации о текущем проводимом или наблюдаемом митинге */
@Injectable({
  providedIn: 'root',
})
export class MeetingState extends AbstractService {
  /** Состояние обмена данными во время проведения митинга */
  public state$: Observable<MeetingStateEnum>;

  /** Общая информация о митинге (описание, детали) */
  public meetingSession$: Observable<ClientMeetingSession>;

  /** Информация о проведении митинга (процесс) */
  public meeting$: Observable<ClientMeeting>;

  /** Селектор - текущий статус митинга */
  public runtimeStatus$: Observable<MeetingRuntimeStatusEnum>;

  /** Полное описание митинга */
  public contentMeeting$: Observable<ContentMeeting>;

  /** Очередь на обновление описания митинга */
  public contentMeetingLoad$ = this.register(new Subject<string>());

  /** Информация о митинге "из истории" */
  public overrideMeeting$: Observable<ClientMeeting>;

  /** Полное описание митинга "из истории" */
  public overrideContentMeeting$: Observable<ContentMeeting>;

  // /** Очередь на обновление описания митинга "из истории" */
  // public overrideContentMeetingLoad$ = this.register(new Subject<string>());

  protected state = this.register(new ReplaySubject<MeetingStateEnum>(1));
  protected meetingSession = this.register(new ReplaySubject<ClientMeetingSession>(1));
  protected _meeting: ClientMeeting;
  protected meeting = this.register(new ReplaySubject<ClientMeeting>(1));
  protected contentMeeting = this.register(new ReplaySubject<ContentMeeting>(1));
  protected _overrideMeeting: ClientMeeting;
  protected overrideMeeting = this.register(new ReplaySubject<ClientMeeting>(1));
  protected overrideContentMeeting = this.register(new ReplaySubject<ContentMeeting>(1));

  public constructor(private http: HttpClient, protected readonly inject: Injector) {
    super(inject);

    this.state$ = this.state.asObservable();
    this.meetingSession$ = this.meetingSession.asObservable();
    this.meeting$ = this.meeting.asObservable();
    this.contentMeeting$ = this.contentMeeting.asObservable();
    this.overrideMeeting$ = this.overrideMeeting.asObservable();
    this.overrideContentMeeting$ = this.overrideContentMeeting.asObservable();

    this.runtimeStatus$ = this.meeting$.pipe(pluck('runtime_status'), distinctUntilChanged());
  }

  public setState(state: MeetingStateEnum) {
    this.state.next(state);
  }

  public setMeetingSession(meetingSession: ClientMeetingSession) {
    this.meetingSession.next(meetingSession);
  }

  public setMeeting(meeting: ClientMeeting) {
    if (this._meeting) {
      // ?? } && this._meeting.meeting_id === meeting.meeting_id) {
      return this.updateMeeting(meeting);
    }

    // // TODO override meeting
    // if (this._meeting.meeting_id_override === meeting.meeting_id) {
    //   return this.updateOverrideMeeting(meeting);
    // }

    this._meeting = new ClientMeeting(meeting);
    this.meeting.next(this._meeting);
  }

  public updateMeeting(meeting: ClientMeeting) {
    if (!this._meeting) {
      return this.setMeeting(meeting);
    }

    this.applyValues(this._meeting, meeting, ['runtime_status', 'engine', 'type']);
    this.meeting.next(this._meeting);
  }

  public updateRuntimeStatus(meeting: ClientMeeting) {
    if (this._meeting && this._meeting.meeting_id === meeting.meeting_id) {
      this.applyValues(this._meeting, meeting, ['runtime_status']);
      this.meeting.next(this._meeting);
    }
  }

  public updateAccess(access: Partial<MeetingAccessOptions>) {
    this._meeting.access = {
      ...this._meeting.access,
      ...access,
    };
    this.meeting.next(this._meeting);
  }

  public setOverrideMeeting(meeting: ClientMeeting) {
    if (!meeting) {
      this._overrideMeeting = null;
    } else {
      this._overrideMeeting = new ClientMeeting(meeting);
    }
    this.overrideMeeting.next(this._overrideMeeting);
  }

  /** Загрузить или обновить полную информацию о митинге */
  public loadContentMeeting() {
    this.contentMeetingLoad$.next(this._meeting?.meeting_id);
  }

  /** Сохранить полное описание митинга */
  public setContentMeeting(meeting: ContentMeeting) {
    this.contentMeeting.next(meeting);
  }

  // /** Загрузить или обновление полную информацию о митинге "из истории" */
  // public loadOverrideContentMeeting() {
  //   this.overrideContentMeetingLoad$.next(this._overrideMeeting?.meeting_id);
  // }

  /** Сохранить полное описание митинга "из истории" */
  public setOverrideContentMeeting(meeting: ContentMeeting) {
    this.overrideContentMeeting.next(meeting);
  }

  /** Принудительно стартовать митинг */
  public forceStartMeeting(id: string) {
    const url = `${environment.content_url}/meeting/${id}/force-start`;
    return this.http.post<boolean>(url, null, { observe: 'response' }).pipe(
      map((response) => response.body as any),
      // catchError(this.handleError),
    );
  }

  protected applyValues(
    meeting: ClientMeeting,
    values: Partial<ClientMeeting>,
    allowed: Array<keyof ClientMeeting>,
  ): ClientMeeting {
    allowed.map((f) => {
      if (values[f] !== undefined) {
        (meeting as any)[f] = values[f];
      }
    });

    return meeting;
  }
}
