import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { IOnlineSearchFile } from '@app/models';
import {
  IMediaFile,
  IPagedResults,
  IFindFileFilter,
  FileTypeEnum,
  IPagination,
  FilePurposeEnum,
  SourceTypeEnum,
  ImageSizeEnum,
} from 'lingo2-models';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IImageThumbnail {
  id?: string;
  url?: string;
  size: string;
  width: number;
  height: number;
  round?: boolean;
  format?: string;
}

export interface IFileType {
  id: string;
  author_id: string;
  filename: string;
  type: FileTypeEnum;
  images?: IImageThumbnail[];
  media?: IMediaFile[];
  storage_file_url?: string; // link to original image (old style)
  isProcessing?: boolean; // show that file is in process now
  parent_file_id?: string; // ID for original user avatar
}

export interface IUploadFileParams {
  target: 'video' | 'audio' | 'image';
  purpose: FilePurposeEnum;
  source: SourceTypeEnum;
  filename: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  public static get isHighDensity() {
    return (
      (window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)',
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)',
          ).matches)) ||
      (window.devicePixelRatio && window.devicePixelRatio > 1.3)
    );
  }

  public static get isRetina() {
    return (
      ((window.matchMedia &&
        (window.matchMedia(
          'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
        ).matches ||
          window.matchMedia(
            'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
          ).matches)) ||
        (window.devicePixelRatio && window.devicePixelRatio >= 2)) &&
      /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    );
  }

  /**
   * @usage <img *ngIf="user.userpic_id" [src]="FilesService.fileUrlBySize(user.userpic_id, ImageSizeEnum.sm)" />
   * @usage <img *ngIf="meeting.cover_id" [src]="FilesService.fileUrlBySize(meeting.cover_id, ImageSizeEnum.md)" />
   */
  public static getFileUrlBySize(id: string, size: ImageSizeEnum = ImageSizeEnum.md): string {
    if (!id) {
      return null;
    }
    let retina_suffix = '';
    if ((this.isHighDensity || this.isRetina) && size !== ImageSizeEnum.wide) {
      retina_suffix = 'X2';
    }
    return id ? `${environment.files_url}/file/format/${id}/${size}${retina_suffix}` : null;
  }

  // for lingo2-forms project
  public getFormFileUrlBySize(id: string): string {
    return FilesService.getFileUrlBySize(id);
  }

  /**
   * Поиск файла по ID
   *
   * @param id string
   */
  public getFileById(id: string): Observable<IFileType> {
    const url = `${environment.files_url}/file/${id}`;
    return this.http.get<IFileType>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Получение "сырого" файла по URL
   */
  public async getFileBlob(url: string): Promise<Blob> {
    const res = await fetch(url);
    return res.blob();
  }

  /**
   * Поиск файла по ID
   *
   * @param id string
   */
  public changeFav(id: string): Observable<IFileType> {
    const url = `${environment.files_url}/file/fav/${id}`;
    return this.http.post<IFileType>(url, { observe: 'response' }).pipe(
      map((response) => response),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск файлов по фильтру
   *
   * @param filter Partial<IFindFileFilter>
   * @param pagination IPagination
   */
  public getRecentFiles(
    filter: Partial<IFindFileFilter>,
    pagination: IPagination,
  ): Observable<IPagedResults<IFileType[]>> {
    const url = `${environment.files_url}/recent`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));

    return this.http.get<IFileType[]>(url, { params, observe: 'response' }).pipe(
      map((response: HttpResponse<IFileType[]>) => {
        const content = response.body;
        return {
          results: content,
          page: +response.headers.get('X-Pagination-Page'),
          pageSize: +response.headers.get('X-Pagination-PageSize'),
          total: +response.headers.get('X-Pagination-Total'),
          totalPages: +response.headers.get('X-Pagination-TotalPages'),
        };
      }),
      // catchError(this.handleError),
    );
  }

  public getOnlineFiles(query: string): Observable<IPagedResults<IOnlineSearchFile[]>> {
    if (!query || query.length === 0) {
      return new Observable<IPagedResults<[]>>();
    }
    const url = `${environment.files_url}/web-search/?q=${query}`;
    // const params = new HttpParams()
    //   .set('page', pagination.page.toString())
    //   .set('page-size', pagination.pageSize.toString())
    //   .set('filter', JSON.stringify(filter));

    return this.http.get<IOnlineSearchFile[]>(url, { observe: 'response' }).pipe(
      map((response: HttpResponse<IOnlineSearchFile[]>) => {
        const content = response.body;
        return {
          results: content,
          page: +response.headers.get('X-Pagination-Page'),
          pageSize: +response.headers.get('X-Pagination-PageSize'),
          total: +response.headers.get('X-Pagination-Total'),
          totalPages: +response.headers.get('X-Pagination-TotalPages'),
        };
      }),
      // catchError(this.handleError),
    );
  }

  public uploadFileByUrl(uri: string, purpose: FilePurposeEnum, extension?: string): Observable<IFileType> {
    let url = `${environment.files_url}/file/upload-by-url/?url=${uri}&purpose=${purpose}`;
    if (extension) {
      url = `${environment.files_url}/file/upload-by-url/?url=${uri}&purpose=${purpose}&extension=${extension}`;
    }
    return this.http.post<IFileType>(url, { observe: 'response' }).pipe(
      map((response) => response),
      // catchError(this.handleError),
    );
  }

  public uploadFileByData(
    data: any,
    purpose: FilePurposeEnum,
    ext: string,
    parent_file_id?: string,
  ): Observable<IFileType> {
    const url = `${environment.files_url}/file/upload-by-data/?purpose=${purpose}&ext=${ext}`;
    return this.http.post<IFileType>(url, { data, parent_file_id }, { observe: 'body' }).pipe(
      map((response) => response),
      // catchError(this.handleError),
    );
  }

  /**
   * Загрузить BLOB на файловый сервер
   */
  public uploadFileAsBlob(blob: any, params: IUploadFileParams): Observable<IFileType> {
    const formData = new FormData();
    formData.append('file', blob, params.filename);

    const url = `${environment.files_url}/file/upload+process`;
    let _params = new HttpParams();
    Object.keys(params).map((p) => {
      _params = _params.set(p, params[p]);
    });

    return this.http.post<IFileType>(url, formData, { params: _params, observe: 'body' }).pipe(
      map((response) => response),
      // catchError(this.handleError),
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
