import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  /** http://hostname:port -> http://hostname:port */
  public get host(): string {
    const browserWindow: any = window || {};
    const location: any = browserWindow.location || {};
    return location.origin;
  }

  /** http://hostname:port -> hostname */
  public get hostname(): string {
    const browserWindow: any = window || {};
    const location: any = browserWindow.location || {};
    return location.hostname || '';
  }
}
