import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as platform from 'platform';
import { BehaviorSubject } from 'rxjs';

import { IPlatform } from './interfaces';
import { devices } from './variables';

/** *
 * Сервис определения платформы (mobile, tablet, normal)
 ***/
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  models$: BehaviorSubject<IPlatform> = new BehaviorSubject<IPlatform>(null);

  private _model: IPlatform = {
    IE: false,
    Edge: false,
    Firefox: false,
    Chrome: false,

    IOS: false,
    Android: false,
    iPhone: false,
    WebView: false,
    Safari: false,
    MobileSafari: false,
    MobileChrome: false,

    mobile: false,
    tablet: false,
    normal: false,

    IPhoneXAndYoung: false,
    ToolsExpanded: false,
  };

  constructor(public deviceService: DeviceDetectorService) {
    const splitName = platform.name.split(' ');
    const vendor = splitName[0];
    const IOS = platform.os.family === 'iOS';
    const Android = platform.os.family === 'Android';
    const iPhone = !!navigator.userAgent.match(/iPhone/i);
    const Firefox = vendor === 'Firefox';
    const Chrome = ['Chrome', 'Yandex'].indexOf(vendor) >= 0;
    const Safari = vendor === 'Safari';

    this._model = {
      ...this._model,

      mobile: this.deviceService.isMobile(),
      tablet: this.deviceService.isTablet(),
      normal: this.deviceService.isDesktop(),

      IE: vendor === 'IE',
      Edge: vendor === 'Microsoft' && splitName[1] === 'Edge',
      Firefox,
      Chrome,
      Safari,

      IOS,
      MobileSafari: IOS && Safari, // ??
      MobileChrome: IOS && Chrome, // ??
      Android,
      iPhone,
      WebView: platform.ua.indexOf('WebView') !== -1,
    };

    // вызов отдельно, потому что обращение к PlatformService._instance._model
    this._model.IPhoneXAndYoung = this.isIPhoneXAndYoung;
    this._model.ToolsExpanded = this.isToolsExpanded;

    this.models$.next(this._model);
  }

  is(prop: keyof IPlatform): boolean {
    return this._model[prop];
  }

  iPhoneModels() {
    if (this.deviceService.isMobile() && this.isIPhone) {
      const keys = Object.keys(devices);
      return keys
        .filter(
          (key) =>
            (devices[key].logicalWidth === window.screen.width &&
              devices[key].logicalHeight === window.screen.height) ||
            (devices[key].logicalWidth === window.screen.height && devices[key].logicalHeight === window.screen.width),
        )
        .filter((key) => devices[key].osVersion === this.osVersion)
        .filter((key) => devices[key].devicePixelRatio === this.devicePixelRatio);
    }
    return [];
  }

  /** Получение информации о текущей платформе */
  get info(): any {
    return {
      device: {
        type: null,
        platform: null,
      },
      description: platform.description,
      layout: platform.layout,
      manufacturer: platform.manufacturer,
      name: platform.name,
      prerelease: platform.prerelease,
      product: platform.product,
      ua: platform.ua,
      version: platform.version,
      os: platform.os,
    };
  }

  get isIPhone(): boolean {
    return this.is('iPhone');
  }

  get isIPhoneXAndYoung(): boolean {
    if (this.deviceService.isMobile() && this.isIPhone) {
      return !!this.iPhoneModels().length;
    }
    return false;
  }

  get osVersion(): number {
    if (!this.isIPhone) {
      return null;
    }
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return parseInt(v[1], 10);
  }

  get devicePixelRatio(): number {
    return window.devicePixelRatio;
  }

  get isToolsExpanded(): boolean {
    const devicesList = this.iPhoneModels().map((key) =>
      window.orientation && window.orientation === 0
        ? devices[key].innerHeightPExpand === window.innerHeight
        : devices[key].innerHeightLExpand === window.innerHeight,
    );
    return devicesList.includes(true);
  }
}
