import { EIPhones, IIPhonesProperties } from './interfaces';

export const devices: { [key: string]: IIPhonesProperties } = {
  [EIPhones.XII]: {
    logicalWidth: 390,
    logicalHeight: 844,
    innerHeightP: 778,
    innerHeightL: 390,
    innerHeightPExpand: 664,
    innerHeightLExpand: 340,
    devicePixelRatio: 3,
    osVersion: 14,
  },
  [EIPhones.XI_Pro_Max_14]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 14,
  },
  [EIPhones.XI_Pro_Max]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 832,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.XI_Pro]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 749,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.XI]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 13,
  },
  [EIPhones.XI_14]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 829,
    innerHeightL: 414,
    innerHeightPExpand: 715,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 14,
  },
  [EIPhones.XR]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 833,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 2,
    osVersion: 12,
  },
  [EIPhones.XS_Max]: {
    logicalWidth: 414,
    logicalHeight: 896,
    innerHeightP: 832,
    innerHeightL: 414,
    innerHeightPExpand: 719,
    innerHeightLExpand: 364,
    devicePixelRatio: 3,
    osVersion: 12,
  },
  [EIPhones.XS]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 748,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 12,
  },
  [EIPhones.XS_13]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 749,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 13,
  },
  [EIPhones.X]: {
    logicalWidth: 375,
    logicalHeight: 812,
    innerHeightP: 748,
    innerHeightL: 375,
    innerHeightPExpand: 635,
    innerHeightLExpand: 325,
    devicePixelRatio: 3,
    osVersion: 11,
  },
};
