import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import stringify from 'fast-json-stable-stringify';
import {
  Game,
  GameModeEnum,
  GameScenarioEnum,
  getContentInstanceByItem,
  IFindLessonsFilter,
  IPagedResults,
  IPagination,
  Lesson,
  ModerationStatusEnum,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createPagedResults } from '../helpers/create-paged-results';

export type LessonDetailsType = keyof Lesson | 'cover:sm' | 'cover:md' | 'author:xs' | 'author:sm';

export type ContentStatusType = 'published' | 'draft' | 'declined' | 'archived';

@Injectable({
  providedIn: 'root',
})
export class LessonsService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  public static getLessonStatus(lesson: Lesson): ContentStatusType {
    if (lesson?.archived_at) {
      // этот вариант недопустим на самом деле
      // ;( throw new Error('Archived content in <app-content-selector> not allowed');
      return 'archived';
    }

    if (lesson.valid === false || !lesson.published_at) {
      return 'draft';
    }

    if (
      lesson.moderation_status === ModerationStatusEnum.softDeclined ||
      lesson.moderation_status === ModerationStatusEnum.hardDeclined
    ) {
      return 'declined';
    }

    return 'published';
  }

  public getLessons(
    filter: Partial<IFindLessonsFilter>,
    pagination: Partial<IPagination>,
    details: LessonDetailsType[],
  ): Observable<IPagedResults<Lesson[]>> {
    const url = `${environment.content_url}/lessons`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter))
      .set('details', stringify(details));

    return this.http.get(url, { params, observe: 'response' }).pipe(map(this.handleLessonsResponse));
  }

  /**
   * Подготовить игру по ID урока
   */
  public prepareGameByLessonId(lessonId: string, mode: GameModeEnum, scenario?: GameScenarioEnum): Observable<Game> {
    const url = `${environment.content_url}/game/prepare/${lessonId}`;
    return this.http.post<Game>(url, { mode, scenario }, { observe: 'body' }).pipe(
      map((response) => new Game(response)),
      // catchError(this.handleError),
    );
  }

  private handleLessonsResponse(response: HttpResponse<Lesson[]>): IPagedResults<Lesson[]> {
    return createPagedResults(response, (values) => getContentInstanceByItem(values) as any);
  }
}
