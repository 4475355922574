import { Injector } from '@angular/core';
import { ClientBoard } from '@app/models/board';
import { ClientMeeting } from '@app/models/meeting';
import { ClientParticipant } from '@app/models/participant';
import { WsParticipantsSyncService } from '@app/services/ws-participants-sync.service';
import {
  AccessOptionEnum,
  AccessOverrideOptionEnum,
  AcknowledgmentEvent,
  BoardContentTypeEnum,
  BoardsetEnum,
  ParticipantAccessOptions,
  ParticipantOptions,
  ParticipantRoleEnum,
} from 'lingo2-conference-models';
import { MeetingTypeEnum } from 'lingo2-models';
import { Observable, of } from 'rxjs';

export interface IAccessContext {
  meeting?: ClientMeeting;
  participant: ClientParticipant;
  board?: ClientBoard;
}

/** Возвращает true если пользователь может управлять досками */
export function canManageBoards(context: IAccessContext): boolean {
  const { meeting, participant } = context;

  // 'другой' митинг из истории
  if (!!meeting?.meeting_id_override || !!participant?.options?.meeting_id_override) {
    return false;
  }

  return participant?.access?.can_manage_boards === AccessOptionEnum.allowed;
}

export function canEditMeeting(context: IAccessContext): boolean {
  const { participant } = context;

  // 'другой' митинг из истории
  /* if (!!meeting?.meeting_id_override || !!participant?.options?.meeting_id_override) {
    return false;
  }*/

  return participant?.access?.can_edit_meeting === AccessOptionEnum.allowed;
}

/** Можно ли рисовать на интерактивной доске */
export function canDraw(context: IAccessContext): boolean {
  const { meeting, participant } = context;

  // 'другой' митинг из истории
  if (!!meeting?.meeting_id_override || !!participant?.options?.meeting_id_override) {
    return false;
  }

  const access = participant?.access?.can_draw;
  const accessOverride = participant?.access?.can_draw_override;
  const accessGlobal = meeting?.access?.can_draw;

  // переопределённый режим доступа - для преподавателя, ассистентов, модератора, ...
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      return true;

    case AccessOverrideOptionEnum.denied:
      return false;

    default:
      return accessGlobal === AccessOptionEnum.allowed && access === AccessOptionEnum.allowed;
  }
}

/** Можно ли использовать доску */
export function canUseBoard(context: IAccessContext): boolean {
  const { meeting, participant, board } = context;

  // 'другой' митинг из истории - нельзя менять
  if (!!meeting?.meeting_id_override || !!participant?.options?.meeting_id_override) {
    return false;
  }

  const accessOverride = participant?.access?.can_draw_override;

  switch (board?.boardset) {
    case BoardsetEnum.group: {
      // переопределённый режим доступа - для преподавателя, ассистентов, модератора, ...
      switch (accessOverride) {
        case AccessOverrideOptionEnum.allowed:
          return true;

        case AccessOverrideOptionEnum.denied:
          return false;

        default: {
          return (
            !!board &&
            // ??? && accessGlobal === AccessOptionEnum.allowed
            participant?.access?.can_do_group === AccessOptionEnum.allowed
          );
        }
      }
    }

    case BoardsetEnum.individual:
      return (
        !!board &&
        participant?.access?.can_do_individual === AccessOptionEnum.allowed &&
        board.result_user_id === participant?.user_id
      );

    case BoardsetEnum.homeworks:
      return (
        !!board &&
        participant?.access?.can_do_homework === AccessOptionEnum.allowed &&
        board.result_user_id === participant?.user_id
      );

    default:
      return false;
  }
}

/** Можно ли смотреть результаты на доске с интерактивным занятием */
export function canViewGameBoard(context: IAccessContext): boolean {
  const { board } = context;
  return board?.content_type === BoardContentTypeEnum.game;
}

/** Можно ли использовать доску с интерактивным занятием */
export function canUseGameBoard(context: IAccessContext): boolean {
  const { board } = context;

  if (!canUseBoard(context)) {
    return false;
  }

  return board?.content_type === BoardContentTypeEnum.game;
}

/** Можно ли использовать чат */
export function canUseChat(context: IAccessContext): boolean {
  const { meeting, participant } = context;
  const access = participant?.access?.can_chat;
  const accessOverride = participant?.access?.can_chat_override;
  const accessGlobal = meeting?.access?.can_chat;

  // переопределённый режим доступа - для преподавателя, ассистентов, модератора, ...
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      return true;

    case AccessOverrideOptionEnum.denied:
      return false;

    default:
      return accessGlobal === AccessOptionEnum.allowed && access === AccessOptionEnum.allowed;
  }
}

/** Можно ли использовать функцию "поднять руку" */
export function canUseHand(context: IAccessContext): boolean {
  const { meeting, participant } = context;

  const access = participant?.access?.can_hand;
  const accessOverride = participant?.access?.can_hand_override;
  const accessGlobal = meeting?.access?.can_hand;

  // переопределённый режим доступа - для преподавателя, ассистентов, модератора, ...
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      return true;

    case AccessOverrideOptionEnum.denied:
      return false;

    default:
      return accessGlobal === AccessOptionEnum.allowed && access === AccessOptionEnum.allowed;
  }
}

/** Можно ли заменить роль пользователям */
export function canOverrideRole(context: IAccessContext): boolean {
  const { participant } = context;

  return participant?.access?.can_override_role === AccessOptionEnum.allowed;
}

export type AccessOptionType = 'can_draw' | 'can_chat' | 'can_use_webcam' | 'can_use_microphone' | 'can_share_screen';

export type ParticipantAccessStatusType =
  | 'allowed'
  | 'allowed-override'
  | 'denied'
  | 'denied-override'
  | 'unset-override'
  | 'unknown';

export type ParticipantAccessLevelType = 'option' | 'override';

/** Состояние участника митинга для контрола 'Могу рисовать' и 'Могу писать в чат' */
export interface IParticipantAccessState {
  option: AccessOptionType;

  /** статус */
  status: ParticipantAccessStatusType;

  /** уровень доступа  */
  accessLevel: ParticipantAccessLevelType;

  /** является ли статус переопределённым */
  overridden: boolean;

  /** глобальный доступ */
  globalAccess: AccessOptionEnum;

  /** Доступ */
  access: AccessOptionEnum;

  /** Переопределённый доступ */
  accessOverride: AccessOverrideOptionEnum;
}

/** Состояние участника митинга для контрола 'Вебкамера' и 'Микрофон' */
export interface IParticipantMediaAccessState extends IParticipantAccessState {
  /** устройство доступно (транслирует данные от пользователя) */
  media_available: boolean;

  /**
   * устройство включено
   *   кнопка 'вкл/выкл' на аватаре пользователя - управление
   *   потоком без физического отключения устройства
   */
  media_enabled: boolean;
}

/*
Справочник, чтобы был перед глазами
AccessOptionEnum { unset = 0, denied = 1, allowed = 2 }
AccessOverrideOptionEnum { unset = 10, denied = 11, allowed = 12 }
*/

/** описание текущего состояния и возможностей изменения состояния участника для использования интерактивных досок */
export function drawAccess(
  me: ClientParticipant,
  participant: ClientParticipant,
  meeting: ClientMeeting,
): IParticipantAccessState {
  const canOverrideAccess = me?.access?.can_override_access === AccessOptionEnum.allowed;
  const access = participant?.access?.can_draw;
  const accessOverride = participant?.access?.can_draw_override;
  const globalAccess = meeting?.access?.can_draw;
  const isMe = me?.user_id === participant?.user_id;

  const overridden = [AccessOverrideOptionEnum.allowed, AccessOverrideOptionEnum.denied].includes(accessOverride);
  const changeable = canOverrideAccess && !isMe; // не может менять себя

  let status: ParticipantAccessStatusType = null;
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      status = changeable ? 'allowed-override' : 'allowed';
      break;

    case AccessOverrideOptionEnum.denied:
      status = changeable ? 'denied-override' : null;
      break;

    default:
      switch (globalAccess) {
        case AccessOptionEnum.allowed:
          status = access === AccessOptionEnum.allowed ? 'allowed' : null;
          // status = changeable ? 'allowed' : 'allowed';
          break;

        case AccessOptionEnum.denied:
          status = changeable ? 'unset-override' : null;
          break;
      }
      break;
  }

  return {
    option: 'can_draw',
    status,
    accessLevel: changeable ? 'override' : null,
    overridden,

    globalAccess,
    access,
    accessOverride,
  };
}

/** описание текущего состояния и возможностей изменения состояния участника для использования чата */
export function chatAccess(
  me: ClientParticipant,
  participant: ClientParticipant,
  meeting: ClientMeeting,
): IParticipantAccessState {
  const canOverrideAccess = me?.access?.can_override_access === AccessOptionEnum.allowed;
  const access = participant?.access?.can_chat;
  const accessOverride = participant?.access?.can_chat_override;
  const globalAccess = meeting?.access?.can_chat;
  const isMe = me?.user_id === participant?.user_id;

  const overridden = [AccessOverrideOptionEnum.allowed, AccessOverrideOptionEnum.denied].includes(accessOverride);
  const changeable = canOverrideAccess && !isMe; // не может менять себя

  let status: ParticipantAccessStatusType = null;
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      status = changeable ? 'allowed-override' : 'allowed';
      break;

    case AccessOverrideOptionEnum.denied:
      status = changeable ? 'denied-override' : null;
      break;

    default:
      switch (globalAccess) {
        case AccessOptionEnum.allowed:
          status = access === AccessOptionEnum.allowed ? 'allowed' : null;
          // status = changeable ? 'allowed' : 'allowed';
          break;

        case AccessOptionEnum.denied:
          status = changeable ? 'unset-override' : null;
          break;
      }
      break;
  }

  return {
    option: 'can_chat',
    status,
    accessLevel: changeable ? 'override' : null,
    overridden,

    globalAccess,
    access,
    accessOverride,
  };
}

/** описание текущего состояния и возможностей изменения состояния участника для использования микрофона */
export function microphoneAccess(
  me: ClientParticipant,
  participant: ClientParticipant,
  meeting: ClientMeeting,
): IParticipantMediaAccessState {
  const canOverrideAccess = me?.access?.can_override_access === AccessOptionEnum.allowed;
  const isMe = me?.user_id === participant?.user_id;

  const access = participant?.access?.can_use_microphone;
  const accessOverride = participant?.access?.can_use_microphone_override;
  const globalAccess = meeting?.access?.can_use_microphone;

  const overridden = [AccessOverrideOptionEnum.allowed, AccessOverrideOptionEnum.denied].includes(accessOverride);
  const changeable = canOverrideAccess || isMe;
  let status: ParticipantAccessStatusType = null;
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      status = changeable ? (isMe ? 'allowed' : 'allowed-override') : 'allowed-override';
      break;

    case AccessOverrideOptionEnum.denied:
      status = changeable ? (isMe ? null : 'denied-override') : 'denied-override';
      break;

    default:
      switch (globalAccess) {
        case AccessOptionEnum.allowed:
          status = 'allowed';
          break;

        case AccessOptionEnum.denied:
          status = changeable ? 'unset-override' : null;
          break;
      }
      break;
  }

  return {
    option: 'can_use_microphone',
    status,
    accessLevel: changeable ? (isMe ? 'option' : 'override') : null,
    overridden,

    media_available: !!participant?.options?.microphone_available,
    media_enabled: !!participant?.options?.microphone_enabled,

    globalAccess,
    access,
    accessOverride,
  };
}

/** описание текущего состояния и возможностей изменения состояния участника для использования веб-камеры */
export function webcamAccess(
  me: ClientParticipant,
  participant: ClientParticipant,
  meeting: ClientMeeting,
): IParticipantMediaAccessState {
  const canOverrideAccess = me?.access?.can_override_access === AccessOptionEnum.allowed;
  const access = participant?.access?.can_use_webcam;
  const accessOverride = participant?.access?.can_use_webcam_override;
  const globalAccess = meeting?.access?.can_use_webcam;
  const isMe = me?.user_id === participant?.user_id;

  const overridden = [AccessOverrideOptionEnum.allowed, AccessOverrideOptionEnum.denied].includes(accessOverride);
  const changeable = canOverrideAccess || isMe;

  let status: ParticipantAccessStatusType = null;
  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      status = changeable ? (isMe ? 'allowed' : 'allowed-override') : 'allowed-override';
      break;

    case AccessOverrideOptionEnum.denied:
      status = changeable ? (isMe ? null : 'denied-override') : 'denied-override';
      break;

    default:
      switch (globalAccess) {
        case AccessOptionEnum.allowed:
          status = 'allowed';
          break;

        case AccessOptionEnum.denied:
          status = changeable ? 'unset-override' : null;
          break;
      }
      break;
  }

  return {
    option: 'can_use_webcam',
    status,
    accessLevel: changeable ? (isMe ? 'option' : 'override') : null,
    overridden,

    media_available: !!participant?.options?.webcam_available,
    media_enabled: !!participant?.options?.webcam_enabled,

    globalAccess,
    access,
    accessOverride,
  };
}

/** Изменение текущего состояния контролов "чат/рисование/микрофон/камера..." */
export function toggleAccess$(
  participant: ClientParticipant,
  access: IParticipantAccessState,
  inject: Injector,
): Observable<AcknowledgmentEvent> {
  if (!access.accessLevel) {
    return of(null);
  }

  switch (access.accessLevel) {
    case 'override':
      return toggleOverrideAccess$(participant, access, inject);

    case 'option':
      return toggleOption$(participant, access, inject);
  }
}

/** Разрешёно ли использование вебкмеры указанному участнику */
export function isWebcamAllowed(participant: ClientParticipant, meeting: ClientMeeting): boolean {
  const isDraft = meeting.type === MeetingTypeEnum.not_defined && participant.role === ParticipantRoleEnum.owner;

  const accessOverride = participant?.access?.can_use_webcam_override;
  const personalAllowed = participant?.access?.can_use_webcam === AccessOptionEnum.allowed;
  const globalAllowed = isDraft ? true : meeting?.access?.can_use_webcam === AccessOptionEnum.allowed;

  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      return true;

    case AccessOverrideOptionEnum.denied:
      return false;

    default:
      return globalAllowed && personalAllowed;
  }
}

/** Разрешёно ли использование микрофона указанному участнику */
export function isMicrophoneAllowed(participant: ClientParticipant, meeting: ClientMeeting): boolean {
  const isDraft = meeting.type === MeetingTypeEnum.not_defined && participant.role === ParticipantRoleEnum.owner;

  const accessOverride = participant?.access?.can_use_microphone_override;
  const personalAllowed = participant?.access?.can_use_microphone === AccessOptionEnum.allowed;
  const globalAllowed = isDraft ? true : meeting?.access?.can_use_microphone === AccessOptionEnum.allowed;

  switch (accessOverride) {
    case AccessOverrideOptionEnum.allowed:
      return true;

    case AccessOverrideOptionEnum.denied:
      return false;

    default:
      return globalAllowed && personalAllowed;
  }
}

// ----------

function toggleOverrideAccess$(
  participant: ClientParticipant,
  access: IParticipantAccessState,
  inject: Injector,
): Observable<AcknowledgmentEvent> {
  const map: { [key: string]: keyof ParticipantAccessOptions } = {
    can_draw: 'can_draw_override',
    can_chat: 'can_chat_override',
    can_use_webcam: 'can_use_webcam_override',
    can_use_microphone: 'can_use_microphone_override',
  };
  const accessOption = map[access.option];
  if (!accessOption) {
    return;
  }

  const newValue = getAccessOverrideValue(access.option, access);
  if (newValue) {
    const participantsSync = inject.get(WsParticipantsSyncService);
    return participantsSync.changeAccess(participant.user_id, { [accessOption]: newValue });
  }
  return of(null);
}

export function getAccessOverrideValue(
  option: AccessOptionType,
  access: IParticipantAccessState,
): AccessOverrideOptionEnum {
  switch (access.globalAccess) {
    case AccessOptionEnum.allowed:
      return access.accessOverride === AccessOverrideOptionEnum.denied
        ? AccessOverrideOptionEnum.unset
        : AccessOverrideOptionEnum.denied;

    case AccessOptionEnum.denied:
      return access.accessOverride === AccessOverrideOptionEnum.allowed
        ? AccessOverrideOptionEnum.unset
        : AccessOverrideOptionEnum.allowed;
  }

  return null;
}

function toggleOption$(
  participant: ClientParticipant,
  access: IParticipantAccessState,
  inject: Injector,
): Observable<AcknowledgmentEvent> {
  const map: { [key: string]: keyof ParticipantOptions } = {
    can_use_webcam: 'webcam_enabled',
    can_use_microphone: 'microphone_enabled',
  };
  const option: keyof ParticipantOptions = map[access.option];
  if (!option) {
    return of(null);
  }

  const options = participant.options || {};
  const newValue = option in options ? !options[option] : true;

  const participantsSync = inject.get(WsParticipantsSyncService);
  return participantsSync.changeOptions(participant.user_id, { [option]: newValue });
}
