import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { WebViewAlertComponent } from './web-view-alert.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [WebViewAlertComponent],
  exports: [WebViewAlertComponent],
})
export class WebViewAlertModule {}
