import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IResponse } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IVoiceCode {
  code: number;
  codeMale: string;
  codeFemale: string;
}

export const voiceCodes: IVoiceCode[] = [
  {
    code: 1,
    codeMale: 'ru-RU-DmitryNeural',
    codeFemale: 'ru-RU-DariyaNeural',
  },
  {
    code: 2,
    codeMale: 'en-GB-RyanNeural',
    codeFemale: 'en-GB-BellaNeural',
  },
  {
    code: 3,
    codeMale: 'fr-FR-HenriNeural',
    codeFemale: 'fr-FR-BrigitteNeural',
  },
  {
    code: 4,
    codeMale: 'de-DE-ConradNeural',
    codeFemale: 'de-DE-TanjaNeural',
  },
  {
    code: 5,
    codeMale: 'es-ES-Standard-B',
    codeFemale: 'Lucia',
  },
  {
    code: 6,
    codeMale: 'pt-PT-DuarteNeural',
    codeFemale: 'pt-PT-FernandaNeural',
  },
  {
    code: 7,
    codeMale: 'it-IT-CataldoNeural',
    codeFemale: 'it-IT-IrmaNeural',
  },
  {
    code: 8,
    codeMale: 'zh-CN-YunjianNeural',
    codeFemale: 'zh-CN-liaoning-XiaobeiNeural',
  },
  {
    code: 9,
    codeMale: 'Takumi',
    codeFemale: 'ja-JP_EmiV3Voice',
  },
  {
    code: 11,
    codeMale: 'tr-TR-AhmetNeural',
    codeFemale: 'tr-TR-EmelNeural',
  },
  {
    code: 12,
    codeMale: 'ar-LY-OmarNeural',
    codeFemale: 'ar-EG-SalmaNeural',
  },
  {
    code: 13,
    codeMale: 'ko-KR-InJoonNeural',
    codeFemale: 'Seoyeon',
  },
  {
    code: 14,
    codeMale: 'vi-VN-NamMinhNeural',
    codeFemale: 'vi-VN-Wavenet-C',
  },
  {
    code: 15,
    codeMale: 'sk-SK-LukasNeural',
    codeFemale: 'sk-SK-ViktoriaNeural',
  },
  {
    code: 16,
    codeMale: 'cs-CZ-AntoninNeural',
    codeFemale: 'cs-CZ-VlastaNeural',
  },
  {
    code: 17,
    codeMale: 'pl-PL-MarekNeural',
    codeFemale: 'pl-PL-AgnieszkaNeural',
  },
  {
    code: 18,
    codeMale: 'ro-RO-EmilNeural',
    codeFemale: 'ro-RO-AlinaNeural',
  },
  {
    code: 19,
    codeMale: 'hu-HU-TamasNeural',
    codeFemale: 'hu-HU-NoemiNeural',
  },
  {
    code: 20,
    codeMale: 'nb-NO-FinnNeural',
    codeFemale: 'nb-NO-PernilleNeural',
  },
  {
    code: 21,
    codeMale: 'da-DK-JeppeNeural',
    codeFemale: 'da-DK-ChristelNeural',
  },
  {
    code: 22,
    codeMale: 'nl-NL-MaartenNeural',
    codeFemale: 'nl-NL-FennaNeural',
  },
  {
    code: 23,
    codeMale: 'sv-SE-Wavenet-D',
    codeFemale: 'sv-SE-Wavenet-E',
  },
  {
    code: 24,
    codeMale: 'fi-FI-HarriNeural',
    codeFemale: 'fi-FI-NooraNeural',
  },
  {
    code: 25,
    codeMale: 'el-GR-NestorasNeural',
    codeFemale: 'el-GR-AthinaNeural',
  },
  {
    code: 26,
    codeMale: 'he-IL-AvriNeural',
    codeFemale: 'he-IL-HilaNeural',
  },
  {
    code: 27,
    codeMale: 'hi-IN-MadhurNeural',
    codeFemale: 'hi-IN-SwaraNeural',
  },
  {
    code: 28,
    codeMale: 'id-ID-ArdiNeural',
    codeFemale: 'id-ID-GadisNeural',
  },
  {
    code: 29,
    codeMale: 'th-TH-NiwatNeural',
    codeFemale: 'th-TH-AcharaNeural',
  },
  {
    code: 30,
    codeMale: 'es-MX-LucianoNeural',
    codeFemale: 'es-MX-CarlotaNeural',
  },
  {
    code: 31,
    codeMale: 'ar-EG-ShakirNeural',
    codeFemale: 'ar-EG-SalmaNeural',
  },
  {
    code: 32,
    codeMale: 'pt-BR-JulioNeural',
    codeFemale: 'pt-BR-BrendaNeural',
  },
  {
    code: 33,
    codeMale: 'zh-CN-YunyeNeural',
    codeFemale: 'zh-CN-XiaoxiaoNeural',
  },
  {
    code: 47,
    codeMale: 'zh-CN-YunyeNeural',
    codeFemale: 'zh-CN-XiaoxiaoNeural',
  },
  {
    code: 48,
    codeMale: 'bg-BG-BorislavNeural',
    codeFemale: 'bg-BG-KalinaNeural',
  },
  {
    code: 49,
    codeMale: 'ca-ES-EnricNeural',
    codeFemale: 'ca-ES-JoanaNeural',
  },
  {
    code: 50,
    codeMale: 'ms-MY-OsmanNeural',
    codeFemale: 'ms-MY-YasminNeural',
  },
  {
    code: 51,
    codeMale: 'sr-RS-NicholasNeural',
    codeFemale: 'sr-RS-SophieNeural',
  },
  {
    code: 52,
    codeMale: 'uk-UA-OstapNeural',
    codeFemale: 'uk-UA-PolinaNeural',
  },
  {
    code: 54,
    codeMale: 'fil-PH-AngeloNeural',
    codeFemale: 'fil-PH-BlessicaNeural',
  },
  {
    code: 56,
    codeMale: 'af-ZA-WillemNeural',
    codeFemale: 'af-ZA-AdriNeural',
  },
  {
    code: 57,
    codeMale: 'sq-AL-IlirNeural',
    codeFemale: 'sq-AL-AnilaNeural',
  },
  {
    code: 58,
    codeMale: 'am-ET-AmehaNeural',
    codeFemale: 'am-ET-MekdesNeural',
  },
  {
    code: 60,
    codeMale: 'az-AZ-BanuNeural',
    codeFemale: 'az-AZ-BabekNeural',
  },
  {
    code: 62,
    codeMale: 'bn-BD-PradeepNeural',
    codeFemale: 'bn-BD-NabanitaNeural',
  },
  {
    code: 63,
    codeMale: 'bs-BA-GoranNeural',
    codeFemale: 'bs-BA-VesnaNeural',
  },
  {
    code: 64,
    codeMale: 'my-MM-ThihaNeural',
    codeFemale: 'my-MM-NilarNeural',
  },
  {
    code: 65,
    codeMale: 'hr-HR-SreckoNeural',
    codeFemale: 'hr-HR-GabrijelaNeural',
  },
  {
    code: 66,
    codeMale: 'fa-IR-FaridNeural',
    codeFemale: 'fa-IR-DilaraNeural',
  },
  {
    code: 67,
    codeMale: 'et-EE-KertNeural',
    codeFemale: 'et-EE-AnuNeural',
  },
  {
    code: 68,
    codeMale: 'ka-GE-GiorgiNeural',
    codeFemale: 'ka-GE-EkaNeural',
  },
  {
    code: 69,
    codeMale: 'kk-KZ-DauletNeural',
    codeFemale: 'kk-KZ-AigulNeural',
  },
  {
    code: 71,
    codeMale: 'lo-LA-ChanthavongNeural',
    codeFemale: 'lo-LA-KeomanyNeural',
  },
  {
    code: 72,
    codeMale: 'lv-LV-NilsNeural',
    codeFemale: 'lv-LV-EveritaNeural',
  },
  {
    code: 73,
    codeMale: 'lt-LT-LeonasNeural',
    codeFemale: 'lt-LT-OnaNeural',
  },
  {
    code: 74,
    codeMale: 'mk-MK-AleksandarNeural',
    codeFemale: 'mk-MK-MarijaNeural',
  },
  {
    code: 76,
    codeMale: 'mn-MN-BataaNeural',
    codeFemale: 'mn-MN-YesuiNeural',
  },
  {
    code: 77,
    codeMale: 'ne-NP-SagarNeural',
    codeFemale: 'ne-NP-HemkalaNeural',
  },
  {
    code: 78,
    codeMale: 'ps-AF-GulNawazNeural',
    codeFemale: 'ps-AF-LatifaNeural',
  },
  {
    code: 79,
    codeMale: 'fa-IR-FaridNeural',
    codeFemale: 'fa-IR-DilaraNeural',
  },
  {
    code: 80,
    codeMale: 'si-LK-SameeraNeural',
    codeFemale: 'si-LK-ThiliniNeural',
  },
  {
    code: 81,
    codeMale: 'sl-SI-RokNeural',
    codeFemale: 'sl-SI-PetraNeural',
  },
  {
    code: 82,
    codeMale: 'so-SO-MuuseNeural',
    codeFemale: 'so-SO-UbaxNeural',
  },
  {
    code: 83,
    codeMale: 'sw-KE-RafikiNeural',
    codeFemale: 'sw-KE-ZuriNeural',
  },
  {
    code: 84,
    codeMale: 'ta-IN-ValluvarNeural',
    codeFemale: 'ta-IN-PallaviNeural',
  },
  {
    code: 86,
    codeMale: 'ur-PK-AsadNeural',
    codeFemale: 'ur-PK-UzmaNeural',
  },
];

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  public constructor(private http: HttpClient) {}

  public translateWord(text: string, sourceLang: string, targetLang: string): Observable<string> {
    const url = `${environment.content_url}/proxy/openai`;
    const prompt = `Translate this word from ${sourceLang} to ${targetLang}: ${text}`;

    const data = {
      prompt,
      max_tokens: 1024,
      n: 1,
      stop: null,
      temperature: 0.7,
    };
    return this.http.post<{ text: string }>(url, data).pipe(map((response) => response.text));
  }

  public generateSpeech(text: string, lang_id: number) {
    const url = `${environment.content_url}/proxy/playht`;
    const voice = voiceCodes.find((el) => el.code == lang_id)
      ? voiceCodes.find((el) => el.code == lang_id)?.codeMale
      : 'en-GB-RyanNeural';
    const payload = { text, voice };
    return this.http.post(url, payload, { observe: 'body' }).pipe(map((response: IResponse<string>) => response.data));
  }
}
