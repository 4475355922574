import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import stringify from 'fast-json-stable-stringify';
import {
  LessonContentType,
  ContentTypeEnum,
  Lesson,
  Video as VideoContent,
  Audio as AudioContent,
  Image as ImageContent,
  Text as TextContent,
  Vocabulary,
  MutableContentBase,
  getContentInstanceByItem,
  ContentDetailsType,
} from 'lingo2-models';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  public static cloneContent(
    item: MutableContentBase,
  ): Lesson | AudioContent | VideoContent | ImageContent | TextContent | Vocabulary {
    if (LessonContentType.includes(item.type)) {
      return new Lesson({ ...(item as any) });
    }

    switch (item.type) {
      case ContentTypeEnum.article:
      case ContentTypeEnum.news:
        return new TextContent({ ...(item as any) });

      case ContentTypeEnum.video:
        return new VideoContent({ ...(item as any) });

      case ContentTypeEnum.audio:
        return new AudioContent({ ...(item as any) });

      case ContentTypeEnum.image:
        return new ImageContent({ ...(item as any) });

      // case ContentTypeEnum.document:
      //   return new DocumentContent({ ...item as any });

      case ContentTypeEnum.vocabulary:
        return new Vocabulary({ ...(item as any) });
    }
  }

  // public contentFullTitle(item: Content): string {
  //   if (!item) {
  //     return '';
  //   }
  //
  //   let subject = '';
  //   if ('subject' in item) {
  //     if (+item.subject.id === otherSubjectId) {
  //       subject = item.subject_other_name || '---';
  //     } else {
  //       subject = item.subject.title || '---';
  //     }
  //   }
  //   // let _level: string;
  //   // if ('level_other_name' in item && !!(item as any).level_other_name)  {
  //   //   _level = (item as any).level_other_name;
  //   // } else {
  //   //   _level = ('level' in item && (item as any).level)
  //   //     ? (item as any).level.title
  //   //     : this.translate.instant('subject-levels.any-level');
  //   // }
  //   const titleParts = [];
  //   if (subject) {
  //     titleParts.push(`${subject}, `);
  //   }
  //   titleParts.push(item.title);
  //   if (item.sub_title) {
  //     titleParts.push(`, ${item.sub_title}`);
  //   }
  //   if (_level) {
  //     titleParts.push(` (${_level})`);
  //   }
  //
  //   return titleParts.join('');
  // }

  /** Поиск записи по ID */
  public getById(id: string, details: ContentDetailsType[]) {
    const url = `${environment.content_url}/content/${id}`;
    const params = new HttpParams().set('details', stringify(details));

    return this.http.get(url, { params, observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  private handleContentResponse(response: HttpResponse<any>) {
    return getContentInstanceByItem(response.body);
  }

  private handleError(error: HttpErrorResponse) {
    this.logger.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
