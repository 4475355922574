export interface WebSocket2Config {
  /** pause between connections */
  reconnectInterval: number;

  /** number of connection attempts */
  reconnectAttempts: number;

  url: string;
}

export enum WebSocketStatusEnum {
  disconnected,
  connected,
  connecting,
  reconnecting,
}
