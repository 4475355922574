import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import stringify from 'fast-json-stable-stringify';
import {
  AccountBalanceInfo,
  AccountDetailsType,
  CurrencyEnum,
  IAccountCheck,
  IFindAccountsFilter,
  IHash,
  IImageFile,
  ImageSizeEnum,
  IPagedResults,
  IPagination,
  User,
  UserSegmentEnum,
  UserStatusEnum,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
    // this.setLogNamespace('onclass:service:AccountService');
  }

  public static accountRoute(account: { slug: string }, extra: string[] = []): string[] {
    return ['/u', account.slug, ...extra];
  }

  public static hasSegment(me: Partial<User>, segment: UserSegmentEnum): boolean {
    return (me?.segments || []).some((s) => s.segment === segment);
  }

  public static getUserName(user: { first_name: string; last_name: string; slug: string }): string {
    if (!user) {
      return '';
    }
    const first_names = user.first_name
      ?.split(/\s+/)
      .map((s) => s.trim())
      .filter((s) => s.length);
    const last_name = user.last_name?.trim();

    const user_name = [(first_names || '').length ? first_names[0] : '', last_name || '']
      .filter((s) => s.length)
      .join(' ');

    return user_name.length ? user_name : user.slug?.trim();
  }

  public static getUserFullName(user: { first_name: string; last_name: string; slug: string }): string {
    if (!user) {
      return '';
    }
    const first_name = user.first_name
      ?.split(/\s+/)
      .map((s) => s.trim())
      .filter((s) => s.length)
      .join(' ');
    const last_name = user.last_name?.trim();

    const full_name = [first_name || '', last_name || '']
      .filter((s) => s.length)
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim();
    return full_name.length ? full_name : user.slug?.trim();
  }

  public static getUserpic(user: { userpic?: IHash<IImageFile> }, size: ImageSizeEnum): IImageFile {
    return user?.userpic?.[size];
  }

  public static getUserpicUrl(user: { userpic?: IHash<IImageFile> }, size: ImageSizeEnum): string {
    return user?.userpic?.[size]?.url;
  }

  /** Является ли пользователь активным (не гостем, не забаненым, не удалённым - и прочее) */
  public static isGuest(user: { status: UserStatusEnum }): boolean {
    return user?.status === UserStatusEnum.guest;
  }

  /** Является ли пользователь активным (не гостем, не забаненым, не удалённым - и прочее) */
  public static isActive(user: { status: UserStatusEnum }): boolean {
    return [UserStatusEnum.not_confirmed, UserStatusEnum.confirmed].includes(user?.status);
  }

  public getUserById(user_id: string, details?: AccountDetailsType[]): Observable<User> {
    const url = `${environment.account_url}/account/${user_id}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http.get<User>(url, { params, observe: 'response' }).pipe(map(this.handleUserResponse));
  }

  public accountCheck(): Observable<IAccountCheck> {
    const url = `${environment.account_url}/check`;
    return this.http.get<IAccountCheck>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }
  /** Список моих учеников */
  public findMyStudents(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/my-students`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter));
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Список пользователей по специческим условиям поиска
   */
  public findAccounts(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/accounts`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter));
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /** Список подписчиков */
  public getSubscribers(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/subscribers`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter));
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /** Список моих подписок */
  public getSubscriptions(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/subscriptions`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter));
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  getUserBalancesForCurrency(currency_id: CurrencyEnum) {
    const url = `${environment.account_url}/billing/1/user_balances/${currency_id}`;
    return this.http.get<AccountBalanceInfo>(url, { observe: 'body' });
  }

  private handleUserResponse(response: HttpResponse<User>): User {
    return new User(response.body);
  }

  private handleItemsResponse(response: HttpResponse<User[]>): IPagedResults<User[]> {
    // const totalRecords = +res.headers.get('X-InlineCount');
    return {
      results: response.body.map((i) => new User(i)),
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }
}
