/** Состояния приложения */
export enum ApplicationStateEnum {
  /** Локализация */
  StateI18nErrUiLocales,
  StateI18nErrTransport,
  StateI18nErrMiss,

  /** Группа состояний - авторизация */
  StateAccess,
  StateAuth,
  StateMeetingSession,
  StateErrNoAuth,
  StateErrNoMeeting,
  StateErrMeetingCanceled,
  StateErrNotParticipant,
  StateErrDraftMeeting,
  StateErrUnexpected,

  /** Группа состояний - подключение к серверу конференции */
  StateConnect,
  StateTransportConnect,
  StateTransportConnected,
  StateTransportAuth,
  StateTransportReConnect,
  StateTransportErrUnexpected,

  /** Группа состояний - установка или восстановление контекста */
  StateRestoreContext,
  StateSetupContext,
  StateRestoreContextErrUnexpected,

  /** Группа состояний - основной режим работы */
  StateMain,

  // за кадром - работа с сервером медиа-конференции */
}
