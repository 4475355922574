// tslint:disable:no-console
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { UUIDv4 } from '@app/models/helpers';
import { ClientMeetingSession } from '@app/models/meeting';
import { AuthService } from '@app/services/lingo2-account/auth.service';
import {
  AbstractEvent,
  AbstractMessage,
  AuthEvent,
  AuthMessage,
  castEvent,
  ErrorEvent,
  EventEnum,
  isTypeOfAcknowledgmentEvent,
  isTypeOfEvent,
  MessageEnum,
} from 'lingo2-conference-models';
import { IPlanUpdate } from 'lingo2-models';
import { BehaviorSubject, interval, Observable, Observer, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, share, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { WebSocket2Config, WebSocketStatusEnum } from './models';

const skipLogForEvents = [
  // EventEnum.PongEvent,
  EventEnum.CursorMoveEvent,
  EventEnum.CursorClickEvent,
  EventEnum.DrawCommandEvent,
];
const skipLogForMessages = [
  // MessageEnum.PingMessage,
  MessageEnum.StatusMessage,
  MessageEnum.CursorMoveMessage,
  MessageEnum.CursorClickMessage,
  MessageEnum.DrawCommandMessage,
];

@Injectable({
  providedIn: 'root',
})
export class WebsocketService extends AbstractService implements OnDestroy {
  public status$: Observable<WebSocketStatusEnum>;
  public sendMetric$ = this.register(new Subject<MessageEnum>());
  public receiveMetric$: Observable<EventEnum>;
  public error$ = this.register(new Subject<ErrorEvent>());

  private authorized = this.register(new BehaviorSubject<boolean>(false));
  public authorized$ = this.authorized.asObservable();

  private config: WebSocketSubjectConfig<AbstractMessage>;
  private session: ClientMeetingSession;

  private connection$: Observer<WebSocketStatusEnum>;
  private reconnection$: Observable<number>;
  private sendingMessages$: WebSocketSubject<AbstractMessage>;
  private receiveMessages$ = this.register(new Subject<AbstractEvent>());

  private status$$: Subscription;
  private receiveMessages$$: Subscription;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  private isConnected: boolean;
  private disabled = false;

  // private watches: any = {};
  private pinger: any;

  public constructor(private authService: AuthService, protected inject?: Injector) {
    super(inject);

    this.receiveMetric$ = this.receiveMessages$.pipe(map((event) => event.event));

    // connection status
    this.status$ = new Observable<WebSocketStatusEnum>((observer) => {
      this.connection$ = observer;
    })
      .pipe(takeUntil(this.destroyed$))
      .pipe(share(), distinctUntilChanged());
  }

  public configure(socketUrl: string, session: ClientMeetingSession) {
    this.logger.debug('init');
    const wsConfig: WebSocket2Config = {
      reconnectInterval: 5000,
      reconnectAttempts: 1000,
      url: socketUrl,
    };
    this.session = session;

    this.reconnectInterval = wsConfig.reconnectInterval || 5000;
    this.reconnectAttempts = wsConfig.reconnectAttempts || 100;

    this.config = {
      url: wsConfig.url,
      closeObserver: {
        next: () => {
          if (this.pinger) {
            clearInterval(this.pinger);
            this.pinger = null;
          }

          this.logger.debug('disconnected');
          this.sendingMessages$ = null;
          this.connection$.next(WebSocketStatusEnum.disconnected);
        },
      },
      openObserver: {
        next: () => {
          this.logger.debug('connected');
          this.connection$.next(WebSocketStatusEnum.connected);

          // reset vars for reconnect
          this.reconnectInterval = wsConfig.reconnectInterval || 5000;
          this.reconnectAttempts = wsConfig.reconnectAttempts || 100;

          this.auth();

          // this.pinger = setInterval(() => {
          //   this.send(WsMessageEnum.ping, null);
          // }, 2500);
        },
      },
    };

    // this.authService.me$
    //   .pipe(
    //     pluck('id'),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       // this.init();
    //       // this.connect();
    //       if (this.isConnected) {
    //         this.auth();
    //       }
    //     }),
    //   )
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe();

    if (this.authService.accessToken) {
      this.try(() => {
        this.init();
        this.connect();
      });
    }

    this.on<ErrorEvent>(EventEnum.ErrorEvent)
      .pipe(
        tap((error) => this.error$.next(error)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public init() {
    if (!this.config) {
      throw new Error('not configured');
    }

    // run reconnect if not connection
    if (this.status$$) {
      this.status$$.unsubscribe();
    }
    this.status$$ = this.status$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
      this.isConnected = status === WebSocketStatusEnum.connected;
      if (!this.reconnection$ && typeof this.isConnected === 'boolean' && !this.isConnected) {
        this.reconnect();
      }
    });

    if (this.receiveMessages$$) {
      this.receiveMessages$$.unsubscribe();
    }
    this.receiveMessages$$ = this.receiveMessages$.pipe(takeUntil(this.destroyed$)).subscribe(
      (evt) => {
        if (!skipLogForEvents.includes(evt.event)) {
          this.logger.debug('received:' + evt.event, evt);
        }
      },
      (error: ErrorEvent) => {
        this.logger.error('received:error', error);
      },
    );

    this.onAuthComplete.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.authorized.next(true);
    });
  }

  public forceDisconnect() {
    this.disabled = true;
    // this.stopWatchingAll();
    this.connection$?.complete();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    // this.stopWatchingAll();
    this.connection$?.complete();
  }

  /** get event from server */
  public on<T extends AbstractEvent>(eventType: EventEnum): Observable<T> {
    return this.receiveMessages$
      .pipe(
        filter(() => !this.disabled),
        filter(isTypeOfEvent),
        filter((event: T) => event.event === eventType),
        map((event: T) => castEvent(event)),
      )
      .pipe(takeUntil(this.destroyed$));
  }

  /** get acknowledgment event from server */
  public onAsk<T extends AbstractEvent>(messageId: string): Observable<T> {
    if (!messageId) {
      return of(null);
    }

    return this.receiveMessages$
      .pipe(
        filter(() => !this.disabled),
        filter(isTypeOfAcknowledgmentEvent),
        filter((event: T) => event.askMessageId === messageId),
        map((event: T) => castEvent(event)),
      )
      .pipe(takeUntil(this.destroyed$));
  }

  /** Сообщение об авторизации на сокет-сервере */
  public get onAuthComplete(): Observable<AuthEvent> {
    return this.on<AuthEvent>(EventEnum.AuthEvent);
  }

  /** send message to server */
  public send<T extends AbstractMessage>(message: T): void {
    if (!message || this.disabled) {
      return;
    }
    this.try(() => {
      if (this.isConnected && this.sendingMessages$) {
        this.sendingMessages$.next(message);
        this.sendMetric$.next(message.message);
        if (!skipLogForMessages.includes(message.message)) {
          this.logger.debug('send:' + message.message, message);
        }
      } else {
        throw new Error('Socket not connected or disconnected');
        // this.logger.debug('send:error', { event, data }, 'Socket not connected or disconnected');
        // this.logger.debug('send retry', { event, data });
        // this.setTimeout(() => {
        //   this.send(event, data);
        // }, 250 + Math.round(1000 * Math.random()));
      }
    });
  }

  /** Изменение финансового профиля (подписки) */
  public get onPlanUpdate(): Observable<IPlanUpdate> {
    return of(null);
  }

  protected try(fn: () => void) {
    try {
      fn();
    } catch (err) {
      this.errorService.err(err);
      this.error$.next(
        ErrorEvent.createInstance({
          error: {
            message: err.message,
            stack: err.stack,
          },
        }),
      );
    }
  }

  /** Авторизация на сокет-сервере */
  private auth() {
    this.logger.debug('auth');
    this.authorized.next(false);
    this.send(
      AuthMessage.createInstance({
        auth_token: this.authService.accessToken,
        session_token: this.session.session_token,
        messageId: UUIDv4(),
        withAsk: true,
      }),
    );
    /** @see onAuthComplete */

    // Object.values(this.watches)
    //   .map((data) => {
    //     const { entity, id } = data as any;
    //     this.startWatching(entity, id);
    //   });
  }

  /** connect to WebSocket */
  private connect(): void {
    if (this.isConnected) {
      return;
    }

    this.logger.debug('connect');

    if (this.sendingMessages$) {
      this.sendingMessages$.unsubscribe();
      this.sendingMessages$.complete();
    }

    this.sendingMessages$ = new WebSocketSubject(this.config);

    const sendMessages$$ = this.sendingMessages$.pipe(takeUntil(this.destroyed$)).subscribe(
      (message) => this.receiveMessages$.next(message as any),
      () => {
        sendMessages$$?.unsubscribe();

        if (!this.sendingMessages$) {
          // run reconnect if errors
          this.reconnect();
        }
      },
      () => {
        sendMessages$$?.unsubscribe();
      },
    );
  }

  /** reconnect if not connecting or errors */
  private reconnect(): void {
    if (this.disabled) {
      return;
    }
    this.connection$.next(WebSocketStatusEnum.reconnecting);
    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile((v, index) => index < this.reconnectAttempts && !this.sendingMessages$),
    );

    const reconnection$$ = this.reconnection$.pipe(takeUntil(this.destroyed$)).subscribe(
      () => {
        this.connect();
      },
      (err) => {
        this.logger.error('reconnect()', 'error', err);
      },
      () => {
        reconnection$$.unsubscribe();
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (this.sendingMessages$) {
          this.logger.debug('reconnect()', 'success');
        } else {
          this.logger.error('reconnect()', 'failed');
          this.receiveMessages$.complete();
          this.connection$.complete();
          if (this.sendingMessages$) {
            this.sendingMessages$.unsubscribe();
            this.sendingMessages$.complete();
            this.sendingMessages$ = null;
          }
        }
      },
    );
  }
}
