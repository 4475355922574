import { HttpResponse } from '@angular/common/http';
import { IPagedResults } from 'lingo2-models';

export function createPagedResults<T>(response: HttpResponse<T>, mapper?: any): IPagedResults<T> {
  let results = response.body as any;
  if (mapper) {
    results = results.map(mapper);
  }
  return {
    results,
    pagination: {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    },
    page: +response.headers.get('X-Pagination-Page'),
    pageSize: +response.headers.get('X-Pagination-PageSize'),
    total: +response.headers.get('X-Pagination-Total'),
    totalPages: +response.headers.get('X-Pagination-TotalPages'),
  };
}
