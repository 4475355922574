import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import stringify from 'fast-json-stable-stringify';
import { AbstractMeetingsService } from 'lingo2-forms';
import {
  IFindMeetingsFilter,
  IMeetingSession,
  IPagedResults,
  IPagination,
  Meeting,
  MeetingDetailsType,
  MeetingParticipant,
  MeetingTypeEnum,
  otherSubjectId,
  MeetingExtendRequest,
  MeetingExtendResponse,
} from 'lingo2-models';
import { DateFnsConfigurationService, FormatPipe } from 'lingo2-ngx-date-fns';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { createPagedResults } from '../helpers/create-paged-results';
import { AccountService } from '../lingo2-account/account.service';

export const meetingDetails: MeetingDetailsType[] = [
  'id',
  'title',
  'category',
  'category_id',
  'description',
  'cover_id',
  'subject',
  'type',
  'access',
  'author',
  'begin',
  'begin_at',
  'end_at',
  'started_at',
  'finished_at',
  'classroom_id',
  'school_id',
  'has',
  'is',
  'can',
  'params',
];

@Injectable({
  providedIn: 'root',
})
export class MeetingsService extends AbstractService implements AbstractMeetingsService {
  private dateFormat = new FormatPipe(this.dateConfig, null);

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private dateConfig: DateFnsConfigurationService,
  ) {
    super();
    // this.setLogNamespace('onclass:service:Meetings');
  }

  public static meetingTypeName(meeting: Meeting): string {
    return (
      {
        [MeetingTypeEnum.single]: 'meeting-type.single',
        [MeetingTypeEnum.group]: 'meeting-type.group',
        [MeetingTypeEnum.webinar]: 'meeting-type.webinar',
        [MeetingTypeEnum.stream]: 'meeting-type.stream',
      }[meeting?.type] || '--'
    );
  }

  public meetingFullTitle(meeting: Meeting): string {
    if (!meeting) {
      return null;
    }

    let subject = '';
    if ('subject' in meeting) {
      if (+meeting.subject?.id === otherSubjectId) {
        subject = meeting.subject_other_name || '---';
      } else {
        subject = (meeting.subject ? meeting.subject.title : null) || '---';
      }
    }
    const level = meeting.level ? meeting.level.title : this.translate.instant('subject-levels.any-level');

    let dateFormatted = '';
    if (meeting.begin_at && meeting.end_at) {
      dateFormatted = [
        this.dateFormat.transform(new Date(meeting.begin_at), 'PPP HH:mm'),
        this.dateFormat.transform(new Date(meeting.end_at), 'HH:mm'),
      ].join(' — ');
    }
    const authorName = AccountService.getUserFullName(meeting.author);

    // title subject date level author | classes -- OnClass
    const titleParts = [meeting.title, ', ', subject];
    if (dateFormatted) {
      titleParts.push(', ', dateFormatted);
    }
    titleParts.push(', ', level);
    if (authorName) {
      titleParts.push(', ', authorName);
    }
    return titleParts.join('');
  }

  /** Очень короткая информация про митинг для первой фазы загрузки митинга */
  public findMeetingSession(session_id: string): Observable<IMeetingSession> {
    const url = `${environment.content_url}/meeting/session/${session_id}`;
    return this.http.get<IMeetingSession>(url, { observe: 'body' });
  }

  /** Один митинг по ID митинга */
  public getMeetingById(id: string, details?: MeetingDetailsType[]): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}`;
    const params = new HttpParams().set('details', stringify(details || meetingDetails));
    return this.http.get<Meeting>(url, { params, observe: 'response' }).pipe(map(this.handleMeetingResponse));
  }

  /** Митинги по фильтру */
  public getMeetings(
    filter: Partial<IFindMeetingsFilter>,
    pagination: IPagination,
    details?: MeetingDetailsType[],
  ): Observable<IPagedResults<Meeting[]>> {
    const url = `${environment.content_url}/meetings/`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', stringify(filter))
      .set('details', stringify(details || meetingDetails));
    return this.http.get<Meeting[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleMeetingsResponse),
      // catchError(this.handleError),
    );
  }

  /** Участники митинга */
  public getMeetingParticipants(id: string, pagination: IPagination): Observable<IPagedResults<MeetingParticipant[]>> {
    const url = `${environment.content_url}/meeting/${id}/participants`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString());
    return this.http.get<MeetingParticipant[]>(url, { params, observe: 'response' }).pipe(
      map((response) => ({
        results: response.body.map((i) => new MeetingParticipant(i)),
        page: +response.headers.get('X-Pagination-Page'),
        pageSize: +response.headers.get('X-Pagination-PageSize'),
        total: +response.headers.get('X-Pagination-Total'),
        totalPages: +response.headers.get('X-Pagination-TotalPages'),
      })),
    );
  }

  /** Обновление митинга */
  public updateMeeting(id: string, values: Partial<Meeting>): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}`;
    return this.http.put<Meeting>(url, values, { observe: 'response' }).pipe(map(this.handleMeetingResponse));
  }

  /** Публикация митинга */
  public publishMeeting(id: string): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}/publish`;
    return this.http.post<Meeting>(url, {}, { observe: 'response' }).pipe(map(this.handleMeetingResponse));
  }

  /** Приглашение участников в митинг */
  public inviteUsers(
    id: string,
    participants: Array<Partial<MeetingParticipant>>,
    details?: MeetingDetailsType[],
  ): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}/participants/invite`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http
      .post<Meeting>(url, { participants }, { params, observe: 'response' })
      .pipe(map(this.handleMeetingResponse));
  }

  /** Проверка времени на занятость другим митингом */
  public checkThatTimeIsAvailableForMeeting(
    from: Date,
    to: Date,
    user_id: string,
    meeting_id?: string,
  ): Observable<{ result: boolean; meeting: Meeting }> {
    meeting_id = meeting_id || '-';
    const url = `${environment.content_url}/meeting/${meeting_id}/available-for/${user_id}`;
    return this.http.post(url, { from, to }, { observe: 'body' }).pipe(
      map((response: any) => response),
      catchError(async () => false),
    );
  }

  /** Продлить митинг */
  public extendMeeting(
    meeting_id: string,
    request: MeetingExtendRequest,
    details?: MeetingDetailsType[],
  ): Observable<MeetingExtendResponse> {
    const url = `${environment.content_url}/meeting/${meeting_id}/extend`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', stringify(details));
    }
    return this.http
      .post<MeetingExtendResponse>(url, request, { params, observe: 'body' })
      .pipe(map((response) => new MeetingExtendResponse(response)));
  }

  public createMeeting(values: Partial<Meeting>): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/`;
    return this.http.post<Meeting>(url, values, { observe: 'response' }).pipe(map(this.handleMeetingResponse));
  }

  private handleMeetingResponse(response: HttpResponse<Meeting>): Meeting {
    return new Meeting(response.body);
  }

  private handleMeetingsResponse(response: HttpResponse<Meeting[]>): IPagedResults<Meeting[]> {
    return createPagedResults(response, (m) => new Meeting(m));
  }
}
