import 'reflect-metadata';
/** *
 * Главный модуль, тут находятся основные настройки дальше он
 * подгружает другой модуль, который уже инициализарует сервисы и компоненты
 *
 * @see AppModule
 **/
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';

import versions from './_versions';
import { AppModule } from './app/app.module';

Sentry.init({
  beforeSend(event) {
    // eslint-disable-next-line no-console
    console.log('beforeSend', event);
    return event;
  },
  dsn: environment.sentry.dsn,
  integrations: [
    Sentry.browserTracingIntegration({
      // tracePropagationTargets: ['localhost', /^https:\/\/b\.onclass\.(com|space|tech)\//],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  dist: versions.version,
  release: versions.version,
  environment: process.env.ASSETS_MODE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
