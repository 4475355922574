import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { AbstractScreenService } from 'lingo2-forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

type FullScreenHTMLElement = HTMLElement & {
  webkitSupportsFullscreen?: boolean;
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
  webkitEnterFullscreen(): Promise<void>;
};

export interface IScreen {
  availWidth: number;
  availHeight: number;
  width: number;
  height: number;
  isPortrait: boolean;
  isLandscape: boolean;
}

export enum DeviceOrientationEnum {
  portrait = 'portrait',
  landscape = 'landscape',
}

export interface ScreenSize {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService extends AbstractService implements AbstractScreenService {
  public options: Observable<IScreen>;
  public updated$: Observable<boolean>;
  public width$: Observable<number>;
  public size$: Observable<ScreenSize>;
  public screenClasses$: Observable<Record<string, boolean>>;

  private _options = this.register(new ReplaySubject<IScreen>(1));
  private updated = this.register(new Subject<boolean>());
  private width = this.register(new ReplaySubject<number>(1));
  private size = this.register(new ReplaySubject<ScreenSize>(1));
  private screenClasses = this.register(new ReplaySubject<Record<string, boolean>>());

  private _isMobile = false;

  public constructor(
    public deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) protected document: Document,
    protected deviceDetector: DeviceDetectorService,
    protected zone: NgZone,
    protected inject?: Injector,
  ) {
    super(inject);

    this.options = this._options.asObservable();
    this.updated$ = this.updated.asObservable();
    this.width$ = this.width.asObservable();
    this.size$ = this.size.asObservable();
    this.screenClasses$ = this.screenClasses.asObservable();

    this.onResize(window.innerWidth, window.innerHeight);
    fromEvent(window, 'resize')
      .pipe(
        map(() => ({ width: window.innerWidth, height: window.innerHeight })),
        distinctUntilChanged(),
        debounceTime(250),
        tap((size) => this.onResize(size.width, size.height)),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public get isMobile() {
    return this._isMobile;
  }

  public get deviceOrientation() {
    if (screen.orientation) {
      if (['portrait-primary', 'portrait-secondary'].includes(screen.orientation.type)) {
        return DeviceOrientationEnum.portrait;
      } else if (['landscape-primary', 'landscape-secondary'].includes(screen.orientation.type)) {
        return DeviceOrientationEnum.landscape;
      }
    } else {
      if (window.innerHeight > window.innerWidth) {
        return DeviceOrientationEnum.portrait;
      } else {
        return DeviceOrientationEnum.landscape;
      }
    }
  }

  /** Классы CSS для мобильных устройств */
  protected updateScreenClasses() {
    const isPortrait = this.deviceOrientation === DeviceOrientationEnum.portrait;
    const isDesktop =
      this.deviceDetector.isDesktop() && !this.deviceDetector.isMobile() && !this.deviceDetector.isTablet();
    const isMobile = this.deviceDetector.isMobile();
    const isTablet = this.deviceDetector.isTablet();
    this.screenClasses.next({
      tablet: isTablet,
      mobile: isMobile,
      desktop: isDesktop,
      portrait: !isDesktop && isPortrait,
      landscape: !isDesktop && !isPortrait,
    });
  }

  public get isTouch() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
  }

  public get isSafari(): boolean {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1
    );
  }

  public static screenOptions(): IScreen {
    let orientation: 'portrait' | 'landscape' = 'portrait';

    if (screen.orientation) {
      const _orientation =
        (screen.orientation || {}).type || (screen as any).mozOrientation || (screen as any).msOrientation;
      if (_orientation) {
        if (_orientation === 'landscape-primary' || _orientation === 'landscape-secondary') {
          orientation = 'landscape';
        }
      } else {
        if (window.orientation === 90 || window.orientation === 270) {
          orientation = 'landscape';
        }
      }
    } else {
      if (window.innerHeight < window.innerWidth) {
        orientation = 'landscape';
      }
    }

    return {
      availWidth: window.screen.availWidth,
      availHeight: window.screen.availHeight,
      width: window.screen.width,
      height: window.screen.height,
      // TODO isPortrait: window.screen.orientation ?
      // TODO isLandscape: window.screen.orientation ?

      isPortrait: orientation === 'portrait',
      isLandscape: orientation === 'landscape',
      // innerWidth: window.innerWidth,
      // innerHeight: window.innerHeight,
    };
  }

  public setBodyFixed(state: boolean) {
    this.setBodyClass('fixed', state);
    if (!this.deviceService.isDesktop()) {
      this.setBodyClass('mobile', state);
    }
  }

  public get isFullscreen(): boolean {
    return !!this.document.fullscreenElement;
  }

  public isFullscreenPresent(element?: HTMLElement): boolean {
    const docElmWithBrowsersFullScreenFunctions: FullScreenHTMLElement =
      (element as FullScreenHTMLElement) || (this.document.documentElement as FullScreenHTMLElement);

    return (
      !!docElmWithBrowsersFullScreenFunctions &&
      !!(
        docElmWithBrowsersFullScreenFunctions.requestFullscreen ||
        docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen ||
        docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen ||
        docElmWithBrowsersFullScreenFunctions.msRequestFullscreen
      )
    );
  }

  public openFullscreen(element?: HTMLElement) {
    // Trigger fullscreen

    const docElmWithBrowsersFullScreenFunctions: FullScreenHTMLElement =
      (element as any) || (this.document.documentElement as any);

    document.body.classList.add('open-fullscreen');

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) {
      /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) {
      /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitSupportsFullscreen) {
      /* iPhone/iPad */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen(); // iPhone/iPad
    }
  }

  public closeFullscreen() {
    document.body.classList.remove('open-fullscreen');
    const docWithBrowsersExitFunctions = this.document as Document & {
      webkitSupportsFullscreen: boolean;
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    } else if (docWithBrowsersExitFunctions.webkitSupportsFullscreen) {
      docWithBrowsersExitFunctions.webkitExitFullscreen(); // iPhone/iPad
    }
  }

  public switchFullScreenMode(element?: HTMLElement) {
    if (!this.isFullscreen) {
      this.openFullscreen(element);
    } else {
      this.closeFullscreen();
    }
  }

  public setBodyClass(className: string, state: boolean) {
    this.zone.runOutsideAngular(() => {
      const body = document.getElementsByTagName('body')[0];
      if (state) {
        body.classList.add(className);
      } else {
        body.classList.remove(className);
      }
    });
  }

  // ----------

  protected onResize(width, height) {
    this._isMobile = width <= 768;
    this.width.next(width);
    this.size.next({ width, height });
    this._options.next(ScreenService.screenOptions());
    this.updated.next(true);
    this.updateScreenClasses();
  }
}
