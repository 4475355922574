import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { UUIDv4 } from '@app/models/helpers';
import {
  EventEnum,
  ParticipantAccessChangedEvent,
  ParticipantOptionsChangedEvent,
  ParticipantsListEvent,
  ParticipantStatusEnum,
  ParticipantStatusEvent,
  ParticipantMediaAccessEvent,
  StatusMessage,
  ParticipantAddedEvent,
  CursorMoveMessage,
  CursorClickMessage,
  ParticipantAccessOptions,
  ChangeParticipantAccessMessage,
  ParticipantOptions,
  ChangeParticipantOptionsMessage,
  AcknowledgmentEvent,
  OverrideMeetingMessage,
  ListParticipantsMessage,
  ParticipantRoleEnum,
  ChangeParticipantsRoleMessage,
  ParticipantTroubleEnum,
  MediaAccessEnum,
  MediaAccessStatusMessage,
  CursorEnum,
} from 'lingo2-conference-models';
import { Observable, of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';
import { ApplicationState } from './application.state';
import { ParticipantsState } from './participants.state';
import { WebsocketService } from './websocket';

/**
 * Сервис отвечает за синхронизацию состояний участников митинга
 *
 * @see WsBoardsSyncService
 * @see WsMeetingSyncService
 * @see WsParticipantsSyncService
 */
@Injectable({
  providedIn: 'root',
})
export class WsParticipantsSyncService extends AbstractService implements OnDestroy {
  constructor(
    protected applicationState: ApplicationState,
    protected participantsState: ParticipantsState,
    protected ws: WebsocketService,
    protected injector: Injector,
  ) {
    super();
    this.init();
  }

  protected init() {
    of(this.watchWsParticipantsEvents$).pipe(mergeAll(), takeUntil(this.destroyed$)).subscribe();
  }

  // ---------- управление участниками

  /** запросить список участников митинга */
  public listParticipants(user_id?: string[]) {
    this.ws.send(
      ListParticipantsMessage.createInstance({
        user_id,
      }),
    );
  }

  /** Статус пользователя */
  public status(status: ParticipantStatusEnum, trouble?: ParticipantTroubleEnum) {
    this.ws.send(StatusMessage.createInstance({ status, trouble }));
  }

  /** Состояние доступа к медиа-устройствам */
  public mediaAccess(media_access: MediaAccessEnum) {
    this.ws.send(
      MediaAccessStatusMessage.createInstance({
        media_access,
      }),
    );
  }

  /** Передать положение курсора в движении */
  public trackCursorMove(board_id: string, layer_id: string, x: number, y: number, cursor: CursorEnum) {
    this.ws.send(
      CursorMoveMessage.createInstance({
        board_id,
        layer_id,
        x,
        y,
        cursor,
      }),
    );
  }

  /** Передать положение курсора при клике */
  public trackCursorClick(board_id: string, layer_id: string, x: number, y: number, cursor: CursorEnum) {
    this.ws.send(
      CursorClickMessage.createInstance({
        board_id,
        layer_id,
        x,
        y,
        cursor,
      }),
    );
  }

  /** Поменять роль пользователей */
  public changeRole(user_id: string[], role_override: ParticipantRoleEnum): Observable<AcknowledgmentEvent> {
    const message = ChangeParticipantsRoleMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      user_id,
      role_override,
    });
    this.ws.send(message);

    /*
    по окончанию обработки придёт серия событий ParticipantAccessChangedEvent
    и ParticipantOptionsChangedEvent
    */

    return this.ws.onAsk(message.messageId);
  }

  /** Поменять один или несколько параметров доступа */
  public changeAccess(user_id: string, access: Partial<ParticipantAccessOptions>): Observable<AcknowledgmentEvent> {
    const message = ChangeParticipantAccessMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      user_id,
      access,
    });
    this.ws.send(message);

    /* по окончанию обработки придёт событие ParticipantAccessChangedEvent */

    return this.ws.onAsk(message.messageId);
  }

  /** Поменять одну или несколько настроек */
  public changeOptions(user_id: string, options: Partial<ParticipantOptions>): Observable<AcknowledgmentEvent> {
    const message = ChangeParticipantOptionsMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      user_id,
      options,
    });
    this.ws.send(message);

    /* по окончанию обработки придёт событие ParticipantOptionsChangedEvent */

    return this.ws.onAsk(message.messageId);
  }

  /** Заменить текущий митинг другим (например, из истории ранее проведённых митингов) */
  public changeOverrideMeeting(meeting_id_override: string) {
    this.ws.send(
      OverrideMeetingMessage.createInstance({
        meeting_id_override,
      }),
    );
  }

  // ---------- управление участниками

  protected get watchWsParticipantsEvents$() {
    return of(
      this.wsParticipantsListEvent$,
      this.wsParticipantAddedEvent$,
      this.wsParticipantStatusEvent$,
      this.wsParticipantMediaAccessEvent$,
      this.wsParticipantAccessChangedEvent$,
      this.wsParticipantOptionsChangedEvent$,
    ).pipe(mergeAll());
  }

  protected get wsParticipantsListEvent$() {
    return this.ws.on<ParticipantsListEvent>(EventEnum.ParticipantsListEvent).pipe(
      tap((event) => {
        event.participants.map((participant) => this.participantsState.addParticipant(participant));
      }),
    );
  }

  protected get wsParticipantAddedEvent$() {
    return this.ws.on<ParticipantAddedEvent>(EventEnum.ParticipantAddedEvent).pipe(
      tap((event) => {
        this.participantsState.addParticipant(event.participant);
      }),
    );
  }

  protected get wsParticipantStatusEvent$() {
    return this.ws.on<ParticipantStatusEvent>(EventEnum.ParticipantStatusEvent).pipe(
      tap((event) => {
        this.participantsState.updateParticipantStatus(event.user_id, event.status, event.trouble);
      }),
    );
  }

  protected get wsParticipantMediaAccessEvent$() {
    return this.ws.on<ParticipantMediaAccessEvent>(EventEnum.ParticipantMediaAccessEvent).pipe(
      tap((event) => {
        this.participantsState.updateParticipantMediaAccess(event.user_id, event.media_access);
      }),
    );
  }

  protected get wsParticipantAccessChangedEvent$() {
    return this.ws.on<ParticipantAccessChangedEvent>(EventEnum.ParticipantAccessChangedEvent).pipe(
      tap((event) => {
        this.participantsState.updateParticipantAccess(event.user_id, event.access);
      }),
    );
  }

  protected get wsParticipantOptionsChangedEvent$() {
    return this.ws.on<ParticipantOptionsChangedEvent>(EventEnum.ParticipantOptionsChangedEvent).pipe(
      tap((event) => {
        this.participantsState.updateParticipantOptions(event.user_id, event.options);
      }),
    );
  }
}
