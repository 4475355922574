import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent } from '@app/models';
import { WebsocketChatService, WebsocketService, WebSocketStatusEnum } from '@app/services';
import { environment } from '@environments/environment';
import { of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-application-version',
  templateUrl: './application-version.component.html',
  styleUrls: ['./application-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationVersionComponent extends AbstractComponent implements OnInit {
  public versions = environment.versions;
  public versionDate: string;
  public mainSocketStatus: 'online' | 'connecting' | 'disconnect' = 'disconnect';
  public chatSocketStatus: 'online' | 'connecting' | 'disconnect' = 'disconnect';

  public constructor(
    protected mainSocketService: WebsocketService,
    protected chatSocketService: WebsocketChatService,
    protected injector: Injector,
  ) {
    super(injector);
    // this.setLogNamespace('onclass:component:ApplicationVersion');
    this.versionDate = this.versions.gitCommitDate || this.versions.versionDate;
  }
  public ngOnInit(): void {
    of(this.watchScreenOptions$).pipe(mergeAll(), takeUntil(this.destroyed$)).subscribe();
    this.mainSocketService.status$
      .pipe(
        tap((status) => {
          if ([WebSocketStatusEnum.connected].includes(status)) {
            this.mainSocketStatus = 'online';
          } else if ([WebSocketStatusEnum.connecting, WebSocketStatusEnum.reconnecting].includes(status)) {
            this.mainSocketStatus = 'connecting';
          } else {
            this.mainSocketStatus = 'disconnect';
          }
          this.detectChanges();
        }),
      )
      .subscribe();

    this.chatSocketService.status$
      .pipe(
        tap((status) => {
          if (status) {
            this.chatSocketStatus = 'online';
          } else {
            this.chatSocketStatus = 'disconnect';
          }
          this.detectChanges();
        }),
      )
      .subscribe();
  }

  public get mainSocketClass() {
    return [this.mainSocketStatus];
  }

  public get chatSocketClass() {
    return [this.chatSocketStatus];
  }
}
