import { ChangeDetectorRef, Directive, ElementRef, Injector, OnDestroy, Renderer2, ViewRef } from '@angular/core';
import { createDummyLogger, createLogger, ILogger } from '@app/models/logger';
import { DeviceDetectorService, LanguageService } from '@app/services';
import { ErrorService } from '@app/services/error.service';
import { IconsService } from '@app/services/icon.service';
import { PlatformService } from '@app/services/platform';
import { DeviceOrientationEnum, ScreenService } from '@app/services/screen.service';
import { environment } from '@environments/environment';
import { FeaturesType } from '@environments/IEnvironment';
import { addMilliseconds } from 'date-fns';
import { Language } from 'lingo2-models';
import trimEnd from 'lodash-es/trimEnd';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { sampleTime, filter, first, mergeAll, takeUntil, tap } from 'rxjs/operators';

type TimerLike = number | Subscription;

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {
  public isDesktop = false;
  public isMobile = false;
  public isPortrait = false;
  public isTablet = false;
  public assets_url: string = trimEnd(environment.assets_url, '/');

  private screenClasses: Record<string, boolean>;

  public svgsetIcon: (icon: string) => string = IconsService.svgIconUrl;
  public svgsetMeetIcon: (icon: string) => string = IconsService.svgIconUrl;

  public screenService: ScreenService;
  protected platform: PlatformService;
  protected screen: ScreenService;
  protected deviceDetector: DeviceDetectorService;
  protected abstractElementRef: ElementRef;
  protected abstractRenderer: Renderer2;
  protected logger: ILogger = createDummyLogger();
  protected changeDetector: ChangeDetectorRef;
  protected errorService: ErrorService;
  protected languageService: LanguageService;

  protected language: Language;
  protected readonly completable: Array<Subject<any>> = [];
  protected readonly destroyed$ = new Subject<boolean>();

  private readonly detectChanges$ = new BehaviorSubject<{ state: boolean; sampleTime: number }>({
    state: false,
    sampleTime: 32,
  });

  protected constructor(protected inject?: Injector) {
    this.logger = createLogger('onclass:' + this.constructor.name);

    if (!inject) {
      /*  const message = `Injector is not defined, ${this.constructor.name}`;
      this.logger.warn(message);*/
      return this;
    }

    this.changeDetector = this.inject.get(ChangeDetectorRef);
    this.deviceDetector = this.inject.get(DeviceDetectorService);
    this.screenService = this.inject.get(ScreenService);
    this.platform = this.inject.get(PlatformService);
    this.screen = this.inject.get(ScreenService);
    this.errorService = this.inject.get(ErrorService);
    this.languageService = this.inject.get(LanguageService);

    if (ElementRef && Renderer2) {
      this.abstractRenderer = this.inject.get(Renderer2);
      this.abstractElementRef = this.inject.get(ElementRef);
    }

    this.updateDeviceInfo();

    this.watchDetectChanges$.pipe(takeUntil(this.destroyed$)).subscribe();
    this.watchLanguage$.pipe(takeUntil(this.destroyed$)).subscribe();
  }

  public assetsUrl(url: string): string {
    const isAbsolute = url.indexOf('http://') === 0 || url.indexOf('https://') === 0 || url.indexOf('//') === 0;
    return isAbsolute ? url : this.assets_url + url;
  }

  public get portalUrl(): string {
    return trimEnd(this.language?.url || environment.portal_url, '/');
  }

  /** Слежение за запросами на рендер компонента */
  protected get watchDetectChanges$() {
    return this.detectChanges$.pipe(
      filter(
        (changeDetect) => !!changeDetect?.state && this.changeDetector && !(this.changeDetector as ViewRef).destroyed,
      ),
      sampleTime(32),
      tap(() => {
        this.changeDetector.detectChanges();
      }),
    );
  }

  /** Слежение за ориентацией и размерами экрана */
  protected get watchScreenOptions$(): Observable<any> {
    return this.screen.width$.pipe(
      tap(() => {
        this.updateDeviceInfo();
      }),
    );
  }

  /** Текущий язык */
  protected get watchLanguage$(): Observable<Language> {
    return this.languageService.language$.pipe(
      tap((language) => {
        this.language = language;
        this.detectChanges();
      }),
    );
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.completable.map((s) => s.complete());
    this.completable.splice(0);
  }

  public updateDeviceInfo() {
    /* this.isPortrait = this.screen.deviceOrientation === DeviceOrientationEnum.portrait;
    this.isDesktop = this.deviceDetector.isDesktop();
    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();*/
    this.updateScreenClasses();
  }

  /** Включена ли отдельная функциональная возможность, логический блок или компонента */
  public isFeatureAvailable(feature: FeaturesType): boolean {
    return environment.features[feature];
  }

  protected register<T extends Subject<any>>(instance: T): T {
    this.completable.push(instance);
    return instance;
  }

  protected setTimeout(func: () => void, timeout: number): Subscription {
    const dueTime = addMilliseconds(new Date(), timeout);
    return timer(dueTime).pipe(first(), takeUntil(this.destroyed$)).subscribe(func);
  }

  protected clearTimeout(timerLike: TimerLike) {
    if (!timerLike) {
      return;
    }
    if (typeof timerLike === 'number') {
      clearTimeout(timerLike);
    } else {
      if ('unsubscribe' in timerLike) {
        // instanceof Subscription
        timerLike.unsubscribe();
      }
    }
  }

  protected setInterval(func: () => void, period: number): Subscription {
    return interval(period).pipe(takeUntil(this.destroyed$)).subscribe(func);
  }

  protected clearInterval(intervalLike: TimerLike) {
    if (!intervalLike) {
      return;
    }
    if (typeof intervalLike === 'number') {
      clearInterval(intervalLike);
    } else {
      if ('unsubscribe' in intervalLike) {
        // instanceof Subscription
        intervalLike.unsubscribe();
      }
    }
  }

  protected detectChanges(_sampleTime = 32) {
    this.detectChanges$.next({ state: true, sampleTime: _sampleTime });
  }

  protected markForCheck(_sampleTime = 32) {
    this.detectChanges$.next({ state: true, sampleTime: _sampleTime });
  }

  /** Классы CSS для мобильных устройств */
  protected updateScreenClasses() {
    this.isPortrait = this.screen.deviceOrientation === DeviceOrientationEnum.portrait;
    this.isDesktop = this.deviceDetector.isDesktop();
    this.isMobile = this.deviceDetector.isMobile();
    this.isTablet = this.deviceDetector.isTablet();
    this.screenClasses = {
      tablet: this.isTablet,
      mobile: this.isMobile,
      desktop: (!this.isTablet && !this.isMobile) || this.isDesktop,
      portrait: !this.isDesktop && this.isPortrait,
      landscape: !this.isDesktop && !this.isPortrait,
    };
  }

  protected try(fn: () => void, title?: string) {
    try {
      fn();
    } catch (err) {
      this.errorService.err(err);
      if (title) {
        this.logger.error(title, err);
      } else {
        this.logger.error(err);
      }
    }
  }
}
