import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent } from '@app/models/abstract.component';
import isWebview from 'is-ua-webview';
import { fromEvent, of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent implements OnInit {
  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.logger.trace('profiling application started');
    of(this.initPushState$, this.watchPopState$).pipe(mergeAll(), takeUntil(this.destroyed$)).subscribe();

    this.preventBrowserZoom();
  }

  /**
   * При нажатии на кнопки "<-- предыдущая" и "следующая -->" страница
   * браузера добавляет новое состояние в историю навигации.
   *
   * Код ниже предотвращает переход назад по истории браузера, чтобы
   * конференция не закрывалась нечаянно на смартфоне по софткнопке 'назад'.
   *
   * @link https://developer.mozilla.org/ru/docs/Web/API/History/pushState
   */
  get watchPopState$() {
    return fromEvent(window, 'popstate').pipe(
      tap(() => {
        history.pushState(null, null, document.URL);
      }),
    );
  }

  /**
   * Установка по умолчанию текущего состояние в историю навигации браузера
   *
   * Код ниже предотвращает переход назад по истории браузера, чтобы
   * конференция не закрывалась нечаянно на смартфоне по софткнопке 'назад'.
   *
   * @link https://developer.mozilla.org/ru/docs/Web/API/History/pushState
   */
  get initPushState$() {
    return of(true).pipe(
      tap(() => {
        history.pushState(null, null, document.URL);
      }),
    );
  }

  /**
   * Подавление Zoom в браузере
   */
  protected preventBrowserZoom() {
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener('wheel', this.onMouseWheel, { passive: false });
    body.addEventListener('keydown', this.onKeyDown, { passive: false });
  }

  /** Подавление Zoom в браузере с помощью (Ctrl|Cmd) + mouse wheel up/down*/
  protected onMouseWheel = (event: WheelEvent) => {
    if (event.ctrlKey || event.metaKey) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /** Подавление Zoom в браузере с помощью (Ctrl|Cmd) + keypad +|-|0 */
  /** event.ctrlKey = Ctrl в Windows */
  /** event.metaKey = Command в Mac */
  protected onKeyDown = (event: KeyboardEvent) => {
    if (
      (event.ctrlKey && ['_', '-', '+', '0', '='].includes(event.key)) ||
      (event.metaKey && ['_', '-', '+', '0', '='].includes(event.key))
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  /**
   * Проверка на открытие сайта через webview
   */
  public get openFromWebview() {
    return isWebview(navigator.userAgent);
  }
}
