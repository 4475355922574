import { Meeting } from 'lingo2-conference-models';
import {
  IHash,
  IImageFile,
  IMeetingSession,
  MeetingAccessEnum,
  MeetingBeginEnum,
  MeetingParticipant,
  MeetingStatusEnum,
  MeetingTypeEnum,
  TenantEnum,
  User,
} from 'lingo2-models';

/** Состояния митинга, загруженного в приложение */
export enum MeetingStateEnum {
  /** Митинг не готов к запуску */
  Draft,

  /** слишком рано */
  WaitingForStart,

  /** Идет соединение */
  Connecting,

  /** Конференция началась но учитель еще не пришел */
  WaitingForTeacher,

  /** Учитель прервал урок посреди конференции */
  TeacherBreak,

  /** Конференция завершилась */
  MeetingIsOver,

  /** Конференция идет */
  MeetingIsGoing,

  /** Митинг был отменён */
  MeetingCanceled,
}

export class ClientMeeting extends Meeting {
  // constructor(values?: Partial<ClientMeeting>) {
  //   super(values);
  //
  //   if (values) {
  //     const instance = this;
  //     ['slug', 'first_name', 'last_name', 'userpic'].map((f) => {
  //       if (f in values) {
  //         instance[f] = values[f];
  //       }
  //     });
  //   }
  // }
}

const properties: Array<keyof ClientMeetingSession> = [
  'id',
  'title',
  'slug',
  'status',
  'type',
  'user_service_id',
  'school_id',
  'author_id',
  'participant',
  'cover',
  'session_id',
  'session_ws_api_url',
  'session_http_api_url',
  'conference_url',
  'session_token',
  'begin',
  'begin_at',
  'end_at',
  'access',
];

export class ClientMeetingSession implements IMeetingSession {
  /**
   * @see Meeting.id
   */
  public id: string;

  /**
   * Название
   *
   * @see Meeting.title
   */
  public title: string;

  /**
   * @see Meeting.slug
   */
  public slug: string;

  /**
   * @see Meeting.status
   */
  public status: MeetingStatusEnum;

  /**
   * @see Meeting.type
   */
  public type: MeetingTypeEnum;

  /**
   * Идентификатор услуги
   * UserService.id
   */
  public user_service_id?: string;

  /**
   * Идентификатор школы
   * CSChool.id
   */
  public school_id?: string;

  /**
   * Идентификатор автора
   */
  public author_id?: string;

  /** Автор */
  public author?: Partial<User>;

  /** Текущий пользователь как участник митинга */
  public participant?: Partial<MeetingParticipant>;

  /** Изображение обложки */
  public cover?: IHash<IImageFile>;

  /** Уникальный код для конференции */
  public session_id?: string;

  /**
   * URL бэк-сервера lingo2-conference-server, обслуживающего конференцию
   * К этому API подключается lingo2-conference по протоколу websockets
   */
  public session_ws_api_url?: string;

  /**
   * URL бэк-сервера lingo2-conference-server, обслуживающего конференцию
   * Через этот API можно управлять конференцией по протоколу HTTP
   */
  public session_http_api_url?: string;

  /**
   * URL конференции
   * Приложение на базе lingo2-conference
   * Этот URL пользователь открывает в браузере, чтобы принять участие в конференции
   */
  public conference_url?: string;

  /** Токен авторизации в этом конкретном митинге */
  public session_token: string;

  public begin: MeetingBeginEnum;

  public begin_at: Date;

  public end_at: Date;

  public access: MeetingAccessEnum;

  public tenant: TenantEnum;

  constructor(values?: Partial<ClientMeetingSession>) {
    if (values) {
      const instance: any = this;
      properties.forEach((p: keyof Partial<ClientMeetingSession>) => {
        if (p in values) {
          instance[p] = values[p];
        }
      });
    }
  }

  /** *
   * Митинг не оформлен (например, если был создан "по одному клику")
   */
  public get isDraft(): boolean {
    return this.type === MeetingTypeEnum.not_defined;
  }

  /**
   * Митинг стартует автоматически
   *
   * https://app.clickup.com/t/fz4bbc
   *
   * ЗАПЛАНИРОВАННЫЙ МИТИНГ начинается в точное время автоматически
   * БЫСТРЫЙ МИТИНГ начинается ТОЛЬКО по нажатию на кнопку "Начать занятие"
   */
  public get isAutoStart(): boolean {
    return this.begin === MeetingBeginEnum.specified_time;
  }
}

// Перечень состояний модального окна формы митинга
export type TMeetingFormState = 'closed' | 'new' | 'edit' | 'buy_credits' | 'activate_plan';

/** Параметры окна формы митинга */
export interface MeetingFormOptions {
  /** Откуда вызвали */
  caller: string;

  /** Состояние модального окна формы митинга */
  state: TMeetingFormState;
}
