import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { ApplicationStateEnum } from '@app/models';
import { AbstractService } from '@app/models/abstract.service';
import { ApplicationState } from '@app/services/application.state';
import { environment } from '@environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { map, Observable, of, zip } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class TranslationHttpLoader extends AbstractService implements TranslateLoader {
  private applicationState: ApplicationState;
  private http: HttpClient;

  public constructor(protected inject: Injector) {
    super(inject);

    this.applicationState = inject.get(ApplicationState);
    this.http = inject.get(HttpClient);
  }

  public getTranslation(lang: string): Observable<object> {
    const isInContext = (localStorage || false) && localStorage.getItem(environment.i18n_incontext_key);

    const _lang = isInContext ? 'fj-FJ' : lang; // в режиме CrowdIn InContext всегда загружается локаль FJ
    const isEnDEv = environment.is_i18n_developer && _lang === 'en';
    const urlPrefix = isEnDEv // EN локаль для разработчика раздаётся через lingo2_i18n/src/index.js
      ? environment.i18n_en_url
      : `${environment.i18n_url}/${_lang}`;
    const urlPostfix = isEnDEv ? '?r=' + Math.random() : ''; // чтобы подавить кэширование для разработчика

    const files = ['meeting.json', 'game-lib.json', 'web.json', 'forms.json'];

    const observables: Array<Observable<object>> = files.map((file) => {
      const url = `${urlPrefix}/${file}${urlPostfix}`;
      return this.http.get<object>(url).pipe(
        catchError((err) => {
          this.applicationState.setState(ApplicationStateEnum.StateI18nErrTransport);
          this.errorService.err(err);
          return of({});
        }),
      );
    });

    return zip(...observables).pipe(
      map((translations) => {
        let mergedTranslations = {};
        translations.map((translation) => (mergedTranslations = { ...mergedTranslations, ...translation }));

        // TODO проверить наличие нескольких ключей
        // TODO ApplicationStateEnum.StateI18nErrMiss
        // TODO throw new Error('Translations are missed or format is wrong');

        return mergedTranslations;
      }),
    );
  }
}
