import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { ClientBoard } from '@app/models/board';
import { BoardsetEnum } from 'lingo2-conference-models';
import { Content, IHash } from 'lingo2-models';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

interface IContentRequestType {
  board_id: string;
  content_id: string;
}

/** Хранилище информации о досках в текущем проводимом или наблюдаемом митинге */
@Injectable({
  providedIn: 'root',
})
export class BoardsState extends AbstractService {
  /** Текущий тип досок */
  public activeBoardsetType$: Observable<BoardsetEnum>;

  /** ID текущей доски у меня */
  public activeBoardId$: Observable<string>;

  /** ID текущей доски у учителя */
  public teacherActiveBoardId$: Observable<string>;

  /** Полный набор досок */
  public boards$: Observable<ClientBoard[]>;

  /** Уведомление о том, что состав досок изменился */
  public boardsChanged$: Observable<boolean>;

  /** ID контент (board.content.content_id) для которых необходимо получить мета-информацию (обложка, название, ...) */
  public contentRequiredQueue$ = this.register(new ReplaySubject<boolean>(1));
  protected contentRequired: IContentRequestType[] = [];

  protected _boardsSubjects: IHash<BehaviorSubject<ClientBoard>> = {};
  protected _boards: IHash<ClientBoard> = {};
  protected activeBoardsetType = this.register(new BehaviorSubject<BoardsetEnum>(BoardsetEnum.group));
  protected activeBoardId = this.register(new BehaviorSubject<string>(null));
  protected teacherActiveBoardId = this.register(new BehaviorSubject<string>(null));
  protected boards = this.register(new BehaviorSubject<ClientBoard[]>(Object.values(this._boards)));
  protected boardsChanged = this.register(new BehaviorSubject<boolean>(null));

  public constructor() {
    super();

    this.activeBoardsetType$ = this.activeBoardsetType.asObservable();
    this.activeBoardId$ = this.activeBoardId.asObservable();
    this.teacherActiveBoardId$ = this.teacherActiveBoardId.asObservable();
    this.boards$ = this.boards.asObservable();
    this.boardsChanged$ = this.boardsChanged.asObservable();
  }

  public replaceBoards(boards: ClientBoard[]) {
    // Удалить доски
    this.__clearBoards();

    // добавить доски
    boards.map((board) => this.__addBoard(board));

    // сообщить, что есть доски
    this.boards.next(Object.values(this._boards));
    this.boardsChanged.next(true);

    // запросить описание контента (название и обложки уроков) для досок
    Object.values(this._boards).map((board) => this.__requireContent(board));
  }

  public addBoard(board: ClientBoard) {
    const board_id = board.board_id;
    if (board_id in this._boards) {
      return this.updateBoard(board);
    }

    const _board = this.__addBoard(board);

    // сообщить, что доски изменились
    this.boards.next(Object.values(this._boards));
    this.boardsChanged.next(true);

    // запросить описание контента (название и обложки уроков) для досок
    this.__requireContent(_board);
  }

  public updateBoard(board: ClientBoard) {
    const board_id = board.board_id;
    if (!(board_id in this._boards)) {
      return this.addBoard(board);
    }

    const _board = this.__applyValues(this._boards[board_id], board, [
      'title',
      'grid',
      'published',
      'deleted',
      'content_type',
      'content',
      'has_content_history',
      'content_meta',
    ]);

    this._boards[board_id] = _board;
    this._boardsSubjects[board_id].next(_board);

    // сообщить, что доски изменились
    this.boards.next(Object.values(this._boards));
    this.boardsChanged.next(true);

    // запросить описание контента (название и обложки уроков) для досок
    this.__requireContent(_board);
  }

  public updateBoardContentMeta(board_id: string, content_meta: Partial<Content>) {
    if (!(board_id in this._boards)) {
      return;
    }

    const _b = this.__applyValues(this._boards[board_id], { content_meta }, ['content_meta']);

    this._boards[board_id] = _b;
    this._boardsSubjects[board_id].next(_b);

    // сообщить, что доски изменились
    this.boards.next(Object.values(this._boards));
    this.boardsChanged.next(true);
  }

  public setActiveBoardsetType(boardsetType: BoardsetEnum) {
    this.activeBoardsetType.next(boardsetType);
  }

  public setActiveBoardId(board_id: string) {
    if (board_id in this._boards) {
      this.activeBoardId.next(board_id);
    }
  }

  public setTeacherActiveBoardId(board_id: string) {
    if (board_id in this._boards) {
      this.teacherActiveBoardId.next(board_id);
    }
  }

  /** Выдаёт очередь заданий на догрузку информации о контенте и очищает очередь */
  public releaseRequireContentQueue(): IContentRequestType[] {
    const queue = [...this.contentRequired];
    this.contentRequired = [];
    return queue;
  }

  /** Заменяет разрешённые свойства указанного митинга указанными значениями */
  protected __applyValues(
    board: ClientBoard,
    values: Partial<ClientBoard>,
    allowed: Array<keyof ClientBoard>,
  ): ClientBoard {
    allowed.map((f) => {
      if (values[f] !== undefined) {
        (board as any)[f] = values[f];
      }
    });

    return board;
  }

  /** Очищает внутренние массивы досок */
  protected __clearBoards() {
    Object.keys(this._boardsSubjects).map((board_id) => {
      const subj: Subject<any> = this._boardsSubjects[board_id];
      subj.complete();
    });

    this._boards = {};
    this._boardsSubjects = {};
  }

  /** ДОбавляет доску в внутренние массивы */
  protected __addBoard(board: ClientBoard): ClientBoard {
    const _board = new ClientBoard(board);
    this._boards[_board.board_id] = _board;
    this._boardsSubjects[_board.board_id] = this.register(new BehaviorSubject<ClientBoard>(_board));
    return _board;
  }

  /** Помещает задание на догрузку информации о контенте */
  protected __requireContent(board: ClientBoard) {
    if (board.content?.content_id) {
      this.contentRequired.push({
        board_id: board.board_id,
        content_id: board.content.content_id,
      });
      this.contentRequiredQueue$.next(true);
    }
  }
}
