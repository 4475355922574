export interface IIPhonesProperties {
  logicalWidth: number;
  logicalHeight: number;
  innerHeightP?: number;
  innerHeightL?: number;
  innerHeightPExpand?: number;
  innerHeightLExpand?: number;
  devicePixelRatio?: number;
  osVersion?: number;
}

export enum EIPhones {
  XII = 'XII',
  XI_Pro_Max_14 = 'XI_Pro_Max_14',
  XI_Pro_Max = 'XI_Pro_Max',
  XI_Pro = 'XI_Pro',
  XI = 'XI',
  XI_14 = 'XI_14',
  XR = 'XR',
  XS_Max = 'XS_Max',
  XS = 'XS',
  XS_13 = 'XS_13',
  X = 'X',
}

export interface IPlatform {
  IE: boolean;
  Edge: boolean;
  Firefox: boolean;
  Chrome: boolean;

  IOS: boolean;
  Android: boolean;
  iPhone: boolean;
  WebView: boolean;
  Safari: boolean;
  MobileSafari: boolean;
  MobileChrome: boolean;

  mobile: boolean;
  tablet: boolean;
  normal: boolean;

  IPhoneXAndYoung: boolean;
  ToolsExpanded: boolean;
}
