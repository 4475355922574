import { Injectable, Injector } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { UUIDv4 } from '@app/models/helpers';
import {
  AcknowledgmentEvent,
  ActivateBoardMessage,
  Board,
  BoardActivatedEvent,
  BoardContent,
  BoardContentTypeEnum,
  BoardCreatedEvent,
  BoardGridEnum,
  BoardHistoryMessage,
  BoardHistoryRetrieveMessage,
  BoardHistoryStartedEvent,
  BoardHistoryUpsyncMessage,
  BoardsListEvent,
  BoardUpdatedEvent,
  ChangeBoardContentMessage,
  CreateBoardMessage,
  EventEnum,
  ListBoardsMessage,
  ParticipantRoleEnum,
  RemoveBoardMessage,
  UpdateBoardGridMessage,
} from 'lingo2-conference-models';
import { combineLatest, Observable, of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';
import { ApplicationState } from './application.state';
import { BoardsState } from './boards.state';
import { MeetingState } from './meeting.state';
import { ParticipantsState } from './participants.state';
import { WebsocketService } from './websocket';

/**
 * Сервис отвечает за синхронизацию состояний досок митинга
 *
 * @see WsBoardsSyncService
 * @see WsMeetingSyncService
 * @see WsParticipantsSyncService
 */
@Injectable({
  providedIn: 'root',
})
export class WsBoardsSyncService extends AbstractService {
  protected myUserId: string;
  protected ownersUserIds: string[];
  protected activeBoardId: string;

  constructor(
    protected applicationState: ApplicationState,
    protected meetingState: MeetingState,
    protected participantsState: ParticipantsState,
    protected boardsState: BoardsState,
    protected ws: WebsocketService,
    protected injector: Injector,
  ) {
    super();
    this.init();
  }

  protected init() {
    of(this.watchParticipants$, this.watchWsBoardsEvents$).pipe(mergeAll(), takeUntil(this.destroyed$)).subscribe();
  }

  // --- управление досками митинга

  /** Запросить доски митинга */
  public listBoards$(meeting_id?: string): Observable<BoardsListEvent> {
    const message = ListBoardsMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      meeting_id,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Запросить историю по доске */
  public boardHistory$(meeting_id: string, board_id: string): Observable<BoardHistoryStartedEvent> {
    const message = BoardHistoryMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      meeting_id,
      board_id,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Запросить (ПЕРЕ)ОТПРАВКУ пакетов команд истории по доске */
  public boardHistoryRetrieve(meeting_id: string, board_id: string, event_chunks: string[]) {
    this.ws.send(
      BoardHistoryRetrieveMessage.createInstance({
        meeting_id,
        board_id,
        event_chunks,
      }),
    );
  }

  /** Запросить ПРОДОЛЖЕНИЕ истории по доске */
  public boardHistoryUpsync$(
    meeting_id: string,
    board_id: string,
    last_event_id: number,
  ): Observable<BoardHistoryStartedEvent> {
    const message = BoardHistoryUpsyncMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      meeting_id,
      board_id,
      last_event_id,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Создать доску */
  public createBoard$(board: Board): Observable<AcknowledgmentEvent> {
    const message = CreateBoardMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      board_id: board.board_id,
      boardset: board.boardset,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Активировать доску */
  public activateBoard(board_id: string) {
    if (this.activeBoardId !== board_id) {
      this.ws.send(ActivateBoardMessage.createInstance({ board_id }));
    }
  }

  /** Удалить доску */
  public removeBoard$(board_id: string): Observable<AcknowledgmentEvent> {
    const message = RemoveBoardMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      board_id,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  /** Изменить сетку на доске */
  public updateGrid(board_id: string, grid: BoardGridEnum) {
    this.ws.send(UpdateBoardGridMessage.createInstance({ board_id, grid }));
  }

  /** Изменить тип содержимого на доске */
  public changeContentType$(
    board_id: string,
    content_type: BoardContentTypeEnum,
    content?: Partial<BoardContent>,
  ): Observable<AcknowledgmentEvent> {
    const message = ChangeBoardContentMessage.createInstance({
      messageId: UUIDv4(),
      withAsk: true,
      board_id,
      content_type,
      content,
    });
    this.ws.send(message);
    return this.ws.onAsk(message.messageId);
  }

  // ----------

  protected get watchParticipants$() {
    return combineLatest([
      this.participantsState.meAsParticipant$,
      this.participantsState.filterParticipants$({ role: [ParticipantRoleEnum.owner] }),
    ]).pipe(
      tap(([me, owners]) => {
        this.myUserId = me?.user_id;
        this.ownersUserIds = (owners || []).map((p) => p.user_id);
      }),
    );
  }

  protected get watchWsBoardsEvents$() {
    return of(
      this.wsBoardsListEvent$,
      this.wsBoardCreatedEvent$,
      this.wsBoardUpdatedEvent$,
      this.wsBoardActivatedEvent$,
    ).pipe(mergeAll());
  }

  protected get wsBoardsListEvent$() {
    return this.ws
      .on<BoardsListEvent>(EventEnum.BoardsListEvent)
      .pipe(tap((event) => this.boardsState.replaceBoards(event.boards)));
  }

  protected get wsBoardCreatedEvent$() {
    return this.ws
      .on<BoardCreatedEvent>(EventEnum.BoardCreatedEvent)
      .pipe(tap((event: BoardCreatedEvent) => this.boardsState.addBoard(event.board)));
  }

  protected get wsBoardUpdatedEvent$() {
    return this.ws
      .on<BoardUpdatedEvent>(EventEnum.BoardUpdatedEvent)
      .pipe(tap((event: BoardUpdatedEvent) => this.boardsState.updateBoard(event.board)));
  }

  protected get wsBoardActivatedEvent$() {
    return this.ws.on<BoardActivatedEvent>(EventEnum.BoardActivatedEvent).pipe(
      tap((event: BoardActivatedEvent) => {
        if (event.user_id === this.myUserId) {
          this.activeBoardId = event.board_id;
          this.boardsState.setActiveBoardId(event.board_id);
        } else if (this.ownersUserIds.includes(event.user_id)) {
          this.boardsState.setTeacherActiveBoardId(event.board_id);
        }

        if (event.follow_me) {
          this.activateBoard(event.board_id);
        }
      }),
    );
  }
}
