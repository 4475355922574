import { Board } from 'lingo2-conference-models';
import { Content } from 'lingo2-models';

export class ClientBoard extends Board {
  public content_meta?: Partial<Content>;

  constructor(values?: Partial<ClientBoard>) {
    super(values);

    if (values) {
      const instance = this;
      ['content_meta'].map((f) => {
        if (f in values) {
          instance[f] = values[f];
        }
      });
    }
  }
}
