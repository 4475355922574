import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import { BuyCreditsEndpoint, CancelPlanEndpoint } from 'lingo2-api-models';
import { AbstractBillingService } from 'lingo2-forms';
import {
  AccountBalanceTypeEnum,
  AccountPlan,
  ActivatePlanRequest,
  BillingOperation,
  BillingPlan,
  BillingProfile,
  CancelPlanResponse,
  CPriceTier,
  currencies,
  CurrencyEnum,
  IAccountBillingPlan,
  IBillingCard,
  IBillingPlan,
  IBillingSettings,
  ICurrencyAmount,
  IExchangeRate,
  IMeetingPriceTier,
  IPriceTier,
  IResponse,
  SwitchPlanRequest,
  SwitchPlanResponse,
  Wallet,
} from 'lingo2-models';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';

export interface IBillingMeetingsSettings {
  prices: IPriceTier[];
}

export interface IBillingProcessingFeeRules {
  feePercent: number;
  feeMinUsd: number;
  taxPercent: number;
  taxMinUsd: number;
}

export interface IBillingCancelMeetingFineRules {
  author_fine_amount: number;
  author_fine_currency: CurrencyEnum;
  participant_fine_percent: number;
}

export interface IBillingFeeFineSettings {
  stripeFee: IBillingProcessingFeeRules;
  cloudpaymentsFee: IBillingProcessingFeeRules;
  cancelMeetingFineRules: IBillingCancelMeetingFineRules;
}

export interface IBillingResponse<T> {
  signature: string;
  payload: T;
}

const billing_url = `${environment.account_url}/billing`;

// @see https://javascript.ru/php/number_format
// Format a number with grouped thousands
//
// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
// +	 bugfix by: Michael White (http://crestidg.com)
export function number_format(value: number, decimals = 2, dec_point = '.', thousands_sep = ' ') {
  let _value;
  let j;

  _value = (+value || 0).toFixed(decimals);
  _value = parseInt(_value, 10) + '';

  j = _value.length;
  if (j > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  const km = j ? _value.substr(0, j) + thousands_sep : '';
  const kw = _value.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);
  // kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : '');
  const kd = decimals
    ? dec_point +
      Math.abs(value - _value)
        .toFixed(decimals)
        .replace(/-/, '0')
        .slice(2)
    : '';
  return km + kw + kd;
}

export interface PaywallModalOptions {
  /** Откуда вызвали */
  caller: string;

  /** Причина вызова */
  reason: string;

  /** Рекомендованный план для покупки (чтобы пропустиь выбор тарифа) */
  recommendedPlan?: BillingPlan;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService extends AbstractService implements AbstractBillingService {
  private _paywallOptions = this.register(new BehaviorSubject<PaywallModalOptions>(null));
  public paywallOptions$ = this._paywallOptions.asObservable();
  private settingsSubject = new BehaviorSubject<IBillingSettings>(null);
  private settings$ = this.settingsSubject.asObservable();
  private priceTiers$: { [key: string]: ReplaySubject<IPriceTier> } = {};
  private version = 1;
  private _settingsLoading$$: Subscription;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
  }

  public init() {
    this.auth.me$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.getSettings$(true));
  }

  public getPriceTier$(price_tier_id: number): Observable<IPriceTier> {
    if (!(price_tier_id in this.priceTiers$)) {
      this.priceTiers$[price_tier_id] = new ReplaySubject<IPriceTier>(1);
    }
    return this.priceTiers$[price_tier_id].asObservable();
  }

  private definePriceTier$(price_tier: IPriceTier) {
    if (!(price_tier.id in this.priceTiers$)) {
      this.priceTiers$[price_tier.id] = new ReplaySubject<IPriceTier>(1);
    }
    this.priceTiers$[price_tier.id].next(price_tier);
  }

  public formatPriceTier(price_tier: IPriceTier): string {
    return this.formatCurrencyAmount(price_tier);
  }

  public formatCurrencyAmount(amount: ICurrencyAmount): string {
    return amount ? this.formatAmount(amount.amount, amount.currency_id) : null;
  }

  public formatAmount(amount: number, currency?: CurrencyEnum): string {
    if (!amount) {
      return '--.00';
    }
    const _currency = currency || CurrencyEnum.usd;
    return currencies[_currency].formatFixed(amount);
  }

  public showPaywall(onSuccess: (setting: IBillingSettings) => void, options: PaywallModalOptions) {
    const _continue = () => {
      const settings = this.settingsSubject.value;
      if (!settings) {
        return;
      }
      const havePlan = !!settings.plan;
      const isExpired = settings.plan?.expires_at?.getTime() < Date.now();
      const isPaid = !!settings.plan?.plan?.price_tier_id;
      const isTrial = settings.plan?.plan?.is_trial;
      if (havePlan && (isPaid || isTrial) && !isExpired) {
        onSuccess(settings);
      } else {
        this._paywallOptions.next(options);
      }
    };

    if (this._settingsLoading$$) {
      const _waiting = setInterval(() => {
        if (!this._settingsLoading$$) {
          clearInterval(_waiting);
          _continue();
        }
      }, 2500);
    } else {
      _continue();
    }
  }

  public closePaywallModal() {
    this._paywallOptions.next(null);
  }

  public getSettings$(force = false): Observable<IBillingSettings> {
    if (!this.auth.me) {
      this.settingsSubject.next(null);
      return this.settings$;
    }
    if (this._settingsLoading$$) {
      return this.settings$;
    }
    if (!this.settingsSubject.value || force) {
      this._settingsLoading$$?.unsubscribe();
      this._settingsLoading$$ = this._getSettings()
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe(
          (settings: IBillingSettings) => {
            this._settingsLoading$$ = null;
            settings.balance = (settings.balance || []).map((balance) => {
              balance.currency = currencies[balance.currency_id];
              return balance;
            });
            if (settings.plan) {
              settings.plan.expires_at = new Date(settings.plan.expires_at);
            }
            (settings.prices || []).forEach((p) => {
              this.definePriceTier$(p);
            });
            // this.store.dispatch(setPrices({ prices: settings.prices }));
            // this.store.dispatch(setPriceTiers({ priceTiers: settings.priceTiers }));
            // this.store.dispatch(setMyCurrency({ currency_id: settings.currency_id }));
            // TODO settings.coupons = (settings.coupons || []).map((coupon) => new AccountCoupon(_coupon));
            this.settingsSubject.next(settings);
          },
          () => {
            this._settingsLoading$$ = null;
            // console.error(err);
          },
        );
    }
    return this.settings$;
  }

  // /** Информация о возможностях оплаты пользователя */
  // public getAccountPaymentInfo(account_id: string): Observable<IAccountPaymentInfo> {
  //   const url = `${environment.account_url}/payment_info/${account_id}`;
  //   return this.http
  //     .get<IAccountPaymentInfo>(url, { observe: 'response' })
  //     .pipe(
  //       map((response) => response.body),
  //     );
  // }

  /** Профиль пользователя для биллинга */
  public getBillingProfile(): Observable<BillingProfile> {
    const url = `${environment.account_url}/billing/${this.version}/profile`;
    return this.http
      .get<IBillingResponse<BillingProfile>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingProfile>(response)));
  }

  public getCards(): Observable<IBillingCard[]> {
    const url = `${billing_url}/${this.version}/cards`;
    return this.http
      .get<IBillingResponse<IBillingCard[]>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingCard[]>(response)));
  }

  private _getSettings(): Observable<IBillingSettings> {
    const url = `${environment.account_url}/billing/${this.version}/settings`;
    return this.http
      .get<IBillingResponse<IBillingSettings>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingSettings>(response)));
  }

  public getMeetingsSettings(): Observable<IBillingMeetingsSettings> {
    const url = `${environment.account_url}/billing/${this.version}/settings/meetings`;
    return this.http
      .get<IBillingResponse<IBillingMeetingsSettings>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingMeetingsSettings>(response)));
  }

  public completeOperation(billing_operation_id: string, billing_card_id?: string): Observable<BillingOperation> {
    const url = `${billing_url}/${this.version}/operation/${billing_operation_id}/complete`;
    return this.http
      .post<IBillingResponse<BillingOperation>>(url, { billing_card_id }, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse(response, (values) => new BillingOperation(values))));
  }

  private handleBillingResponse<T>(response: HttpResponse<IBillingResponse<T>>, transformer?: (arg: any) => any): T {
    if (transformer) {
      return transformer(response.body.payload);
    } else {
      return response.body.payload;
    }
  }

  public switchToPlan(plan_id: string, coupon_id?: string): Observable<BillingOperation> {
    const payload = {
      plan_id,
      coupon_id,
    };

    const url = `${billing_url}/${this.version}/plan/${plan_id}`;
    return this.http
      .post<IBillingResponse<BillingOperation>>(url, payload, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingOperation>(response)));
  }

  public switchToPlanV2(request: SwitchPlanRequest): Observable<SwitchPlanResponse> {
    const url = `${billing_url}/2/plans/switch`;
    return this.http
      .post<SwitchPlanResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new SwitchPlanResponse(response)));
  }

  public activatePlanV2(request: ActivatePlanRequest): Observable<ActivatePlanRequest> {
    const url = `${billing_url}/2/plans/activate`;
    return this.http.post<ActivatePlanRequest>(url, request, { observe: 'body' }).pipe(map((response) => response));
  }

  public cancelPlan(): Observable<IAccountBillingPlan> {
    const url = `${billing_url}/2/plan`;
    return this.http
      .delete<IBillingResponse<IAccountBillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IAccountBillingPlan>(response)));
  }

  public cancelPlanV2(): Observable<CancelPlanEndpoint.Response> {
    const url = `${environment.account_url}/${CancelPlanEndpoint.path}`;
    return this.http.post<CancelPlanEndpoint.Response>(url, {}, { observe: 'body' });
  }

  public getRecommendedPlan(): Observable<IBillingPlan> {
    const url = `${billing_url}/2/plans/recommended`;
    return this.http
      .get<IBillingResponse<IBillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingPlan>(response)));
  }
  public getDefaultPlan(): Observable<IBillingPlan> {
    const url = `${billing_url}/2/plans/default`;
    return this.http
      .get<IBillingResponse<IBillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingPlan>(response)));
  }

  public getActivePlan(): Observable<IAccountBillingPlan> {
    const url = `${billing_url}/2/plans/current`;
    return this.http
      .get<IBillingResponse<IAccountBillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IAccountBillingPlan>(response)));
  }

  public getCurrentPlan(): Observable<AccountPlan | null> {
    const url = `${billing_url}/2/plans/current`;
    return this.http
      .get<IResponse<AccountPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }

  public getExchangeRates(currency_id: CurrencyEnum): Observable<IMeetingPriceTier> {
    const url = `${billing_url}/${this.version}/exchange_rates/${currency_id}`;
    return this.http.get<IMeetingPriceTier>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Информация о курсах относительно валюты */
  public getExchangeRate(from_currency_id: CurrencyEnum, to_currency_id: CurrencyEnum): Observable<IExchangeRate> {
    const url = `${billing_url}/${this.version}/exchange_rate/${from_currency_id}/${to_currency_id}`;
    return this.http.get<IExchangeRate>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** @deprecated use buyCreditsV2 */
  public buyCredits(amount: number): Observable<BillingOperation> {
    const url = `${billing_url}/${this.version}//operations/buy-credits?amount=${amount}`;
    return this.http
      .post<IBillingResponse<BillingOperation>>(url, {}, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingOperation>(response)));
  }

  public buyCreditsV2(request: BuyCreditsEndpoint.Body): Observable<BuyCreditsEndpoint.Response> {
    const url = `${environment.account_url}${BuyCreditsEndpoint.path}`;
    return this.http.post<BuyCreditsEndpoint.Response>(url, request, { observe: 'body' });
  }

  public getUserBalancesForCurrency(currency_id: CurrencyEnum) {
    const url = `${billing_url}/2/account-balance/${currency_id}`;
    return this.http.get<Record<AccountBalanceTypeEnum, number>>(url, { observe: 'body' });
  }

  public getAvailableAmount(currency_id: CurrencyEnum): Observable<number> {
    return this.getUserBalancesForCurrency(currency_id).pipe(
      map((balances) => {
        const wallet = new Wallet(currency_id);
        wallet.initBalances(balances);
        return wallet.getAvailableBalance();
      }),
    );
  }

  /** Plans */
  public getPlans(): Observable<BillingPlan[]> {
    const castBillingPlans = (plans: BillingPlan[]) =>
      plans.map((plan) => {
        const _plan = new BillingPlan(plan);
        if (_plan.priceTier) {
          _plan.priceTier = new CPriceTier(_plan.priceTier);
        }
        return _plan;
      });

    const url = `${billing_url}/2/plans`;
    return this.http.get<BillingPlan[]>(url, { observe: 'body' }).pipe(map(castBillingPlans));
  }

  /** Получить тарифный план с триалом для текущего пользователя */
  public getActualTrialPlan(): Observable<BillingPlan | null> {
    const url = `${billing_url}/2/plans/actual-trial`;
    return this.http
      .get<IResponse<BillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }
}
