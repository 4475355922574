import { Injectable } from '@angular/core';
import { AccountService } from '@app/services/lingo2-account';
import { LessonsService, MeetingsService } from '@app/services/lingo2-content';
import { FilesService, IFileType } from '@app/services/lingo2-files';
import { AccountDetailsType, AnyType, IHash, IImageFile, IPagination, User } from 'lingo2-models';
import { uniq as _uniq, get as _get, set as _set, first as _first } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export type ExtensionType = 'account' | 'meeting' | 'content' | 'images' | 'media';

@Injectable({
  providedIn: 'root',
})
export class EntityExtenderService {
  protected cache: {
    accounts: IHash<User>;
    images: IHash<IHash<IImageFile>>;
  } = {
    accounts: {},
    images: {},
  };

  public constructor(
    private accountService: AccountService,
    private meetingsService: MeetingsService,
    private lessonsService: LessonsService,
    private filesService: FilesService,
  ) {}

  public extendOne$<T>(
    source: T,
    entity: ExtensionType,
    source_key_prop: string,
    extension_prop: string,
    details: string[] = [],
  ): Observable<T> {
    return this.extendMany$([source], entity, source_key_prop, extension_prop, details).pipe(
      map((targets) => (Array.isArray(targets) ? _first(targets) : null)),
    );
  }

  public extendMany$<T>(
    source: T[],
    entity: ExtensionType,
    source_key_prop: string,
    extension_prop: string,
    details: string[] = [],
  ): Observable<T[]> {
    const keys = source.map((element) => _get(element, source_key_prop)) as AnyType as string[];
    const _keys = _uniq(keys.filter((v) => !!v));
    const _details = details as AnyType[];

    switch (entity) {
      case 'account': {
        const _ids = _keys.filter((key) => !this.isAccountCached(key));
        return this.getAccountsById$(_ids, _details).pipe(
          map((accounts: User[]) => {
            this.saveAccountsToCache(accounts);
            const _accounts = [...accounts, ...this.getAccountsFromCache(_keys)];
            return this.listMapper(source, _accounts, source_key_prop, extension_prop, 'id');
          }),
        );
      }

      case 'images': {
        const _ids = _keys.filter((key) => !this.isImageCached(key));
        return this.getImagesById$(_ids).pipe(
          map((images: IHash<IHash<IImageFile>>) => {
            this.saveImagesToCache(images);
            const _images = { ...images, ...this.getImagesFromCache(_keys) };
            return this.hashMapper(source, _images, source_key_prop, extension_prop);
          }),
        );
      }
    }

    return of(source);
  }

  protected listMapper<S, V>(
    source: S[],
    extensions: V[],
    source_key_prop: string,
    extension_prop: string,
    extension_key_prop: string,
  ): S[] {
    return source.map((element) => {
      const _extension = extensions.find((v) => _get(v, extension_key_prop) === _get(element, source_key_prop));
      return _set(element as AnyType, extension_prop, _extension);
    });
  }

  protected hashMapper<S, V>(source: S[], extensions: IHash<V>, source_key_prop: string, extension_prop: string): S[] {
    return source.map((element) => {
      const _extension = extensions[_get(element, source_key_prop)] || null;
      return _set(element as AnyType, extension_prop, _extension);
    });
  }

  protected getAccountsById$(id: string[], details: AccountDetailsType[]): Observable<User[]> {
    if (id.length === 0) {
      return of([]);
    }
    const pagination: IPagination = {
      page: 1,
      pageSize: id.length,
    };
    return this.accountService.findAccounts({ id }, pagination, details).pipe(map((response) => response.results));
  }

  protected getImagesById$(id: string[]): Observable<IHash<IHash<IImageFile>>> {
    if (id.length === 0) {
      return of({});
    }
    const pagination: IPagination = {
      page: 1,
      pageSize: id.length,
    };
    return this.filesService
      .getRecentFiles({ id }, pagination)
      .pipe(map((response) => this.mapFilesToImageHash(response.results)));
  }

  protected mapFilesToImageHash(files: IFileType[]): IHash<IHash<IImageFile>> {
    return files.reduce((hash, file) => {
      hash[file.id] = this.mapFileToImageHash(file);
      return hash;
    }, {});
  }

  protected mapFileToImageHash(file: IFileType): IHash<IImageFile> {
    return file.images.reduce((hash, image) => {
      hash[image.size.toString()] = image;
      return hash;
    }, {});
  }

  protected isAccountCached(id: string): boolean {
    return id in this.cache.accounts;
  }

  protected saveAccountsToCache(accounts: User[]) {
    accounts.forEach((account) => (this.cache.accounts[account.id] = account));
  }

  protected getAccountsFromCache(ids: string[]): User[] {
    return ids.map((id) => this.cache.accounts[id]).filter((v) => !!v);
  }

  protected isImageCached(id: string): boolean {
    return id in this.cache.images;
  }

  protected saveImagesToCache(images: IHash<IHash<IImageFile>>) {
    Object.keys(images).forEach((id) => (this.cache.images[id] = images[id]));
  }

  protected getImagesFromCache(ids: string[]): IHash<IHash<IImageFile>> {
    // return ids.map((id) => this.cache.images[id]).filter((v) => !!v);
    return ids.reduce((carry, id) => {
      if (this.cache.images[id]) {
        carry[id] = this.cache.images[id];
      }
      return carry;
    }, {});
  }
}
