import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createLogger, ILogger } from '@app/models';
import { ErrorService } from '@app/services';
import { environment } from '@environments/environment';
import jwtDecode from 'jwt-decode';
import { User } from 'lingo2-models';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  protected authPrefix = 'Bearer';
  protected logger: ILogger;

  public constructor(private cookie: CookieService, private errorService: ErrorService) {
    this.logger = createLogger('onclass:' + this.constructor.name);
  }

  private get accessToken(): string {
    if (this.mockAccessToken) {
      return this.mockAccessToken;
    }

    const accessToken = this.cookie.get(environment.access_token_key) || '';
    return accessToken.length ? accessToken : null;
  }

  /** Для тестовых целей */
  public get mockAccessToken(): string {
    if (environment.useMocks.auth) {
      return localStorage.getItem('APP_MEET_AUTH');
    }
    return null;
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAuthorizationRequired(request)) {
      const accessToken = this.accessToken;
      if (accessToken) {
        const user = this.parseUserFromAccessToken(accessToken);
        request = request.clone({
          setHeaders: {
            'X-User': user ? `${user.slug}/${user.id}` : 'none', // TODO !production || debug
            Authorization: `${this.authPrefix} ${accessToken}`,
          },
        });
      }
    }

    return next.handle(request);
  }

  private isAuthorizationRequired(request: HttpRequest<any>): boolean {
    if (request.url.indexOf('.json') >= 0) {
      return false;
    }

    return true;
  }

  private parseUserFromAccessToken(token: string): User {
    try {
      const token_decoded = jwtDecode<any>(token);
      if (!token_decoded.subject || !token_decoded.subject.id) {
        this.logger.error('AuthInterceptor:parseUserFromAccessToken', 'token is wrong', token);
      }
      return new User(token_decoded.subject);
    } catch (e) {
      this.errorService.err(e);
      this.logger.error('AuthInterceptor:parseUserFromAccessToken', e);
    }
  }
}
