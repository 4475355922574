import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { createPagedResults } from '@app/services/helpers/create-paged-results';
import {
  IPagedResults,
  Vocabulary,
  IVocabularyStudyOptions,
  IVocabularyStudyResults,
  VocabularyTerm,
  IPagination,
  IFindVocabularyTerm,
  IFindVocabularyList,
  VocabularyList,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VocabularyService {
  public term_language_id: number;
  public definition_language_id: number;
  public term_language_name: string;
  public definition_language_name: string;

  public constructor(private http: HttpClient) {}

  /**
   * Создать словарь
   *
   * @param values
   */
  public createVocabulary(values: Partial<Vocabulary>): Observable<Vocabulary> {
    const url = `${environment.content_url}/vocabulary/`;
    return this.http.post<Vocabulary>(url, values, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить словарь
   *
   * @param id
   * @param values
   */
  public updateVocabulary(id: string, values: Partial<Vocabulary>): Observable<Vocabulary> {
    const url = `${environment.content_url}/vocabulary/${id}`;
    return this.http.put<Vocabulary>(url, values, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  public getVocabularyOptions(id: string): Observable<IVocabularyStudyOptions> {
    const url = `${environment.content_url}/vocabulary/${id}/options`;
    return this.http.get<IVocabularyStudyOptions>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public setVocabularyOptions(id: string, options: IVocabularyStudyOptions): Observable<IVocabularyStudyOptions> {
    const url = `${environment.content_url}/vocabulary/${id}/options`;
    return this.http.post<IVocabularyStudyOptions>(url, options, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getVocabularyStudyResults(id: string): Observable<IVocabularyStudyResults> {
    const url = `${environment.content_url}/vocabulary/${id}/results`;
    return this.http.get<IVocabularyStudyResults>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public findVocabularyTerms(filter: Partial<IFindVocabularyTerm>, pagination: IPagination) {
    const url = `${environment.content_url}/vocabulary/terms`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http
      .get<VocabularyTerm[]>(url, { params, observe: 'response' })
      .pipe(map(this.handleVocabularyTermsResponse));
  }

  public createVocabularyTerm(term: Partial<VocabularyTerm>) {
    const url = `${environment.content_url}/vocabulary/terms`;
    return this.http.post<VocabularyTerm>(url, term, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public updateVocabularyTerm(id: string, term: Partial<VocabularyTerm>) {
    const url = `${environment.content_url}/vocabulary/terms/${id}`;
    return this.http.put<VocabularyTerm>(url, term, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public bulkUpdateVocabularyTerms(terms: Array<Partial<VocabularyTerm>>) {
    const url = `${environment.content_url}/vocabulary/terms/bulk/`;
    return this.http.post<VocabularyTerm>(url, terms, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public deleteVocabularyTerm(id: string) {
    const url = `${environment.content_url}/vocabulary/terms/${id}`;
    return this.http.delete<VocabularyTerm>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public bulkDeleteVocabularyTerm(ids: string[]) {
    const url = `${environment.content_url}/vocabulary/terms`;
    return this.http.delete<VocabularyTerm>(url, { observe: 'response', body: ids }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getVocabularies(filter?: Partial<IFindVocabularyTerm>) {
    const url = `${environment.content_url}/vocabulary/vocabularies`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<Array<Partial<VocabularyTerm>>>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public findVocabularyLists(filter?: Partial<IFindVocabularyList>, pagination?: IPagination) {
    const url = `${environment.content_url}/vocabulary/lists`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http.get<VocabularyList[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleVocabularyListsResponse),
      // catchError(this.handleError),
    );
  }

  public createVocabularyList(list: Partial<VocabularyList>) {
    const url = `${environment.content_url}/vocabulary/lists`;
    return this.http.post<VocabularyList>(url, list, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public updateVocabularyList(id: string, list: Partial<VocabularyList>) {
    const url = `${environment.content_url}/vocabulary/lists/${id}`;
    return this.http.put<VocabularyList>(url, list, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public deleteVocabularyList(id: string) {
    const url = `${environment.content_url}/vocabulary/lists/${id}`;
    return this.http.delete<VocabularyList>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public duplicateVocabularyList(id: string) {
    const url = `${environment.content_url}/vocabulary/lists/${id}/clone`;
    return this.http.post<VocabularyList>(url, {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  private handleItemResponse(response: HttpResponse<Vocabulary>): Vocabulary {
    return new Vocabulary(response.body);
  }

  private handleVocabularyTermsResponse(response: HttpResponse<VocabularyTerm[]>): IPagedResults<VocabularyTerm[]> {
    return createPagedResults(response, (values) => new VocabularyTerm(values));
  }

  private handleVocabularyListsResponse(response: HttpResponse<VocabularyList[]>): IPagedResults<VocabularyList[]> {
    return createPagedResults(response, (value) => new VocabularyList(value));
  }
}
