import { v4 } from 'uuid';

export function UUIDv4(): string {
  return v4();
}

export const regexGuid = /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i;

export function isUUID(value: string) {
  return regexGuid.test(value);
}

function pad2(num: number) {
  return [num < 10 ? '0' : '', num].join('');
}

export function HhMmSs(time: number): { hours: number; minutes: number; seconds: number } {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);
  return { hours, minutes, seconds };
}

export function formatHhMmSs(time: number): string {
  const _time = HhMmSs(Math.abs(time));
  const parts = [];
  if (_time.hours > 0) {
    parts.push(pad2(_time.hours));
  }
  if (_time.minutes) {
    parts.push(pad2(_time.minutes));
  }
  parts.push(pad2(_time.seconds));

  return parts.join(':');
}

export function getComputedStyleProperty(
  element: HTMLElement,
  property: string,
  format?: 'integer' | 'string',
): number | string {
  const value = window.getComputedStyle(element, null).getPropertyValue(property);
  switch (format) {
    case 'integer':
      return parseInt(value, 10) || 0;

    case 'string':
    default:
      return value.toString();
  }
}

const rootTagName = 'app-root';

export function getParentNodes(element: HTMLElement): HTMLElement[] {
  if (!element || !(element instanceof HTMLElement || SVGPathElement)) {
    return [];
  }

  let _node = element;
  const parents: HTMLElement[] = [];
  parents.push(_node);
  while (_node.parentElement) {
    parents.unshift(_node.parentElement);
    _node = _node.parentElement;
    if (_node.nodeName === rootTagName) {
      break;
    }
  }
  return parents;
}

export function isChildOfNode(element: HTMLElement, parentTagName: string): boolean {
  const parents = getParentNodes(element);
  const _parentTagName = parentTagName.toLowerCase();
  return parents.some((e) => e.nodeName.toLowerCase() === _parentTagName);
}
