import { Injectable, OnDestroy } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { ClientParticipant } from '@app/models/participant';
import stringify from 'fast-json-stable-stringify';
import {
  MediaAccessEnum,
  ParticipantAccessOptions,
  ParticipantOptions,
  ParticipantRoleEnum,
  ParticipantStatusEnum,
  ParticipantTroubleEnum,
} from 'lingo2-conference-models';
import { IHash, User } from 'lingo2-models';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

export interface IParticipantsFilter {
  role: ParticipantRoleEnum[];
  status: ParticipantStatusEnum[];
}

/** Хранилище информации об участниках в текущем проводимом или наблюдаемом митинге */
@Injectable({
  providedIn: 'root',
})
export class ParticipantsState extends AbstractService implements OnDestroy {
  public meAsParticipant$: Observable<ClientParticipant>;
  public participants$: Observable<ClientParticipant[]>;
  public participantsChanged$: Observable<boolean>;

  /** ID участников (participant.user_id) для которых необходимо получить информацию о пользователях (имя и аватара) */
  public userRequiredQueue$ = this.register(new Subject<string>());

  protected _participants: IHash<ClientParticipant> = {};
  protected _participantsSubjects: IHash<BehaviorSubject<ClientParticipant>> = {};
  protected meAsParticipant = this.register(new ReplaySubject<ClientParticipant>(1));
  protected meAsParticipant$$: Subscription;
  protected meId: string;
  protected participants = this.register(new BehaviorSubject<ClientParticipant[]>(Object.values(this._participants)));
  protected participantsChanged = this.register(new ReplaySubject<boolean>(1));

  public constructor() {
    super();
    this.participantsChanged$ = this.participantsChanged.asObservable();
    this.participants$ = this.participants.asObservable();
    this.meAsParticipant$ = this.meAsParticipant.asObservable();
  }

  public addParticipant(participant: Partial<ClientParticipant>) {
    if (!participant) {
      return;
    }

    const user_id = participant.user_id;
    if (user_id in this._participants) {
      return this.updateParticipant(participant);
    }

    const _p = new ClientParticipant(participant);
    this._participants[user_id] = _p;
    this._participantsSubjects[user_id] = this.register(new BehaviorSubject<ClientParticipant>(_p));
    this.participants.next(Object.values(this._participants));
    this.participantsChanged.next(true);

    this.userRequiredQueue$.next(_p.user_id);
  }

  public filterParticipants$(_filter: Partial<IParticipantsFilter>): Observable<ClientParticipant[]> {
    return this.participants$.pipe(
      map((participants) =>
        (participants || []).filter((p) => {
          let matched = true;
          if (_filter.role && !_filter.role.includes(p.options?.role_override || p.role)) {
            matched = false;
          }
          if (_filter.status && !_filter.status.includes(p.status)) {
            matched = false;
          }
          return matched;
        }),
      ),
    );
  }

  public getParticipant$(id: string): Observable<ClientParticipant> {
    return this.getParticipant(id).asObservable();
  }

  public init(meId: string) {
    if (this.meId !== meId) {
      this.meId = meId;
      if (this.meAsParticipant$$) {
        this.meAsParticipant$$.unsubscribe();
      }

      // трансляция из общего массива Observable участников в конкретный один Observable
      // необходимо для того, чтобы у подписчиков на this.meAsParticipant$ не происходил сбой
      // после авторизации при смене this.meId из null в конкретное значение
      this.meAsParticipant$$ = this.getParticipant(this.meId)
        .pipe(
          tap((participant) => this.meAsParticipant.next(participant)),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }
  }

  public ngOnDestroy() {
    Object.values(this._participants).map((p) => p.ngOnDestroy());
    super.ngOnDestroy();
  }

  public updateParticipant(participant: Partial<ClientParticipant>) {
    if (!participant) {
      return;
    }

    const user_id = participant.user_id;
    if (!(user_id in this._participants)) {
      return this.addParticipant(participant);
    }

    const _p = this.applyValues(this._participants[user_id], participant, [
      'role',
      'status',
      'trouble',
      'media_access',
      'access',
      'options',
      'slug',
      'first_name',
      'last_name',
      'userpic',
    ]);
    this._participants[user_id] = _p;
    this._participantsSubjects[user_id].next(_p);
    this.participants.next(Object.values(this._participants));
    this.participantsChanged.next(true);

    if (!_p.userApplied) {
      this.userRequiredQueue$.next(_p.user_id);
    }
  }

  /** Изменить настройки прав доступа */
  public updateParticipantAccess(user_id: string, access: Partial<ParticipantAccessOptions>) {
    const _participant = this.getParticipant(user_id);
    const participant = _participant.value;

    const _pAccess = stringify(participant.access);
    const _access = stringify(access);

    if (_pAccess !== _access) {
      this.updateParticipant({ user_id: participant.user_id, access });
    }
  }

  public updateParticipantFromUser(user: Partial<User>) {
    if (!user) {
      return;
    }

    const user_id = user.id;
    if (!this._participants[user_id]) {
      return;
    }

    const values: Partial<ClientParticipant> = {
      slug: user.slug,
      first_name: user.first_name,
      last_name: user.last_name,
      userpic: user.userpic,
    };
    const _p = this.applyValues(this._participants[user_id], values, ['slug', 'first_name', 'last_name', 'userpic']);
    this._participants[user_id] = _p;
    this._participantsSubjects[user_id].next(_p);
    this.participants.next(Object.values(this._participants));
    this.participantsChanged.next(true);
  }

  public updateParticipantMediaAccess(user_id: string, media_access: MediaAccessEnum) {
    const _participant = this.getParticipant(user_id);
    const participant = _participant.value;

    if (participant.media_access !== media_access) {
      this.updateParticipant({ user_id: participant.user_id, media_access });
    }
  }

  public updateParticipantOptions(user_id: string, options: Partial<ParticipantOptions>) {
    const _participant = this.getParticipant(user_id);
    const participant = _participant.value;

    const _pOptions = stringify(participant.options);
    const _options = stringify(options);

    if (_pOptions !== _options) {
      this.updateParticipant({ user_id: participant.user_id, options });
    }
  }

  public updateParticipantStatus(user_id: string, status: ParticipantStatusEnum, trouble: ParticipantTroubleEnum) {
    if (user_id === this.meId) {
      return; // игнорировать смену своего собственного статуса
    }

    const _participant = this.getParticipant(user_id);
    const participant = _participant.value;

    if (participant.status !== status || participant.trouble !== trouble) {
      this.updateParticipant({ user_id: participant.user_id, status, trouble });
    }
  }

  // ----------

  protected applyValues(
    participant: ClientParticipant,
    values: Partial<ClientParticipant>,
    allowed: Array<keyof ClientParticipant>,
  ): ClientParticipant {
    allowed.map((f) => {
      if (values[f] !== undefined && values[f] !== null) {
        participant.setValue(f, values[f]);
      }
    });

    participant.changed$.next(true);
    return participant;
  }

  protected getParticipant(user_id: string): BehaviorSubject<ClientParticipant> {
    if (!(user_id in this._participants)) {
      const participant = new ClientParticipant({
        user_id,
        role: null,
      });
      this.addParticipant(participant);
    }
    return this._participantsSubjects[user_id];
  }
}
