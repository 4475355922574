import { Injectable } from '@angular/core';
import { MediaWizardScreen } from '@app/components/video-conference/media-wizard/models';
import { AbstractService } from '@app/models/abstract.service';
import { ApplicationStateEnum } from '@app/models/application';
import { ClientBoard } from '@app/models/board';
import { MeetingFormOptions } from '@app/models/meeting';
import { ClientParticipant } from '@app/models/participant';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @todo
 *
 * Оригинальный текст описания класса говорит, что это хранилище. Но это не так.
 * Класс представляет из себя монстра-управленца. Почти все методы класса
 * - вызовы других компонент. Хранилище так не делает. Нужно полностью
 * обновить его логику и заставить его работать как хранилище.
 * Максимум - пересчёт значений, но не более.
 *
 * Хранилище информации о клиентском приложении
 * - Состояние загрузки приложения
 * - Режимы работы приложения
 * - Видимость (активность) диалогов
 */
@Injectable({
  providedIn: 'root',
})
export class ApplicationState extends AbstractService {
  /** Признак, что инициализация приложения завершена, можно убирать splash и спиннеры */
  public loaded$: Observable<boolean>;

  /** Код стадии инициализации приложения */
  public state$: Observable<ApplicationStateEnum>;

  /** Режим слежения за преподавателем */
  public trackTeacherEnabled$: Observable<boolean>;

  /** Видимость диалога 'Карточка митинга' */
  public meetingCardVisible$: Observable<boolean>;

  /** Настройки формы редактирования митинга */
  public meetingFormOptions$: Observable<MeetingFormOptions>;

  /** Видимость диалога 'Форма настройки митинга' */
  public meetingFormVisible$: Observable<boolean>;

  /** Видимость диалога 'Продление митинга' */
  public prolongDialogVisible$: Observable<boolean>;

  /** Видимость панели 'История занятий' */
  public meetingsHistoryVisible$: Observable<boolean>;

  /** Видимость диалога 'Отзыв о занятии' */
  public meetingReviewVisible$: Observable<boolean>;

  /** Видимость панели 'Индивидуальные занятия' */
  public individualPanelVisible$: Observable<boolean>;

  /** Видимость панели 'Домашние занятия' */
  public homeworkPanelVisible$: Observable<boolean>;

  /** Видимость диалога 'Приглашение на митинг'*/
  public inviteToMeetingVisible$: Observable<boolean>;

  /** Видимость диалога 'Выбор языка'*/
  public languageSelectorVisible$: Observable<boolean>;

  /** Видимость диалога 'Выбор урока для доски'*/
  public lessonSelectorVisible$: Observable<boolean>;

  /** Пользователь, чьё задание сейчас просматривается преподавателем */
  public taskParticipant$: Observable<ClientParticipant>;

  /** Доска (задание), которое сейчас просматривается преподавателем */
  public taskBoard$: Observable<ClientBoard>;

  /** Экран диалога настроек доступа к медиа-устройствам и выбора медиа-устройств */
  public mediaWizardScreen$: Observable<boolean | MediaWizardScreen>;

  /** Экстренное завершение работы */
  public forceDisconnected$: Observable<boolean>;

  public debug$ = this.register(new BehaviorSubject<boolean>(false));

  protected state = this.register(new ReplaySubject<ApplicationStateEnum>(1));
  protected loaded = this.register(new ReplaySubject<boolean>(1));
  protected trackTeacherEnabled = this.register(new BehaviorSubject<boolean>(false));
  protected meetingCardVisible = this.register(new BehaviorSubject<boolean>(false));
  protected meetingFormOptions = this.register(new BehaviorSubject<MeetingFormOptions>(null));
  protected prolongDialogVisible = this.register(new BehaviorSubject<boolean>(false));
  protected meetingsHistoryVisible = this.register(new BehaviorSubject<boolean>(false));
  protected meetingReviewVisible = this.register(new BehaviorSubject<boolean>(false));
  protected individualPanelVisible = this.register(new BehaviorSubject<boolean>(false));
  protected homeworkPanelVisible = this.register(new BehaviorSubject<boolean>(false));
  protected inviteToMeetingVisible = this.register(new BehaviorSubject<boolean>(false));
  protected languageSelectorVisible = this.register(new BehaviorSubject<boolean>(false));
  protected lessonSelectorVisible = this.register(new BehaviorSubject<boolean>(false));
  protected taskParticipant = this.register(new BehaviorSubject<ClientParticipant>(null));
  protected taskBoard = this.register(new BehaviorSubject<ClientBoard>(null));
  protected mediaWizardScreen = this.register(new BehaviorSubject<boolean | MediaWizardScreen>(false));
  protected forceDisconnected = this.register(new ReplaySubject<boolean>(1));

  public constructor() {
    super();
    this.loaded$ = this.loaded.asObservable();
    this.state$ = this.state.asObservable();
    this.trackTeacherEnabled$ = this.trackTeacherEnabled.asObservable();
    this.meetingCardVisible$ = this.meetingCardVisible.asObservable();
    this.meetingFormOptions$ = this.meetingFormOptions.asObservable();
    this.meetingFormVisible$ = this.meetingFormOptions.pipe(map((options) => !!options));
    this.prolongDialogVisible$ = this.prolongDialogVisible.asObservable();
    this.meetingsHistoryVisible$ = this.meetingsHistoryVisible.asObservable();
    this.meetingReviewVisible$ = this.meetingReviewVisible.asObservable();
    this.individualPanelVisible$ = this.individualPanelVisible.asObservable();
    this.homeworkPanelVisible$ = this.homeworkPanelVisible.asObservable();
    this.inviteToMeetingVisible$ = this.inviteToMeetingVisible.asObservable();
    this.languageSelectorVisible$ = this.languageSelectorVisible.asObservable();
    this.lessonSelectorVisible$ = this.lessonSelectorVisible.asObservable();
    this.taskParticipant$ = this.taskParticipant.asObservable();
    this.taskBoard$ = this.taskBoard.asObservable();
    this.mediaWizardScreen$ = this.mediaWizardScreen.asObservable();
    this.forceDisconnected$ = this.forceDisconnected.asObservable();
  }

  public get isTrackTeacherEnabled(): boolean {
    return this.trackTeacherEnabled.value;
  }

  public setState(state: ApplicationStateEnum) {
    this.state.next(state);
  }

  public setLoaded(loaded: boolean) {
    this.loaded.next(loaded);
  }

  public enableTrackTeacher() {
    this.trackTeacherEnabled.next(true);
  }

  public disableTrackTeacher() {
    this.trackTeacherEnabled.next(false);
  }

  public openMeetingCard() {
    this.meetingCardVisible.next(true);
  }

  public closeMeetingCard() {
    this.meetingCardVisible.next(false);
  }

  public openMeetingForm(options: MeetingFormOptions) {
    this.meetingFormOptions.next(options);
  }

  public closeMeetingForm() {
    this.meetingFormOptions.next(null);
  }

  public openProlongDialog() {
    this.prolongDialogVisible.next(true);
  }

  public closeProlongDialog() {
    this.prolongDialogVisible.next(false);
  }

  public openMeetingsHistory() {
    this.meetingsHistoryVisible.next(true);
  }

  public closeMeetingsHistory() {
    this.meetingsHistoryVisible.next(false);
  }

  public openMeetingReview() {
    this.meetingReviewVisible.next(true);
  }

  public closeMeetingReview() {
    this.meetingReviewVisible.next(false);
  }

  public openIndividualPanel() {
    this.individualPanelVisible.next(true);
  }

  public closeIndividualPanel() {
    this.individualPanelVisible.next(false);
  }

  public openHomeworkPanel() {
    this.homeworkPanelVisible.next(true);
  }

  public closeHomeworkPanel() {
    this.homeworkPanelVisible.next(false);
  }

  public openInviteToMeeting() {
    this.inviteToMeetingVisible.next(true);
  }

  public closeInviteToMeeting() {
    this.inviteToMeetingVisible.next(false);
  }

  public openLanguageSelector() {
    this.languageSelectorVisible.next(true);
  }

  public closeLanguageSelector() {
    this.languageSelectorVisible.next(false);
  }

  public openLessonSelector() {
    this.lessonSelectorVisible.next(true);
  }

  public closeLessonSelector() {
    this.lessonSelectorVisible.next(false);
  }

  public setTaskParticipant(participant: ClientParticipant) {
    this.taskParticipant.next(participant);
  }

  public setTaskBoard(board: ClientBoard) {
    this.taskBoard.next(board);
  }

  public openMediaWizard(screen?: true | MediaWizardScreen) {
    this.mediaWizardScreen.next(screen || true);
  }

  public closeMediaWizard() {
    this.mediaWizardScreen.next(false);
  }

  public forceDisconnect() {
    this.forceDisconnected.next(true);
  }

  public closeAllModals() {
    this.closeLessonSelector();
    this.closeLanguageSelector();
    this.closeInviteToMeeting();
    // this.closeMeetingReview();
    this.closeMeetingsHistory();
    this.closeProlongDialog();
    this.closeMeetingForm();
    this.closeMeetingCard();
  }
}
