import { Injectable, OnDestroy } from '@angular/core';
import { createLogger, ILogger } from '@app/models/logger';
import { Observable, Subject } from 'rxjs';

export interface ErrorEntry {
  message: string;
  stack?: string;
  details?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService /* нельзя! extends AbstractService */ implements OnDestroy {
  public error$: Observable<ErrorEntry>;

  protected error = new Subject<ErrorEntry>();
  protected logger: ILogger;

  public constructor() {
    this.logger = createLogger('onclass:' + this.constructor.name);
    this.error$ = this.error.asObservable();
  }

  protected get backtrace(): string {
    const [t, , , ...stack] = new Error().stack.split(/\n/);
    return [t, ...stack].join('\n');
  }

  public ngOnDestroy(): void {
    this.error.complete();
  }

  public err(error: Error) {
    this.logger.error(error);
    if (!!error.stack) {
      this.error.next({
        message: error.message,
        stack: error.stack,
        details: error,
      });

      // StackTrace.fromError(error)
      //   .then((stack) => {
      //     this.error.next({
      //       message: error.message,
      //       stack,
      //       details: error,
      //     })
      //   })
      //   .catch(console.error);
    } else {
      this.error.next({
        message: error.message,
        stack: this.backtrace,
        details: error,
      });

      // StackTrace.get()
      //   .then((stack) => {
      //     this.error.next({
      //       message: error.message,
      //       stack,
      //       details: error,
      //     });
      //   })
      //   .catch(console.error);
    }
  }

  public log(message: string, details?: any) {
    this.error.next({
      message,
      stack: this.backtrace,
      details,
    });

    // StackTrace.get()
    //   .then((stack) => {
    //     this.error.next({
    //       message,
    //       stack,
    //       details,
    //     });
    //   });
  }
}
