import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AbstractService, HttpStatuses, NotFoundError } from '@app/models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor extends AbstractService implements HttpInterceptor {
  constructor(protected inject: Injector) {
    super(inject);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          this.errorService.err(error);
          // this.logger.debug(request.method + ' ' + request.urlWithParams, error);
          switch (error.status) {
            // Обычно если не известен хост сервера или порт не отвечает
            case 0: // UnknownError
              throw new NotFoundError({ ...error });

            // Сервер выдал ошибку 'Page Not Found'
            case HttpStatuses.NotFoundError:
              throw new NotFoundError({ ...error });

            // Сервер не смог выдать ответ из-за внутренних ошибок
            case HttpStatuses.BadGatewayError:
            case HttpStatuses.GatewayTimeoutError:
            case HttpStatuses.InternalServerError:
              throw new NotFoundError({ ...error });

            default:
              break;
          }
        }
        return throwError(error);
      }),
    );
  }
}
