import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@app/models/abstract.service';
import { environment } from '@environments/environment';
import { MeetingReview } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommentService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
    // this.logger.debug('CommentService:constructor');
  }

  /** Оставить оценку митингу */
  public createMeetingReview(meeting_id, review: Partial<MeetingReview>): Observable<boolean> {
    const url = `${environment.comment_url}/review/meeting/${meeting_id}`;
    return this.http.post<boolean>(url, review, { observe: 'response' }).pipe(
      map((response) => response.body as any),
      // catchError(this.handleError),
    );
  }

  /** Получить мою оценку за митинг */
  public getMyMeetingReview(meeting_id: string): Observable<Partial<MeetingReview>> {
    const url = `${environment.comment_url}/review/meeting/${meeting_id}`;
    return this.http.get<Partial<MeetingReview>>(url, { observe: 'response' }).pipe(
      map((response) => response.body as any),
      // catchError(this.handleError),
    );
  }
}
