import { environmentDefaults } from './enviroment.default';
import { IProductionEnviroment } from './IEnvironment';

/*
# CDN забирает данные с meet.onclass.com для раздачи через cdn-meet.onclass.com
# see https://selfcare.cdnnow.ru/projects/42689582-e9b5-42b7-a0ed-4a4c26a9efdb
#
# see https://www.reg.ru/user/account/#/card/55095507/nss
#   CNAME cdn-meet → user38499.clients-cdnnow.ru
*/
const ASSETS_HOST = 'cdn-meet.onclass.com';

const I18N_HOST = 'i.onclass.com';

/** Конфиг для meet.onclass.com */
export const environment: IProductionEnviroment = {
  ...environmentDefaults,
  env: 'production',
  production: true,
  logLevel: 'error',
  globalErrorHandler: true,
  debugMode: false,

  portal_url: 'https://onclass.com',
  auth_url: 'https://b.onclass.com/web/auth',
  account_url: 'https://b.onclass.com/web/account',
  content_url: 'https://b.onclass.com/web/content',
  comment_url: 'https://b.onclass.com/web/comments',
  meetings_url: 'https://meet.onclass.tech',
  files_url: 'https://b.onclass.com/web/files',
  messaging_url: 'https://b.onclass.com/web/messaging',
  schedule_url: 'https://b.onclass.com/web/account/schedule',
  chat_url: 'https://b.onclass.com/web/chat',
  ws_url: 'wss://b.onclass.com/ws',
  stripe_iframe_host: 'https://b.onclass.com/web/billing',
  billing_public_url: 'https://b.onclass.com/web/billing',

  i18n_url: `//${I18N_HOST}/i18n`,
  i18n_en_url: `//${I18N_HOST}/i18n`,
  is_i18n_developer: false,

  webSocketUrl: 'wss://b.onclass.com/ws',
  chatEndpoint: 'https://b.onclass.com/',

  features: {
    ...environmentDefaults.features,
    chat: true,
    homeworks: false,
    individualTasks: false,
    replayHistory: false,
    meetingsHistory: false,
    vocabulary: true,
  },

  assets_url: `//${ASSETS_HOST}`,
  sendbird_app_id: 'BAE9A789-C6DC-4365-B9F3-7AB63DC5DC9F',
};
