import { Directive, Injector } from '@angular/core';
import { BoardsState } from '@app/services/boards.state';
import { MeetingState } from '@app/services/meeting.state';
import { ParticipantsState } from '@app/services/participants.state';
import { BoardsetEnum, MeetingRuntimeStatusEnum } from 'lingo2-conference-models';
import { Meeting as ContenttMeeting } from 'lingo2-models';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AbstractComponent } from './abstract.component';
import { ClientBoard } from './board';
import { ClientMeeting, ClientMeetingSession } from './meeting';
import { ClientParticipant } from './participant';

/** Абстрактная компонента для компонт, которым нужен текущий контекст митинга */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractMeetingComponent extends AbstractComponent {
  protected meetingSession: ClientMeetingSession;
  protected meeting: ClientMeeting;
  protected overrideMeeting: ClientMeeting;
  protected contentMeeting: ContenttMeeting;
  protected overrideContentMeeting: ContenttMeeting;
  protected me: ClientParticipant;
  protected activeBoardId: string;
  protected teacherActiveBoardId: string;
  protected boards: ClientBoard[] = [];
  protected activeBoard: ClientBoard;
  protected boardset: BoardsetEnum;
  protected runtimeStatus: MeetingRuntimeStatusEnum;

  protected participantsState: ParticipantsState;
  protected meetingState: MeetingState;
  protected boardsState: BoardsState;

  protected constructor(protected inject: Injector) {
    super(inject);

    this.participantsState = this.inject.get(ParticipantsState);
    this.meetingState = this.inject.get(MeetingState);
    this.boardsState = this.inject.get(BoardsState);
  }

  /** Детальное состояние митинга */
  protected get watchMeetingSession$(): Observable<ClientMeetingSession> {
    if (!this.meetingState) {
      return of(null);
    }
    return this.meetingState.meetingSession$.pipe(tap((session) => (this.meetingSession = session)));
  }

  /** Детальное состояние митинга */
  protected get watchMeeting$(): Observable<ClientMeeting> {
    if (!this.meetingState) {
      return of(null);
    }
    return this.meetingState.meeting$.pipe(tap((meeting) => (this.meeting = meeting)));
  }

  /** Краткое состояние митинга */
  protected get watchRuntimeStatus$(): Observable<MeetingRuntimeStatusEnum> {
    if (!this.meetingState) {
      return of(null);
    }
    return this.meetingState.runtimeStatus$.pipe(tap((runtimeStatus) => (this.runtimeStatus = runtimeStatus)));
  }

  /** Митинг "из истории" */
  protected get watchOverrideMeeting$(): Observable<ClientMeeting> {
    if (!this.meetingState) {
      return of(null);
    }
    return this.meetingState.overrideMeeting$.pipe(tap((meeting) => (this.overrideMeeting = meeting)));
  }

  /** Полное описание митинга */
  protected get watchContentMeeting$(): Observable<ContenttMeeting> {
    return this.meetingState.contentMeeting$.pipe(tap((meeting) => (this.contentMeeting = meeting)));
  }

  /** Полное описание митинга "из истории" */
  protected get watchOverrideContentMeeting$(): Observable<ContenttMeeting> {
    return this.meetingState.overrideContentMeeting$.pipe(tap((meeting) => (this.overrideContentMeeting = meeting)));
  }

  /** Текущий участник */
  protected get watchMyParticipant$(): Observable<ClientParticipant> {
    if (!this.participantsState) {
      return of(null);
    }
    return this.participantsState.meAsParticipant$.pipe(tap((me) => (this.me = me)));
  }

  /** Слежение только за board_id текущей доски */
  protected get watchActiveBoardId$(): Observable<string> {
    if (!this.boardsState) {
      return of(null);
    }
    return this.boardsState.activeBoardId$.pipe(tap((id) => (this.activeBoardId = id)));
  }

  /** Слежение за board_id доски, где находится преподаватель */
  protected get watchTeacherActiveBoardId$(): Observable<string> {
    if (!this.boardsState) {
      return of(null);
    }
    return this.boardsState.teacherActiveBoardId$.pipe(tap((id) => (this.teacherActiveBoardId = id)));
  }

  /** Слежение только за текущей доской */
  protected get watchActiveBoard$(): Observable<ClientBoard> {
    if (!this.boardsState) {
      return of(null);
    }
    return combineLatest([this.boardsState.boards$, this.boardsState.activeBoardId$]).pipe(
      tap(([boards, activeBoardId]) => {
        this.boards = boards;
        this.activeBoardId = activeBoardId;
        this.activeBoard = boards.find((b) => b.board_id === activeBoardId);
      }),
      switchMap(() => of(this.activeBoard)),
    );
  }

  /** Слежение за списком досок */
  protected get watchBoards$() {
    if (!this.boardsState) {
      return of(null);
    }
    return this.boardsState.boards$.pipe(tap((boards) => (this.boards = boards)));
  }

  /**
   * Слежение за активным типом досок
   * для определения типа активной доски используй watchActiveBoard$
   *
   * @see watchActiveBoard$()
   */
  protected get watchActiveBoardsetType$() {
    if (!this.boardsState) {
      return of(null);
    }
    return this.boardsState.activeBoardsetType$.pipe(tap((boardset) => (this.boardset = boardset)));
  }
}
