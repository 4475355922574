import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationStateEnum } from '@app/models';
import { AbstractService } from '@app/models/abstract.service';
import { ApplicationState } from '@app/services/application.state';
import { RequestService } from '@app/services/request.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { addDays } from 'date-fns';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import it from 'date-fns/locale/it';
import ru from 'date-fns/locale/ru';
import { AbstractLanguageService } from 'lingo2-forms';
import { Language } from 'lingo2-models';
import { DateFnsConfigurationService } from 'lingo2-ngx-date-fns';
import { CookieService } from 'ngx-cookie-service';
import { catchError, Observable, ReplaySubject, of, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends AbstractService implements AbstractLanguageService {
  public language$: Observable<Language>;
  public languages$: Observable<Language[]>;

  private _languages: Language[] = [];
  private languages = new ReplaySubject<Language[]>(1);
  private language = new ReplaySubject<Language>(1);
  private lang_cookie_key = 'APP_LANG_MEET';

  public constructor(
    protected applicationState: ApplicationState,
    protected cookieService: CookieService,
    protected translateService: TranslateService,
    protected dateFnsConfig: DateFnsConfigurationService,
    protected location: Location,
    protected http: HttpClient,
    protected requestService: RequestService,
  ) {
    super();

    this.language$ = this.language.asObservable();
    this.languages$ = this.languages.asObservable();
  }

  protected get cookieLanguage(): Language {
    const code = this.cookieService.get(this.lang_cookie_key);
    return code ? (this._languages || []).find((lang) => lang.code === code) : null;
  }

  protected set cookieLanguage(language: Language) {
    if (language) {
      this.cookieService.delete(this.lang_cookie_key);
      this.cookieService.set(this.lang_cookie_key, language.code, {
        expires: addDays(new Date(), 30),
        path: '/',
      });
    } else {
      this.cookieService.delete(this.lang_cookie_key);
    }
  }

  public loadUiLanguages$(): Observable<Language[]> {
    return this.getUiLanguages(environment.default_language, this.requestService.host).pipe(
      tap((languages) => this.setUiLanguages(languages)),
      catchError((err) => {
        this.logger.error(err);
        this.applicationState.setState(ApplicationStateEnum.StateI18nErrUiLocales);
        // fallback: доступен как минимум английский язык
        this.setUiLanguages([
          new Language({
            id: 1, // Аааа! ХардКод!
            code: environment.default_language,
            title: environment.default_language,
            url: environment.portal_url,
          }),
        ]);
        return of(null);
      }),
    );
  }

  public setUiLanguage(userLanguageCode: string) {
    const language =
      this.cookieLanguage || // 1. язык в куках
      this.getLanguageByCode(userLanguageCode) || // 2. язык в настройках пользователя
      this.getLanguageByCode(environment.default_language); // 3. язык по-умолчанию
    this.updateLanguage(language);
  }

  public updateLanguage(language: Language) {
    const languageCode = language.code;
    if (languageCode !== this.translateService.currentLang) {
      this.cookieLanguage = language;
      this.logger.debug('LanguageService::updateLanguage()', 'load language resources for ' + languageCode);
      this.configureDateFns(languageCode);
      // ??? this.dateAdapter.setLocale(languageCode);
    }
    this.language.next(language);
  }

  public getLanguageByCode(languageCode: string, includeDialects = true): Language {
    if (languageCode) {
      let uiLanguage = this._languages.find((l) => l.code === languageCode);
      if (!uiLanguage && includeDialects) {
        const splitCode = languageCode.split('-')[0];
        uiLanguage = this._languages.find((l) => l.code === splitCode);
      }
      return uiLanguage;
    }
    return null;
  }

  protected getSavedLanguage() {
    return sessionStorage.getItem(this.lang_cookie_key) || this.cookieService.get(this.lang_cookie_key);
  }

  protected configureDateFns(lang: string) {
    const dateFnsLocaleCode = this.dateFnsConfig.locale() ? this.dateFnsConfig.locale().code : '';
    this.logger.debug('LanguageService::configureDateFns()', 'dateFnsConfig locale = ' + dateFnsLocaleCode);
    switch (lang) {
      case 'ru':
        if ('ru' !== dateFnsLocaleCode) {
          this.dateFnsConfig.setLocale(ru); // TODO dynamic import
        }
        break;

      case 'it':
        if ('it' !== dateFnsLocaleCode) {
          this.dateFnsConfig.setLocale(it); // TODO dynamic import
        }
        break;

      case 'de':
        if ('de' !== dateFnsLocaleCode) {
          this.dateFnsConfig.setLocale(de); // TODO dynamic import
        }
        break;

      case 'en':
      default:
        if ('en-US' !== dateFnsLocaleCode) {
          this.dateFnsConfig.setLocale(enUS); // TODO dynamic import
        }
        break;
    }
  }

  protected setUiLanguages(languages: Language[]) {
    this._languages = languages;
    this.languages.next(this._languages);
  }

  protected getUiLanguages(locale: string, origin: string): Observable<Language[]> {
    const url = `${environment.content_url}/reference/ui-languages`;
    const params = new HttpParams().set('locale', locale).set('origin', origin);
    return this.http.get<Language[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }
}
