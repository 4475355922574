import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/interceptors/auth.interceptor';
import { CorsPatchInterceptor } from '@app/interceptors/cors-patch.interceptor';
import { ErrorInterceptor } from '@app/interceptors/error.interceptor';
import { ProfilerInterceptor } from '@app/interceptors/profiler.interceptor';
import { RequesterUrlInterceptor } from '@app/interceptors/requester-url.interceptor';
import { TenantInterceptor } from '@app/interceptors/tenant.interceptor';
import { VersionInterceptor } from '@app/interceptors/version.interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: CorsPatchInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ProfilerInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequesterUrlInterceptor, multi: true },
];
