/* tslint:disable */
import versions from '../_versions';

import { IDevEnvironment, IParticipantOptionsTable, TParticipantOptions } from './IEnvironment';

const participantOptions: TParticipantOptions = {
  resolution: '120x90', // ?
  frameRate: 15,
  audioBitrate: 24000,
};

const teacherOptions: TParticipantOptions = {
  resolution: '320x240',
  frameRate: 15,
  audioBitrate: 24000,
};

const MaxToVideoSettingTable: IParticipantOptionsTable[] = [
  {
    maxParticipants: 4,
    participantOptions,
    teacherOptions,
  },
  {
    maxParticipants: 5,
    participantOptions: {
      ...participantOptions,
      frameRate: 11,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 14,
    },
  },
  {
    maxParticipants: 6,
    participantOptions: {
      ...participantOptions,
      frameRate: 10,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 14,
    },
  },
  {
    maxParticipants: 9,
    participantOptions: {
      ...participantOptions,
      frameRate: 9,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 12,
    },
  },
  {
    maxParticipants: 11,
    participantOptions: {
      ...participantOptions,
      frameRate: 7,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 12,
    },
  },
  {
    maxParticipants: 14,
    participantOptions: {
      ...participantOptions,
      frameRate: 6,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 10,
    },
  },
  {
    maxParticipants: 17,
    participantOptions: {
      ...participantOptions,
      frameRate: 5,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 10,
    },
  },
  {
    maxParticipants: 20,
    participantOptions: {
      ...participantOptions,
      frameRate: 3,
    },
    teacherOptions: {
      ...teacherOptions,
      frameRate: 10,
    },
  },
];

export const environmentDefaults: Pick<
  IDevEnvironment,
  | 'title'
  | 'versions'
  | 'features'
  | 'logLevel'
  | 'useMocks'
  | 'mocks'
  | 'access_token_key'
  | 'access_token_valid_days'
  | 'tokbox_api_key'
  | 'uploadImageMaxSize'
  | 'default_language'
  | 'videoSettingTable'
  | 'pongTimeMs'
  | 'i18n_incontext_key'
  | 'sentry'
  | 'env'
  | 'assets_url'
> = {
  title: 'OnClass Conference',
  versions,
  env: 'development',
  default_language: 'en',
  features: {
    debugPanel: false,
    videoConference: true,
    billing: true,
    chat: true,
    homeworks: true,
    meetingsHistory: true,
    individualTasks: true,
    fullpageVideoconference: true,
    notifications: false,
    replayHistory: false,
    vocabulary: true,
  },
  logLevel: 'error',

  useMocks: {},
  mocks: {},

  access_token_key: 'APP_AUTH_ACCESS',
  access_token_valid_days: 0.2,

  /* https://tokbox.com/account/#/project/47162774 */
  tokbox_api_key: '47162774',

  uploadImageMaxSize: {
    maxWidth: 830,
    maxHeight: 830,
  },

  videoSettingTable: MaxToVideoSettingTable,

  pongTimeMs: 7000,

  i18n_incontext_key: 'APP__INCNTX',

  /** Одинаковая для всех, безопасно хранить в репозитори */
  sentry: {
    dsn: 'https://529fab4eebdf4266a8e21703ae11c30d@o1072045.ingest.sentry.io/6070130',
  },

  assets_url: '',
};
